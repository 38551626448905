
import { useNavigate, useParams } from "react-router-dom";


import axios from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Project } from "../../Interfaces/Project";

import Select from 'react-select';
import { ValidateEmail } from "../../function/Login";
import { alertLogin } from "../../Interfaces/Login";
import Cookies from "universal-cookie";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import UpdatedProjectSkeleton from '../Skeleton/formUpdatedProject/updatedProjectSkeleton';

const initialStateOption = {
   value: "",
   label: ""
};

const initialStateProject = {
   id_type_opportunities:"",
   s_types_id: "",
   project_name: "",
   user_company_id: "",
   delivery_date: "",
   // end_date: "",
   dp: "",
   description: "",
   publication_date: "",
   budget: "",
   public_info: "",
   // file:"",
   knowledges: []
};

const initialStateListSpecialization = {
   created_at: "",
   id: 0,
   name: "",
   updated_at: ""
};

const initialStateInput = {
   input: "",
   message: '',
   state: false
}

const initialStateBudget = {
   id: "",
   range: ""
}

type handleInputChangeSpecialtie = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;

const SeeProject = () => {

   const [infoProject, setInfoProject] = useState<Project>(initialStateProject);

   const [File, setFile] = useState({ file: null });

   const [specializations, setSpecializations] = useState([initialStateListSpecialization]);

   const [optionStates, setOptionStates] = useState([initialStateOption]);

   const [SelectedValues, setSelectedValues] = useState([]);

   const [alert, setAlert] = useState<alertLogin[]>([initialStateInput]);

   const [termsAndConditions, setTermsAndConditions] = useState<boolean | undefined>(false);

   const [IsLoading, setIsLoading] = useState(true);

   const [DefaultListData, setDefaultListData] = useState([]);

   const [sendingData, setSendingData] = useState(false);

   const [listBudget, setListBudget] = useState([initialStateBudget]);

   const [knowledges, setknowledges] = useState([]);


   // Cookies
   const cookies = new Cookies();

   const getSesion = cookies.get('Bearer');

   const MySwal = withReactContent(Swal);

   const navigate = useNavigate();

   let fileRefProfile = null;

   const params = useParams();

   let checkBoxInfoPublic:any = useRef(null);
   let checkBoxInfoPublic2:any = useRef(null);

   let checkBoxDocuemntProposal:any = useRef(null);
   let checkBoxDocuemntProposal2:any = useRef(null);

   async function getInfoProject() {

      const urlCountry = `${process.env.REACT_APP_API_URI}project/get/${params.id}`;

      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      };
      try {

         const resp = await axios.get(urlCountry, config);

         if (resp.data){

            infoProject.s_types_id = resp.data.s_types_id;
            infoProject.project_name = resp.data.project_name;
            // infoProject.user_company_id   = resp.data.
            infoProject.delivery_date = resp.data.delivery_date;
            // infoProject.end_date          = resp.data.end_date;
            infoProject.dp = resp.data.accept_proposals_files;
            infoProject.description = resp.data.description;

            infoProject.publication_date = resp.data.publication_date;
            infoProject.budget = resp.data.budget.id;

            infoProject.public_info = resp.data.public_info;

            if (resp.data.knowledges_tags.length > 0) {

               const knowledgesListDefault = resp.data.knowledges_tags.map((item: any) => {
                  return { "value": item.id, "label": item.name }
               });

               const getknowledgesList = resp.data.knowledges_tags.map((item: any) => {
                  return item.id
               });

               setknowledges(getknowledgesList);
               setOptionStates(knowledgesListDefault);
            };

            setTimeout(() => { setIsLoading(false); }, 2000);

         };


      } catch (err) {
         // Handle Error Here
         console.error(err);
      }

   };

   // Handle values input
   const handleInputProject = ({ target: { name, value, id, } }: handleInputChangeSpecialtie) => {

      if (name == "termsAndConditions") {
         const checkbox = document.getElementById("termsAndConditions",) as HTMLInputElement | null;
         const valueCheck = checkbox?.checked;
         setTermsAndConditions(valueCheck);
      };

      if (name == "s_types_id") knowledged([value]);

      setInfoProject({ ...infoProject, [name]: value });
   };

   // Handle Values Select
   const selectInputRef = useRef<any>();

   const valueOpcion = (e: any) => {
      if (e.length > 0) {
         const values = e.map((option: any) => { return option.value })
         setSelectedValues(values);
      }
   }

   // handle form end validations
   const handleFormProject = async (event: React.FormEvent<HTMLFormElement>) => {

      event.preventDefault()

      infoProject.knowledges = SelectedValues;
      infoProject.knowledges = knowledges;

      let verifyValues = [
         { name: "name", value: infoProject.project_name },
         { name: "specialty", value: infoProject.s_types_id },
         { name: "budget", value: infoProject.budget },
         { name: "dateInit", value: infoProject.delivery_date },
         { name: "publication_date", value: infoProject.publication_date },
         { name: "stateProfile", value: infoProject.public_info },
         { name: "description", value: infoProject.description },
         { name: "amount", value: String(infoProject.knowledges.length) },
         { name: "receiveDocuments", value: infoProject.dp },
      ];

      infoProject.user_company_id = getSesion.id;

      const validateInputs = await ValidateEmail(verifyValues);

      setAlert(validateInputs);

      const state = validateInputs.filter(input => input.state === false).map((s) => { return false });
      
      if (state.length >= 9) sendData();

   };


   const sendData = () => {

      setSendingData(true);

      const url = `${process.env.REACT_APP_API_URI}company/update/project/${params.id}`;

      let config = {
         // multipart/form-data
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'ApiToken': `Bearer ${getSesion.token}`
         }
      };

      axios.post(url, infoProject, config)
         .then(response => {
           
            if (File.file == "" || File.file == null) {
               MySwal.fire({
                  position: 'center',
                  icon: 'success',
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 3500
               }).then(() => {
                  navigate('/proyectos-empresa');
               });

               setSendingData(false);

            } else {
               sendFileProject(response.data.data.id);
            };


         })
         .catch(err => {
            
            MySwal.fire({
               position: 'center',
               icon: 'error',
               title: err.response.data.message,
               showConfirmButton: false,
               timer: 3500
            });
            setSendingData(false);

         })
   };

   const sendFileProject = (projectId: Number) => {

      const url = `${process.env.REACT_APP_API_URI}company/upload-project-file/${projectId}`;

      let config = {
         // multipart/form-data
         headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'ApiToken': `Bearer ${getSesion.token}`
         }
      };

      axios.post(url, File, config)
         .then(response => {
            MySwal.fire({
               position: 'center',
               icon: 'success',
               title: "Proyecto creado exitosamente",
               showConfirmButton: false,
               timer: 4500
            }).then(() => {
               navigate('/ListProject');
            });

            setSendingData(false);

         })
         .catch(err => {

            MySwal.fire({
               position: 'center',
               icon: 'error',
               title: err.response.data.message,
               showConfirmButton: false,
               timer: 3500
            });

            setSendingData(false);

         });
   }

   // handle files
   const uploadFiles = (e: { target: { files: any; }; }) => {

      let files = e.target.files;

      let image = files[0];
      
      fileRefProfile = image;

      let imageUrl = URL.createObjectURL(image);
      
      setFile({ file: fileRefProfile })

   };

   // Get knowledged
   async function knowledged(ids: string[]) {

      setOptionStates([initialStateOption]);

      const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/Knowledge/${ids}`;

      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'ApiToken': `Bearer ${getSesion.token}`
         }
      };

      try{

         setOptionStates([initialStateOption]);

         const resp = await axios.get(urlCountry, config);

         if (resp.data.data.length > 0) {

            resp.data.data.map((infoData: any) => {
               setOptionStates(current => [...current, { value: infoData.id, label: infoData.name }]);
            });
         };

      } catch (err) {
         setOptionStates([initialStateOption]);
      }
   };

   useEffect(() => {
      async function getSpecialties() {

         const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/types`;

         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         };
         try {

            const resp = await axios.get(urlCountry, config);
            setSpecializations(resp.data.specializationTypes);

         } catch (err) {
            console.error(err);
         };
      };

      getSpecialties();
      getInfoProject();

      async function getBudget() {
         const urlBudget = `${process.env.REACT_APP_API_URI}budget`;
         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         };

         try {
            const resp = await axios.get(urlBudget, config);
            setListBudget(resp.data);

         }catch(err){
            console.error(err);
         };

      };
      getBudget();
   }, []);


   const selectCheck = () =>{

    if (infoProject.public_info == "1") {

       if (checkBoxInfoPublic.current != null) { checkBoxInfoPublic.current.checked = true; };

    } else if (infoProject.public_info == "2") {

       if (checkBoxInfoPublic2.current  != null) { checkBoxInfoPublic2.current.checked = true; };
    };

    if (infoProject.dp == "1") {

       if (checkBoxDocuemntProposal.current != null) { checkBoxDocuemntProposal.current.checked = true; }

    } else if (infoProject.dp == "2") {

       if (checkBoxDocuemntProposal2.current != null) { checkBoxDocuemntProposal2.current.checked = true; }
    };
 };

 setTimeout(() => { selectCheck();}, 2500);

   return (

      <>

         {IsLoading && <UpdatedProjectSkeleton />}

         {!IsLoading &&
            <div className="w-full md:w-95% flex justify-center">
               <form className="w-11/12" onSubmit={handleFormProject}>
                  <div className="flex justify-start pb-0 lg:pb-4">
                     <h1 className="text-Yellow font-bold text-3xl">¡Información de tu proyecto!</h1>
                  </div>
                  <div className="lg:columns-1">
                     <div className="mb-6 text-start">
                        {/* <label htmlFor="nombreCompleto" className="text-white">Selecciona la categoría de acuerdo al proyecto que deseas publicar</label> */}
                        <div className="flex flex-wrap mt-4">
                           <div className="w-full md:w-1/3 md:pr-4 mb-2">
                              <select
                                 disabled
                                 value={infoProject.s_types_id}
                                 name="s_types_id"
                                 onChange={handleInputProject}
                                 className="bg-Light-Blue form-control block w-full px-4 py-3 text-xl font-normal text-sky-400 bg-clip-padding border border-solid border-transparent rounded transition ease-in-out md:m-0 focus:border-Yellow focus:outline-none focus:text-white"  >
                                 <option className="text-white" selected>...</option>
                                 {
                                    specializations.map((item) => {
                                       return <option value={item.id}>{item.name}</option>
                                    })
                                 }
                              </select>
                              <p>
                                 {
                                    alert.filter(input => input.input == "specialty" && input.state === true).map(message => (
                                       <span className="text-red-500">{message.message}</span>
                                    ))
                                 }
                              </p>

                           </div>
                           <div className="w-full md:w-2/3 md:pl-4">

                              <Select
                                 isMulti
                                 name="colors"
                                 defaultValue={optionStates}
                                 options={optionStates.filter((item) => item.label != "")}
                                 className="basic-multi-select"
                                 classNamePrefix="select"
                                 onChange={valueOpcion}
                                 ref={selectInputRef}
                                 isDisabled
                              />
                              {
                                 alert.filter(input => input.input == "amount" && input.state === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                 ))
                              }
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="bg-bluecard-big p-4 rounded">
                     <h1 className="text-Yellow font-bold text-3xl text-left mb-8">Requerimientos del proyecto</h1>
                     <div className="flex flex-wrap">
                        <div className="mb-6 text-start w-full md:w-1/2 lg:w-1/3 md:pr-2 lg:pr-4 xl:pr-12">
                           <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base">Nombre del proyecto</label>
                           <input
                              disabled
                              type="text"
                              className="form-control block w-full mt-2 px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                              name="project_name"
                              value={infoProject.project_name}
                              onChange={handleInputProject}
                           />
                           {
                              alert.filter(input => input.input == "name" && input.state === true).map(message => (
                                 <span className="text-red-500">{message.message}</span>
                              ))
                           }
                        </div>
                        <div className="mb-6 text-start btn-editpro w-full md:w-1/2 lg:w-2/3">
                           <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base">Adjuntar documento/s complementario</label>
                           <input
                              disabled
                              type="file"
                              className="btn-editpro form-control block w-full mt-2 pr-4 text-xs md:text-lg text-holder-gray font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                              name="documents"
                              onChange={uploadFiles}
                           />
                           {
                              alert.filter(input => input.input == "documents" && input.state === true).map(message => (
                                 <span className="text-red-500">{message.message}</span>
                              ))
                           }
                        </div>
                     </div>
                     <div className="flex flex-wrap">
                        <div className="mb-6 text-start w-full md:w-1/2 lg:w-1/3 md:pr-2 lg:pr-4 xl:pr-12">
                           <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base">Presupuesto disponible</label>
                           <select
                              disabled
                              name="budget"
                              value={infoProject.budget}
                              onChange={handleInputProject}
                              className="form-control block w-full px-4 py-2 mt-2 text-xl font-normal text-Yellow bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"  >
                              <option className="text-white" selected>Selecciona un presupuesto</option>
                              {
                                 listBudget.map((range) => {
                                    return <option value={range.id}>{range.range}</option>
                                 })
                              }
                           </select>
                           {
                              alert.filter(input => input.input == "budget" && input.state === true).map(message => (
                                 <span className="text-red-500">{message.message}</span>
                              ))
                           }
                        </div>

                        <div className="mb-6 text-start md:pr-2 w-full md:w-1/2 lg:w-1/3">
                           <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base">Fecha de publicación del proyecto</label>
                           <input
                              disabled
                              type="date"
                              className="form-control block w-full mt-2 px-4 py-2 text-lg text-holder-gray font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                              name="publication_date"
                              value={infoProject.publication_date}
                              onChange={handleInputProject}
                           />
                           {
                              alert.filter(input => input.input == "publication_date" && input.state === true).map(message => (
                                 <span className="text-red-500">{message.message}</span>
                              ))
                           }
                        </div>

                        <div className="mb-6 text-start w-full md:w-1/2 lg:w-1/3 md:pr-2 lg:pr-4 xl:pr-12">
                           <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base">Fecha de entrega del proyecto</label>
                           <input
                              disabled
                              type="date"
                              className="form-control block w-full mt-2 px-4 py-2 text-lg text-holder-gray font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                              name="delivery_date"
                              value={infoProject.delivery_date}
                              onChange={handleInputProject}
                           />
                           {
                              alert.filter(input => input.input == "dateInit" && input.state === true).map(message => (
                                 <span className="text-red-500">{message.message}</span>
                              ))
                           }
                        </div>

                     </div>
                     <div className="flex flex-wrap">
                        <div className="mb-6 text-start btn-editpro w-full">
                           <label htmlFor="dateBirth" className="text-white mb-8 lg:text-sm xl:text-base">Descripción del proyecto</label>
                           <input
                              disabled
                              type="text"
                              className="form-control block w-full mt-2 px-4 py-2 text-lg font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                              placeholder="Tu mensaje"
                              name="description"
                              value={infoProject.description}
                              onChange={handleInputProject}
                           />
                           {
                              alert.filter(input => input.input == "description" && input.state === true).map(message => (
                                 <span className="text-red-500">{message.message}</span>
                              ))
                           }
                        </div>
                     </div>
                     <div className="text-justify md:text-left">
                        <div>
                           <label htmlFor="" className="text-white lg:text-sm xl:text-base">¿Deseas publicar este proyecto utilizando los datos de tu empresa o prefieres publicarlo de forma anónima?</label>
                        </div>
                        <div className="flex mt-2">

                           <div className="px-2">
                              <input className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white" type="radio"
                                 disabled
                                 id="public_info1"
                                 name="public_info"
                                 value="1"
                                 ref={checkBoxInfoPublic}
                                 onChange={handleInputProject}
                              />
                              <span className="text-white lg:text-sm xl:text-base">Pública</span>
                           </div>

                           <div className="px-2">
                              <input className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white"
                                 disabled
                                 type="radio"
                                 name="public_info"
                                 id="public_info2"
                                 value="2"
                                 ref={checkBoxInfoPublic2}
                                 onChange={handleInputProject}
                              />
                              <span className="text-white lg:text-sm xl:text-base">Anonima</span>
                           </div>
                           {
                              alert.filter(input => input.input == "stateProfile" && input.state === true).map(message => (
                                 <span className="text-red-500">{message.message}</span>
                              ))
                           }
                        </div>
                     </div>
                     <div className="flex flex-wrap text-justify md:text-left mt-8">
                        <div className="w-full md:w-1/3 pr-4 lg:pr-2 xl:pr-8">
                           <label htmlFor="" className="text-white lg:text-sm xl:text-base">¿Deseas recibir documento de propuesta?</label>
                           <div className="flex mt-2">

                              <div className="px-2">
                                 <input className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white" type="radio"
                                    name="dp"
                                    ref={checkBoxDocuemntProposal}
                                    id="dp1"
                                    value="1"
                                    disabled
                                    onChange={handleInputProject}
                                 />
                                 <span className="text-white lg:text-sm xl:text-base">Si</span>
                              </div>
                              <div className="px-2">
                                 <input className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white"
                                    type="radio"
                                    name="dp"
                                    ref={checkBoxDocuemntProposal2}
                                    disabled
                                    id="dp2"
                                    value="2"
                                    onChange={handleInputProject}
                                 />
                                 <span className="text-white lg:text-sm xl:text-base">No</span>
                              </div>

                              {
                                 alert.filter(input => input.input == "receiveDocuments" && input.state === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                 ))
                              }

                           </div>
                        </div>
                        {/* <div className="w-full mt-8 md:mt-0 md:w-1/3">
                       <label htmlFor="" className="text-white lg:text-sm xl:text-base">Términos y condiciones</label>
                       <div className="flex flex-wrap mt-2 md:mt-8 lg:mt-8 xl:mt-2">
                          <div className="px-2">
                             <input className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white" type="checkbox"
                                name="termsAndConditions"
                                id="termsAndConditions"
                                onChange={handleInputProject}
                             />
                             <span className="text-white lg:text-xs xl:text-base">Acepto los <a href="" className="hover:text-Yellow">términos y condiciones</a></span>
                          </div>
                          {
                             alert.filter(input => input.input == "termsAndConditions" && input.state === true).map(message => (
                                <span className="text-red-500">{message.message}</span>
                             ))
                          }
                       </div>
                    </div> */}
                     </div>
                  </div>
               </form>
            </div>
         }



      </>


   )
}

export default SeeProject;