import imagenesEditProject from "../../../assets/imgEditProject/imgEditProject";
import { Link } from "react-router-dom";
import { useState, useEffect, useCallback } from 'react';
import Cookies from "universal-cookie";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2';

import { faCcVisa, faCcMastercard } from "@fortawesome/free-brands-svg-icons";
import SkeletonPaymentHistory from "../../../components/Skeleton/paymentHistory/paymentHistorySkeleton";
import Skeleton from "react-loading-skeleton";

const SkeletonDetailsSkeleton = () => {

    // Cookies
    const cookies = new Cookies();

    const getSesion = cookies.get('Bearer');

    const urlData = `${process.env.REACT_APP_API_URI}subscription`;

    interface InterfaceDataSuscripcion {
        active: number,
        brand: string,
        last4: string,
        next_payment: string,
        plan: string,
        price: string,
        time: string
    };

    const initDataSuscripcion = {
        active: 1,
        brand: "",
        last4: "",
        next_payment: "",
        plan: "",
        price: "",
        time: ""
    };

    interface InterfaceSuscripcionCancelada {
        message: string
    };

    const initSuscripcionCancelada = {
        message: ""
    };

    const planEmpresa = {
        plan: "",
        planes_id: ""
    };

    const [dataSuscripcion, setDataSuscripcion] = useState<InterfaceDataSuscripcion>(initDataSuscripcion);

    const [detallesSuscripcionCancelada, setDetallesSuscripcionCancelada] = useState<InterfaceSuscripcionCancelada>(initSuscripcionCancelada);

    const [dataPlanEmpresa, setDataPlanEmpresa] = useState(planEmpresa);

    async function getSuscripcion() {

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        };

        try {

            const resp = await axios.get(urlData, config);
            setDataSuscripcion(resp.data.data);
            
        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    }

    async function confirmarDeleteSuscripcion() {

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        };
        try {

            const resp = await axios.delete(urlData, config);
            setDetallesSuscripcionCancelada(resp.data.details);

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    }

    async function GetInfoCompany() {

        const idUser = cookies.get('Bearer');

        const urlCountry = `${process.env.REACT_APP_API_URI}company/profile/${getSesion.id}`;
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        };
        try {

            const resp = await axios.get(urlCountry, config);

            setDataPlanEmpresa({
                plan: resp.data.plan,
                planes_id: resp.data.planes_id
            })


        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    };

    useEffect(() => {
        getSuscripcion();
        GetInfoCompany();
    }, []);

    const deleteSuscripcion = () => {
        Swal.fire({
            title: 'Cancelando suscripción...',
            text: "¿Esta seguro de querer cancelarlo?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {

                confirmarDeleteSuscripcion();

                Swal.fire({
                    title: "Suscripcion cancelada con exito",
                    icon: 'success',
                    allowOutsideClick: false,
                    confirmButtonText: 'OK'
                }).then(function () {
                    window.location.href = "/perfil-empresa";
                });
            }
        });
    };


    return (
        <>
            <div className="bg-gradient-to-b from-Light-purple via-turquoise to-turquoise">
                <section className="block md:flex md:flex-wrap h-full -mt-px">
                    <div className="hidden lg:flex w-1/5 py-14 bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">
                        <img className="opacity-50 py-10 h-1/2 lg:h-96 xl:h-35" src={imagenesEditProject.logo_blanco_mitad} alt="" />
                    </div>
                    <div className="w-full lg:w-4/5 mb-12 md:mb-0 flex flex-wrap justify-start pb-5 px-6">
                        <div className="w-full sm:w-5% mt-4 md:mt-4">
                            <Link to="" onClick={() => window.history.go(-1)}>
                                <img src={imagenesEditProject.arrow_back_4} className="w-10% sm:w-full md:mx-4" alt="" />
                            </Link>
                        </div>
                        <div className="w-full md:w-95% flex justify-center">
                            <form className="w-full md:w-95%">
                                <div className="flex justify-center pb-0 lg:pb-12">
                                    <h2 className="text-white text-2xl md:text-3xl text-center font-semibold py-2 md:py-8">
                                        {/* <Skeleton count={1} width={300} height={40} ></Skeleton> */}
                                     </h2>
                                </div>
                                <div className="border-t-2">
                                    <div className="mb-6 text-start mt-5">
                                        <div className="flex flex-wrap">

                                            {(dataPlanEmpresa.planes_id != "1") ? (
                                                <>
                                                    <div className="w-full sm:w-1/2 text-white">
                                                        <div className="flex justify-center sm:justify-start items-center">
                                                            <div>
                                                                <h1 className="mt-3"><Skeleton count={1} width={350} height={40} /> </h1>
                                                                <h1 className="mt-3"><Skeleton count={1} width={350} height={40} /> </h1>
                                                                <h1 className="mt-3"><Skeleton count={1} width={350} height={40} /> </h1>
                                                                <h1 className="mt-3"><Skeleton count={1} width={350} height={40} /> </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full sm:w-1/2 mt-5 sm:mt-0 flex justify-center sm:justify-end items-center">
                                                        <div className="flex justify-center sm:justify-end items-center">
                                                            <div className="border-t-2 sm:border-t-0 p-3 text-justify">
                                                                <h1 className="mt-3 text-white"> <Skeleton count={1} width={350} height={40} /> </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="w-full">
                                                    <h1 className="text-center text-white mt-3">No tienes suscripciones activas</h1>
                                                    <div className="w-full flex justify-center items-center">
                                                        <div className="mt-3 bg-Yellow p-3 text-white text-center">
                                                            <Link to={"/Planes"}>Contrata un plan</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}

export default SkeletonDetailsSkeleton;