import { Link } from "react-router-dom";
import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import CreateProjectComponent from "../components/createProject/createProject";

const PublishProject = ({typeForm}: any) =>{
    
    return(
        <section className="bg-turquoise block md:flex md:flex-wrap">
            <CreateProjectComponent typeForm={typeForm}></CreateProjectComponent>
        </section>
    );

};

export default PublishProject;