import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import imagenesEditProject from "../../../assets/imgEditProject/imgEditProject";
import { ValidateEmail } from "../../../function/Login";
import { InitialStateCountry, InitialStateDepartments, InitialStateStudiesTypes } from "../../../Interfaces/departmentsAndmuniti";
import { alertLogin } from "../../../Interfaces/Login";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate, useParams, Link } from "react-router-dom";
import IconLoading from "../../iconLoading/IconLoading";

// type
type handleInputChangeStudies = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>


const initialStateCountry = {
   code: 0,
   id: 0,
   name: ""
}

const initialStateDepartments = {
   code: 0,
   id: 0,
   name: "",
   country: initialStateCountry
}

const initialStateStudies = {
   type_id: "",
   institution: "",
   study_degree: "",
   emision_year: "",
   country_id: "",
   // state_id:"1 ",
   user_id: ""
}

const initialStateInput = {
   input: "",
   message: '',
   state: false
};

const initialStateStudiesTypes = {
   id: 0,
   name: ""
}

const StudiesFreelancer = () => {

   const [countryLists, setCountryLists] = useState<InitialStateCountry[]>([initialStateCountry]);

   const [DepartmentList, setDepartmentList] = useState<InitialStateDepartments[]>([initialStateDepartments]);

   const [Studies, setStudies] = useState(initialStateStudies);

   const [StudiesTypes, setStudiesTypes] = useState<InitialStateStudiesTypes[]>([initialStateStudiesTypes]);

   const [alert, setAlert] = useState<alertLogin[]>([initialStateInput]);

   const [idUser, setIdUser] = useState<number>(0);

   const [sendingData, setSendingData] = useState(false);

   const MySwal = withReactContent(Swal);

   const navigate = useNavigate();

   // Cookies
   const cookies = new Cookies();

   const getSesion = cookies.get("Bearer");

   const params = useParams();

   //  Get country
   useEffect(() => {

      // List info Experience Freelancer
      const StudyInformation = async () => {

         if (Number(params.id) != 0) {

            const idStudy = params.id;

            // console.log('Id experince:', idStudy)

            const urlCountry = `${process.env.REACT_APP_API_URI}freelancer/get-studies/${idStudy}`;

            let config = {
               headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                  'ApiToken': `Bearer ${getSesion.token}`
               }
            };

            try {

               const resp = await axios.get(urlCountry, config);

               // console.log("Get studies ==>", resp);
               departments('3')

               setTimeout(() => {
                  setStudies({
                     type_id: resp.data.Informacion_de_estudio.type_id,
                     institution: resp.data.Informacion_de_estudio.institution,
                     study_degree: resp.data.Informacion_de_estudio.study_degree,
                     emision_year: resp.data.Informacion_de_estudio.emision_year,
                     country_id: resp.data.Informacion_de_estudio.country_id,
                     // state_id:resp.data.Informacion_de_estudio.state_id,
                     user_id: resp.data.Informacion_de_estudio.user_id
                  })
               }, 1500);


            } catch (err) {
               console.error(err);
            }

         }
      }
      StudyInformation();

   }, []);


   const handleInputStudies = ({ target: { name, value, id, } }: handleInputChangeStudies) => {
      //  console.log(name,value);
      setStudies({ ...Studies, [name]: value });
      if (name == "country_id") departments(id);
   };

   const handleWorkStudies = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      let verifyValues = [
         {
            name: "level",
            value: Studies.type_id
         },
         {
            name: "name",
            value: Studies.study_degree
         },
         {
            name: "cargo",
            value: Studies.institution
         },
         {
            name: "dateInit",
            value: Studies.emision_year
         },
         {
            name: "country",
            value: Studies.country_id
         }
      ];

      const validateInputs = ValidateEmail(verifyValues);

      setAlert(validateInputs);

      const state = validateInputs.filter(input => input.state === false).map((s) => { return false });

      Studies.user_id = String(idUser);

      if (state.length >= 5) sendData();

   };

   const sendData = () => {

      setSendingData(true);


      if (Number(params.id) == 0) {
         const url = `${process.env.REACT_APP_API_URI}freelancer/register/studies`;

         let config = {
            headers: {
               'Content-Type': 'multipart/form-data',
               'Accept': 'application/json',
               'ApiToken': `Bearer ${getSesion.token}`
            }
         }

         axios.post(url, Studies, config)
            .then(response => {
               // console.log(response.data);
               setStudies(initialStateStudies);

               setSendingData(false);
               MySwal.fire({
                  position: 'center',
                  icon: 'success',
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 3500
               }).then(() => {
                  navigate('/perfil-freelancer'); 
               })
            })
            .catch(err => {
               //console.log(err.response.data.message);

               MySwal.fire({
                  position: 'center',
                  icon: 'error',
                  title: err.response.data.message,
                  showConfirmButton: false,
                  timer: 3500
               });

               setSendingData(false);

            })

      } else {

         const url = `${process.env.REACT_APP_API_URI}freelancer/update/studies/${params.id}`;

         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
               'ApiToken': `Bearer ${getSesion.token}`
            }
         }
         axios.put(url, Studies, config)
            .then(response => {

               console.log("Get studies ==>", response);
               MySwal.fire({
                  position: 'center',
                  icon: 'success',
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 3500
               }).then(() => {
                  navigate('/perfil-freelancer');
               });

            })
            .catch(err => {

               MySwal.fire({
                  position: 'center',
                  icon: 'error',
                  title: err.response.data.message,
                  showConfirmButton: false,
                  timer: 3500
               });

            })

      }
   }

   // Departments
   const departments = async (id: string) => {

      const urlCountry = `${process.env.REACT_APP_API_URI}state/${id}`;

      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      };

      try {

         const resp = await axios.get(urlCountry, config);

         if (resp.data.message != "No se encontraron estados o departamentos disponibles.") {

            setDepartmentList(resp.data);

         } else {

            setDepartmentList([initialStateDepartments]);

         };


      } catch (err) {
         // Handle Error Here
         console.error(err);
      }
   }

   //  Get country
   useEffect(() => {
      async function fetchData() {

         const urlCountry = `${process.env.REACT_APP_API_URI}country`;

         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         };

         try {
            const resp = await axios.get(urlCountry, config);
            // console.log(resp.data);
            setCountryLists(resp.data);
         } catch (err) {
            // Handle Error Here
            console.error(err);
         }
      };
      fetchData();

      async function getStudiesTypes() {
         const urlCountry = `${process.env.REACT_APP_API_URI}freelancer/list/studies-types?area=Académica&origin=profile`;
         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
               'ApiToken': `Bearer ${getSesion.token}`
            }
         };
         try {
            const resp = await axios.get(urlCountry, config);
            //   console.log(resp.data);
            setStudiesTypes(resp.data.data);
         } catch (err) {
            // Handle Error Here
            console.error(err);
         }
      };
      getStudiesTypes();

      const getIdUser = () => {
         const { id } = cookies.get('Bearer');
         setIdUser(id)
      }
      getIdUser();

   }, []);

   return (
      <div className="w-full md:w-95% flex justify-center">
         <form className="w-full md:w-8/12" onSubmit={handleWorkStudies}>
            <div className="flex justify-center pb-0 lg:pb-12">
               <h2 className="text-Yellow text-2xl md:text-3xl text-center font-semibold py-2 md:py-8">Registrar Estudios Académicos</h2>
            </div>
            <div className="lg:columns-2">
               <div className="mb-6 text-start">
                  <label htmlFor="nombreCompleto" className="text-white mb-8">Seleccione el tipo de estudio</label>
                  <select
                     id="3" //do not remove ID, it is important to validate
                     className="form-control block w-full mt-2 px-4 py-2 text-xl font-normal text-Yellow bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0focus:outline-none"
                     name="type_id"
                     value={Studies.type_id}
                     onChange={handleInputStudies}
                  >
                     <option className="text-white" selected>...</option>
                     {
                        StudiesTypes.map((studiedType, key) => {
                           return <option key={key} className="text-white" value={studiedType.id}>{studiedType.name}</option>

                        })
                     }
                  </select>

                  {alert.filter(input => input.input == "level" && input.state === true).map((message, key) => (
                     <span key={key} className="text-red-500 text-start">{message.message}</span>
                  ))}
               </div>
               <div className="mb-6 text-start">
                  <label htmlFor="email" className="text-white mb-8">Ingrese nombre de la institución</label>
                  <input
                     type="text"
                     className="login form-control block w-full mt-2 px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:outline-none"
                     name="study_degree"
                     value={Studies.study_degree}
                     onChange={handleInputStudies}
                  />
                  {alert.filter(input => input.input == "name" && input.state === true).map((message, key) => (
                     <span key={key} className="text-red-500 text-start">{message.message}</span>
                  ))}
               </div>
            </div>
            <div className="lg:columns-2">
               <div className="mb-6 text-start">
                  <label htmlFor="dateBirth" className="text-white mb-8">Nombre de título adquirido</label>
                  <input
                     type="text"
                     className="form-control block w-full mt-2 px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:outline-none"
                     name="institution"
                     value={Studies.institution}
                     onChange={handleInputStudies}
                  />
                  {alert.filter(input => input.input == "cargo" && input.state === true).map((message, key) => (
                     <span key={key} className="text-red-500 text-start">{message.message}</span>
                  ))}
               </div>
               <div className="mb-6 text-start">
                  <label htmlFor="dateBirth" className="text-white mb-8">Fecha de emisión de título</label>
                  <input
                     // type="date"
                     type="month"
                     className="form-control block w-full mt-2 px-4 py-2 text-lg text-holder-gray font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:outline-none"
                     name="emision_year"
                     id="1" //do not remove ID, it is important to validate
                     value={Studies.emision_year}
                     onChange={handleInputStudies}
                  />
                  {alert.filter(input => input.input == "dateInit" && input.state === true).map((message, key) => (
                     <span key={key} className="text-red-500 text-start">{message.message}</span>
                  ))}

               </div>
            </div>
            <div className="lg:columns-1">
               <div className="mb-6 text-start">
                  <label htmlFor="nombreCompleto" className="text-white mb-8">País</label>
                  <select
                     id="3" //do not remove ID, it is important to validate
                     className="form-control block w-full mt-2 px-4 py-2 text-xl font-normal text-Yellow bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:text-white focus:outline-none"
                     name="country_id"
                     value={Studies.country_id}
                     onChange={handleInputStudies}
                  >
                     <option className="text-white" value="0" selected>...</option>
                     {
                        countryLists.map((country, key) => {
                           return <option key={key} value={country.id}> {country.name}</option>
                        })
                     }
                  </select>
                  {alert.filter(input => input.input == "country" && input.state === true).map((message, key) => (
                     <span key={key} className="text-red-500 text-start">{message.message}</span>
                  ))}
               </div>
               {/* <div className="mb-6 text-start">
         <label htmlFor="nombreCompleto" className="text-white mb-8">Departamento</label>
         <select
            className="form-control block w-full mt-2 px-4 py-2 text-xl font-normal text-Yellow bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:outline-none"
            name="state_id"  
            value={Studies.state_id}
            onChange={handleInputStudies}
            >
            <option className="text-white" value="0" selected>...</option>

            {
               DepartmentList.map((department) =>{
                  return <option value={department.id}> {department.name}</option>
               })
            }
         </select>
      
         {alert.filter(input => input.input == "department" && input.state === true).map((message, key) => (
            <span key={key} className="text-red-500 text-start">{message.message}</span>
         ))}
         </div> */}
            </div>
            <div className="columns-1 flex justify-center">
               <div className="text-center pt-4 lg:text-left w-full md:w-1/2">
                  <button
                     type="submit"
                     className="inline-block px-7 py-3 bg-Yellow hover:opacity-0.5 text-white font-medium text-sm leading-snug rounded-lg shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out w-full"
                  >
                     {!sendingData &&
                        <>
                           Agregar estudio
                        </>
                     }

                     {sendingData &&
                        <IconLoading />
                     }
                  </button>
               </div>
            </div>
         </form>
      </div>
   )
}

export default StudiesFreelancer;