import { Link } from "react-router-dom";
import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import CreateProjectComponent from "../components/createProject/createProject";
import UpdatedProjectCompany from "../components/createProject/updatedProyect";


const UpdatedProjectView = () =>{
    return(
        <>
            <div className="bg-turquoise">
                <section className="block -mt-px md:flex md:flex-wrap">
                    <div className="md:w-full lg:w-5/5 flex flex-wrap justify-center pb-5 lg:py-5 md:pt-4 lg-pt-0">
                        <div className="w-full sm:w-5% mt-4 md:mt-1 py-1 md:pl-1">
                            <Link to="" onClick={() => window.history.go(-1)}>
                                <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                            </Link>
                        </div>

                        <div className="w-full md:w-95% flex justify-center">
                            <div className="w-11/12">
                                <div className="flex justify-start pb-0 lg:pb-4 py-4">
                                    <h1 className="text-Yellow font-bold text-3xl w-full">¡Editar información!</h1>
                                </div>
                                <div className="">
                                    <UpdatedProjectCompany></UpdatedProjectCompany>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default UpdatedProjectView;