import imagenesEditProject from "../../../assets/imgEditProject/imgEditProject";
import StudiesFreelancer from "../../../components/EditProfilefreelancer/studies/StudiesFreelancer";
import { Link } from "react-router-dom";
import { useState, useEffect, useCallback } from 'react';
import AddOtherStudies from "../../../components/EditProfilefreelancer/otherStudies/OtherStudies";
import Cookies from "universal-cookie";
import axios from "axios";
import Skeleton from "react-loading-skeleton";

const dataHistorial = { date: "", description: "", total: "" }

const SkeletonPaymentHistory = () => {

  const [data, setData] = useState();

  const [loaded, setLoaded] = useState(false);

  // Cookies
  const cookies = new Cookies();

  const getSesion = cookies.get('Bearer');


  const [HistorialFacturacion, setHistorialFacturacion] = useState([dataHistorial]);

  useEffect(() => {
    async function getHistorial() {

      const urlData = `${process.env.REACT_APP_API_URI}payment/history/${getSesion.id}`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'ApiToken': `Bearer ${getSesion.token}`
        }
      };

      try{
        
        const resp = await axios.get(urlData, config);
        setHistorialFacturacion(resp.data);
        
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    }
    getHistorial();
  }, []);

  return (
    <>
      <div className="bg-gradient-to-b from-Light-purple lg:via-turquoise via-Light-purple to-turquoise">
        <section className="block -mt-px md:flex md:flex-wrap">
          <div className="hidden lg:flex w-1/5 py-14 bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">
            <img
              className="opacity-50 py-10 h-1/2 lg:h-3/4 xl:h-5/6"
              src={imagenesEditProject.logo_blanco_mitad}
              alt=""
            />
          </div>
          <div className="w-full lg:w-4/5 md:mb-0 justify-start p-5 px-6">
            <div className="sm:w-5% ml-5 sm:ml-0">
              <Link to="" onClick={() => window.history.go(-1)}>
                <img
                  src={imagenesEditProject.arrow_back_4}
                  className="w-10% md:w-full md:mx-4"
                  alt=""
                />
              </Link>
            </div>
            <div className="ml-5 mt-5">

              <h2 className="text-white text-2xl md:text-3xl font-semibold"><Skeleton count={1} width="40%"></Skeleton></h2>
              <p className="text-Yellow text-lg md:text-xl"><Skeleton count={1} width="100%" height={80} ></Skeleton></p>

              <div className="p-5 sm:p-0 block w-full mt-5">
                <div className="relative overflow-x-auto rounded-lg">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-md text-white bg-turquoise">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-Yellow">
                          <Skeleton count={1} width="100%" height={40}></Skeleton>
                        </th>
                        <th scope="col" className="px-6 py-3 text-Yellow">
                          <Skeleton count={1} width="100%" height={40}></Skeleton>
                        </th>
                        <th scope="col" className="px-6 py-3 text-Yellow">
                          <Skeleton count={1} width="100%" height={40}></Skeleton>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                         Array(3).fill(0).map((item, index) =>{
                          return (
                            <tr className="border-b-4 border-gray-600 text-white">
                              <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">
                                <Skeleton count={1} width="100%" height={40}></Skeleton>
                              </th>
                              <td className="px-6 py-4 font-medium whitespace-nowrap md:whitespace-normal">
                                <Skeleton count={1} width="100%" height={40}></Skeleton>
                              </td>
                              <td className="px-6 py-4 font-medium whitespace-nowrap">
                                <Skeleton count={1} width="100%" height={40}></Skeleton>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </section>

      </div>
    </>
  );
};

export default SkeletonPaymentHistory;
