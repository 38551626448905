// libraries
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
// components
import RecoverPassword from '../modalRecoverPassword/recoverPassword';
// functions
import { ValidateEmail } from '../../function/Login';
// img
import React, { ChangeEvent, useRef, useState } from "react";
// import logoLetrasChambaticon from "../../assets/imgLogin/chamba-ticon.png"
import imagenesLogin from "../../assets/imgLogin/imagenesLogin";
// Interfaces
import { alertLogin, Login } from "../../Interfaces/Login";
import ButtonGoogleAndFacebook from '../buttonsFACAndGoo/buttonFacebookAndGoogle';
import IconLoading from '../iconLoading/IconLoading';

interface Props {
   animation: string;
}

// Types
type handleInputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>

// const
const initialState = {
   email: '',
   password: '',
   type: ''
}

const initialStateInput = {
   input: "",
   message: '',
   state: false
}

const FormLogin = ({ animation }: Props) => {

   const [login, setlogin] = useState<Login>(initialState);
   const inputTitle = useRef<HTMLInputElement>(null);
   const [alert, setalert] = useState<alertLogin[]>([initialStateInput]);
   const [sendingData, setSendingData] = useState(false);
   const [HowToLogin, setHowToLogin] = useState(true);
   const [shown, setShown] = React.useState(false);
   const MySwal = withReactContent(Swal);
   const cookies = new Cookies();

   // event form
   const handleLoginSession = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      sendDataValidate();
   };

   // form input event
   const handleInputChange = ({ target: { name, value } }: handleInputChange) => {
      setlogin({ ...login, [name]: value })
   };

   // Send info
   const sendDataValidate = () => {

      const sendDataValidate = [
         {
            name: "gmail",
            value: login.email
         },
         {
            name: "passwordLogin",
            value: login.password
         }
      ];

      const validateInputs = ValidateEmail(sendDataValidate);
      const state = validateInputs.filter(input => input.state === false).map((s) => { return false });
      setalert(validateInputs);
      console.log("Alerts ==> ", state.length)
      if (state.length >= 2) loginFreelancers();
   };

   // Freelancers login
   const loginFreelancers = async () => {
      setSendingData(true);
      const url = `${process.env.REACT_APP_API_URI}freelancer/login`;
      let config = {
         headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
         }
      }
      axios.post(url, login, config)
         .then(response => {
            const token = response.data.access_token;
            const name = response.data.token_type;
            const expires = response.data.expires_at;
            const type = response.data.type;
            MySwal.fire({
               position: 'center',
               icon: 'success',
               title: "Bienvenido a Chambaticon",
               showConfirmButton: false,
               timer: 3500
            }).then(() => {
               // redirect to profile 
               console.log("RESPONSE LOGIN", response);
               handleNavigationLogin(token, name, expires, type);
               setSendingData(false);
            });
         }).catch(err => {
            if (err.response.data.message === "Usuario no encontrado. Verifique sus credenciales.") {
               // employee login function call
               loginEmployer();
            } else {
               MySwal.fire({
                  position: 'center',
                  icon: 'error',
                  title: err.response.data.message,
                  showConfirmButton: false,
                  timer: 3500
               });
            }
            setSendingData(false);
         });
   };

   // Employer Login
   function loginEmployer() {
      login.type = "1";
      const url = `${process.env.REACT_APP_API_URI}employer/login`;
      let config = {
         headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
         }
      };
      axios.post(url, login, config)
         .then(response => {
            const token = response.data.access_token;
            const name = response.data.token_type;
            const expires = response.data.expires_at;
            const type = response.data.type;
            MySwal.fire({
               position: 'center',
               icon: 'success',
               title: "Bienvenidos",
               showConfirmButton: false,
               timer: 3500
            }).then(() => {
               // redirect to profile   
               handleNavigationLogin(token, name, expires, type);
            });
         }).catch(err => {
            MySwal.fire({
               position: 'center',
               icon: 'error',
               title: err.response.data.message,
               showConfirmButton: false,
               timer: 3500
            });
         });
   };

   const handleNavigationLogin = (token: string, name: string, expires: string, type: number) => {
      const url = `${process.env.REACT_APP_API_URI}auth/profile`;
      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'ApiToken': `Bearer ${token}`
         }
      };
      console.log(token);
      console.log(config);
      let idUser: string = "";
      axios.get(url, config)
         .then(response => {
            console.log("RESPONSE", response);
            idUser = response.data.id
            cookies.set(name, { token: token, type: type, id: idUser }, { path: "/", expires: new Date(expires), secure: true });
            localStorage.setItem("photo", response.data.photo);
            switch (type) {
               case 3: {
                  window.location.replace('/perfil-freelancer');
                  break;
               }
               case 4: {
                  window.location.replace('/perfil-empresa');
                  break;
               }
               default: {
                  break;
               }
            }
         }).catch(err => {
            console.log(err.response);
         });
   };

   const switchShown = () => setShown(!shown);

   return (
      <div className='w-full flex flex-wrap justify-center items-center'>
         <form className={`w-full lg:w-2/4 ${animation}`} onSubmit={handleLoginSession} >
            <img src={imagenesLogin.logoLetrasChambaticon} className="w-full my-10 lg:my-5 sm:my-10 md:my-10" alt="" />
            <div className="mb-6 text-start">
               <label htmlFor="email" className="text-white">Correo electrónico</label>
               <input
                  type="text"
                  className="bg-color-input-login form-control block w-full px-4 py-2 mb-1 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                  id="email"
                  name="email"
                  value={login.email}
                  onChange={handleInputChange}
                  autoFocus
                  ref={inputTitle}
               />
               {alert.filter(input => input.input == "gmail" && input.state === true).map(message => (
                  <span className="text-red-500">{message.message}</span>
               ))}
            </div>
            <div className="mb-6 text-start">
               <label htmlFor="password" className="text-white">Contraseña</label>
               <div className="flex items-center">
                  <input
                     type={shown ? 'text' : 'password'}
                     className="bg-color-input-login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                     id="password"
                     name="password"
                     value={login.password}
                     onChange={handleInputChange}
                  />
                  <a onClick={switchShown} className='p-2 text-Yellow cursor-pointer -ml-12'>{shown ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</a>
               </div>
               {alert.filter(input => input.input == "passwordLogin" && input.state === true).map(message => (
                  <span className="text-red-500">{message.message}</span>
               ))}
            </div>
            <button
               type="submit"
               className="inline-block px-7 py-3 bg-Yellow text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out w-full">
               {!sendingData && <>Iniciar sesión</>}
               {sendingData && <IconLoading />}
            </button>
            <div className="w-full text-left">
               <div className="text-white">
                  <RecoverPassword />
               </div>
            </div>
         </form>
      </div>
   )
}
export default FormLogin;
