import React, { ChangeEvent, useEffect, useState } from "react";
import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFileAlt, faIdCard, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { async } from "@firebase/util";
import fileDownload from "js-file-download";
import ProjectListCompany from "../components/Skeleton/projectCompany/ProjectListCompanySkeleton";
import DataTableComponent from "../components/DataTableComponent/DataTableComponent";
import SectionCard from "../components/SectionCard/SectionCard";
import PreloaderTables from "../components/SectionCard/PreloaderTables/PreloaderTables";
import Preloader from "../components/SectionCard/Preloader/Preloader";

const initialStateProjectCompany = {
  company_project_file: "",
  assigned_status: 0,
  allowPublish: "",
  budget: 0,
  created_at: "",
  delivery_date: "",
  description: "",
  end_date: "",
  file_folder_path: 0,
  freelance_id: 0,
  id: 0,
  knowledges_tags: "",
  project_name: "",
  public_info: 0,
  publicationStatus: 0,
  publication_date: "",
  s_types_id: 0,
  status: 0,
  updated_at: "",
  user_company_id: 0,
};

const initialStateSpecialties = {
  id: 0,
  name: "",
  created_at: "",
  updated_at: "",
};

const statusOpportunities: { [key: number]: string } = {
  1: "Activo",
  2: "Inactivo",
  3: "Eliminado",
  4: "En proceso",
  5: "Finalizado",
  6: "Cancelado",
  7: "Atrasado",
};

type handleInputChangeSpecialtie = ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
>;

const ListProProEmp = () => {
  const [ListProjectCompany, setListProjectCompany] = useState([
    initialStateProjectCompany,
  ]);

  const [specialties, setStateSpecialties] = useState([
    initialStateSpecialties,
  ]);

  const navigate = useNavigate();

  const [IsLoading, setIsLoading] = useState(true);

  const [MessageDefault, setMessageDefault] = useState(false);

  const [typeOpportunity, setTypeOpportunity] = useState([]);

  const cookies = new Cookies();

  const getSesion = cookies.get("Bearer");

  const [filterProject, setFilterProject] = useState({
    category: "",
    state: "",
    orden: "",
  });

  const [messageFilter, setMessageFilter] = useState(false);

  const [selectOportunity, setSelectOportunity] = useState(0);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const handleClickFile = (url: string, filename: string) => {
    axios({
      url: url,
      method: "GET",
      responseType: "blob",
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    });
  };

  async function fetchData(id: number) {
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URI}company/get/projects/${getSesion.id}`;
    let config = {
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        'ApiToken': `Bearer ${getSesion.token}`
      },
    };

    try {
      const resp = await axios.get(url, config);
      console.log(resp, "data");

      if (id == 1) {
        console.log(resp.data.internships);
        setData(resp.data.internships);
      } else if (id == 2) {
        setData(resp.data.jobs_vacancies);
      } else if (id == 3) {
        setData(resp.data.profesional_services);
      } else if (id == 4) {
        setData(resp.data.projects);
      }

      setLoading(false);
    } catch (err) {
      console.error(err);
      setData([]);
      setLoading(false);
    }
  }

    async function getTypeOportunity() {
    
      const url = `${process.env.REACT_APP_API_URI}type-opportunity/filter`;

      console.log(url, "url")

      let config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'ApiToken': `Bearer ${getSesion.token}`
        }
      };
      try {

        const resp = await axios.get(url, config);

        setTypeOpportunity(resp.data.data);

      } catch (err) {

        console.log(err)

      };
  };

  useEffect(() => {
    getTypeOportunity();
  }, []);

  const columnsPasantias = [
      {
          name: 'Nombre',
          width: "20%",
          cell: (row: any) => <div>{row.job_title}</div>
      },
      {
          name: 'Fecha de inicio',
          width: "15%",
          cell: (row: any) => <div>{row.start_date}</div>
      },
      {
          name: 'Fecha de finalización',
          width: "15%",
          cell: (row: any) => <div>{row.end_date}</div>
      },
      {
          name: 'Tipo de pasantía',
          width: "15%",
          cell: (row: any) => <div>{row.id_type_internship == 1 ? "Remunerada" : "No remunerada"}</div>
      },
      {
          name: 'Modalidad',
          width: "15%",
          cell: (row: any) => <div>{row.id_work_modality == 1 ? "Presencial" : row.id_work_modality == 2 ? "Remoto" : row.id_work_modality == 3 && "Hibrido"}</div>
      },
      {
          name: 'Estado',
          width: "15%",
          cell: (row: any) => <div>{statusOpportunities[row.status]}</div>
      },
  ];

  const columnsPlaza = [
    {
        name: 'Nombre',
        width: "20%",
        cell: (row: any) => <div>{row.job_title}</div>
    },
    {
        name: 'Tipo de plaza',
        width: "15%",
        cell: (row: any) => <div>{row.id_type_job_vacancy == "1" ? "Fija" : "Temporal"}</div>
    },
    {
        name: 'Tipo de contrato',
        width: "15%",
        cell: (row: any) => <div>{row.id_type_contract == "1" ? "Tiempo completo" : "Tiempo parcial"}</div>
    },
    {
        name: 'Tipo de horario',
        width: "15%",
        cell: (row: any) => <div>{row.id_type_schedule == "1" ? "Fijo" : "Rotativo"}</div>
    },
    {
        name: 'Modalidad',
        width: "15%",
        cell: (row: any) => <div>{row.id_work_modality == "1" ? "Presencial" : row.id_work_modality == "2" ? "Rotativo" : "Híbrido"}</div>
    },
    {
      name: 'Estado',
      width: "15%",
      cell: (row: any) => <div>{statusOpportunities[row.status]}</div>
  },
  ];

  const columnsServiciosProfesionales = [
      {
          name: 'Nombre',
          width: "25%",
          cell: (row: any) => <div>{row.name}</div>
      },
      {
          name: 'Tipo de servicio',
          width: "15%",
          cell: (row: any) => <div>{row.id_type_service == 1 ? "Consultoría" : row.id_type_service == 2 ? "Asesoramiento" : row.id_type_service == 3 ? "Diseño gráfico" : row.id_type_service == 4 && "Desarrollo de software"}</div>
      },
      {
          name: 'Remuneración',
          width: "15%",
          cell: (row: any) => <div>${row.salary}</div>
      },
      {
          name: 'Fecha limite de aplicación',
          width: "20%",
          cell: (row: any) => <div>{row.application_deadline}</div>
      },
      {
        name: 'Estado',
        width: "15%",
        cell: (row: any) => <div>{statusOpportunities[row.status]}</div>
    },
  ];

  const columnsProyecto = [
      {
          name: 'Nombre',
          width: "200px",
          cell: (row: any) => <div>{row.project_name}</div>
      },
      {
          name: 'Categoría',
          width: "200px",
          cell: (row: any) => <div>{
            row.s_types_id == 1 ? "Marketing digital" : row.s_types_id == 2 ? "Programación y tecnología" : row.s_types_id == 3 ? "Diseño y multimedia" : row.s_types_id == 4 ? "Soporte" : row.s_types_id == 2 && "Ofimática"
          }</div>
      },
      {
          name: 'Fecha de entrega',
          width: "200px",
          cell: (row: any) => <div>{row.delivery_date}</div>
      },
      {
        name: 'Estado',
        width: "15%",
        cell: (row: any) => <div>{statusOpportunities[row.status]}</div>
    },
  ];

  const handleChangeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectOportunity(parseInt(event.target.value));
    fetchData(parseInt(event.target.value));
  };

  const expandedComponent = ({ data }: { data: any }, type:any) => {

    let typeOpportunity = ""

    console.log(type, "Type")

    if (type == 1) {
      typeOpportunity = "pasantia"
    } else if (type == 2) {
      typeOpportunity = "plaza"
    } else if (type == 3) {
      typeOpportunity = "servicio-profesional"
    } else if (type == 4) {
      typeOpportunity = "proyecto"
    }

      return(
        <div className="flex py-2 px-12" role="menu" aria-orientation="vertical" aria-labelledby="dropdown-button">
          <Link
            to={`/detalles-de-proyecto/${data.id}/${data.id_type_opportunities}`}
            className="flex items-center bg-Light-Blue rounded-lg mx-1 px-4 py-2 text-sm text-white active:bg-blue-100 cursor-pointer"
          >
              <FontAwesomeIcon className="mr-2" icon={faFileAlt} /> Ver
          </Link>
          {
            <Link
              to={`/editar-oportunidad/${typeOpportunity}/${data.id}`}
              className="flex items-center bg-Light-Blue rounded-lg mx-1 px-4 py-2 text-sm text-white active:bg-blue-100 cursor-pointer"
            >
                <FontAwesomeIcon className="mr-2" icon={faPen} /> Editar
            </Link>
            
          }
          {/* <button
              id={data.id}
              onClick={(e) => {  }}
              className="flex items-center bg-Light-Blue rounded-lg mx-1 px-4 py-2 text-sm text-white active:bg-blue-100 cursor-pointer"
          >
              <FontAwesomeIcon className="mr-2" icon={faTrash} /> Eliminar
          </button> */}
      </div>
      )
    }

  return (
    <>
      <div className="bg-turquoise">
        <section className="block -mt-px md:flex md:flex-wrap">
          <div className="w-full flex justify-center pb-5 lg:py-5">
            <div className="w-full lg:px-10 px-6">
              <div className="w-full my-5 flex items-center">
                <img
                  src={imagenesEditProject.arrow_back_4}
                  className="w-15% sm:w-10% md:w-5% cursor-pointer"
                  alt=""
                  onClick={() => window.history.go(-1)}
                />
                <h1 className="text-Yellow font-bold text-3xl ml-5">
                  Mis oportunidades
                </h1>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/5 grid grid-cols-1 md:grid-cols-2 gap-1">
                  <select
                    name="category"
                    className="bg-Light-Blue form-control block px-4 py-4 text-base font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out focus:border-Yellow focus:outline-none"
                    onChange={handleChangeSelect}
                  >
                    <option className="text-white" value={0} >
                      Seleccionar tipo de oportunidad
                    </option>
                    {
                        typeOpportunity.map((item: any) => (
                            <option className="text-white" key={item.id} value={item.id}>{item.name}</option>
                        ))
                    }
                  </select>
                </div>
              </div>
              {selectOportunity != 0 
              && 
              <SectionCard title={
                selectOportunity == 1 ? "Pasantías" :
                selectOportunity == 2 ? "Plaza de trabajo" :
                selectOportunity == 3 ? "Servicios profesionales" :
                selectOportunity == 4 ? "Proyectos" : "Selecciona una opción"
              } button={
                    <button
                        className="btn-card-header"
                        onClick={() => {
                          navigate("/publicar-proyecto");
                        }}
                    >
                        Crear oportunidad
                    </button>
                } bgColor={"bg-Light-purple"}>
                {
                  loading 
                  ?
                  <PreloaderTables />
                  :
                  <DataTableComponent columns={
                    selectOportunity == 1 ? columnsPasantias :
                    selectOportunity == 2 ? columnsPlaza :
                    selectOportunity == 3 ? columnsServiciosProfesionales :
                    selectOportunity == 4 ? columnsProyecto : []
                  } data={data} expandedComponent={(e:any) => expandedComponent(e, selectOportunity)} />
                }
              </SectionCard>
              }
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default ListProProEmp;
