import { useEffect, useState } from "react";
import IconLoading from "../iconLoading/IconLoading";
import axios from "axios";
import Cookies from "universal-cookie";
import { ValidateEmail } from "../../function/Login";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams } from "react-router-dom";
import { validateData } from "../validateData";
import Skeleton from "react-loading-skeleton";


const FormProfesionalServices = ({typeForm}) =>{

    console.log(typeForm, "typeForm");

    const editTypeOpportunity = useParams();

    const cookies = new Cookies();

    const getSesion = cookies.get('Bearer');

    const MySwal = withReactContent(Swal);

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const initialStateForm = {
        name:"",
        otherTypeService:"",
        idTypeSalary:"",
        salary:"",
        dateLimitToApply:"",
        description:""
    };
    
    const initialStateInput = {
        input: "",
        message: '',
        state: false
    };

    const [oldData, setOldData] = useState({
        name:"",
        otherTypeService:"",
        idTypeSalary:"",
        salary:"",
        dateLimitToApply:"",
        description:""
    });

    const [dateModify, setDateModify] = useState(typeForm == "update" ? false : true);

    const [profesionalServices, setProfesionalServices] = useState(initialStateForm);
    // console.log(profesionalServices);
    const [alert, setAlert] = useState([initialStateInput]);

    const [id, setId] = useState(0);
   
    const getInputs = (e) =>{
        setProfesionalServices({
            ...profesionalServices,
            [e.target.name]: e.target.value
        });
    };

    const handleFormRegister = (event) =>{
        event.preventDefault();

        let inputs = Object.entries(profesionalServices).map(([key, value]) => {
            return {
                name: key,
                value: value,
            };
        });

        if (dateModify === false) {
            inputs = inputs.filter(input => input.name !== "dateLimitToApply");
        }
            
        const datosValidados = validateData(inputs);

        console.log("🚀 ~ datosValidados ~ datosValidados:", datosValidados)

        setAlert(datosValidados);

        let totalValidaciones = datosValidados.filter(input => input.status !== false).map(() => { return false });

        if(totalValidaciones == 0){
        
            sendData();
        
        }else{
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: 'Por favor, asegúrate de llenar todos los campos requeridos.',
                showConfirmButton: false,
                timer: 3500
            });
        };

    };
    
    const sendData = () =>{
        
        const url = `${process.env.REACT_APP_API_URI}company/${typeForm}/project/${id}`;

        console.log("🚀 ~ sendData ~ url:", url)
        let config = {
            // multipart/form-data
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        };

        const newData = {
            name:profesionalServices.name,
            id_type_service:profesionalServices.otherTypeService,
            id_type_salary:profesionalServices.idTypeSalary,
            salary:profesionalServices.salary,
            description:profesionalServices.description,
            id_type_opportunities:"3"
        };

        console.log("🚀 ~ sendData ~ newData:", newData)

        if (dateModify) {
            newData.application_deadline = profesionalServices.dateLimitToApply;
        } else {
            newData.application_deadline = oldData.dateLimitToApply;
        }

        console.log(newData);
    
        axios.post(url, newData, config).then(response => {
            // console.log(response);
            MySwal.fire({
                position: 'center',
                icon: 'success',
                // title: err.response.data.message,
                title: response.data.message,
                showConfirmButton: false,
                timer: 3500
            }).then(() => {
                navigate('/perfil-empresa');
            });

            // setProfesionalServices(initialStateForm);

        }).catch(err => {
            console.log(err);
            MySwal.fire({
                position: 'center',
                icon: 'error',
                // title: err.response.data.message,
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 4000
            });
        });
    };

    const InfoProfessionalService = async () => {

        setIsLoading(true);

        let urlListOpportunity = `${process.env.REACT_APP_API_URI}project/get/${editTypeOpportunity.id}?id_type_opportunities=3`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        };

        try {
            const resp = await axios.get(urlListOpportunity, config);
            const getProfessionalServices = resp.data;
            console.log("get info", getProfessionalServices);

            const listDataNew = {
                name:getProfessionalServices.name,
                otherTypeService:getProfessionalServices.id_type_service,
                idTypeSalary:getProfessionalServices.id_type_salary,
                salary:getProfessionalServices.salary,
                dateLimitToApply:getProfessionalServices.application_deadline,
                description:getProfessionalServices.description
            }

            console.log(getProfessionalServices, "getProfessionalServices");

            setId(getProfessionalServices.id);

            setProfesionalServices(listDataNew);

            setOldData(listDataNew)

            setIsLoading(false);

        } catch (err) {
            console.error(err);
        };

    };

    useEffect(() => {
        if(typeForm == "update"){
            InfoProfessionalService();
        } else if (typeForm == "create"){
            setId(getSesion.id);
        }

    }, [])
    
    return (
        <div className="w-full flex justify-center">
             <form className="w-full" onSubmit={handleFormRegister}>
                <div className="bg-bluecard-big p-5 rounded">
                    <div className="md:flex items-center md:justify-between">
                        <h1 className="text-Yellow font-bold text-3xl text-left mb-8 px-2">Requerimientos de servicios profesionales</h1>
                        {typeForm === "update" && isLoading === false && <label className="relative inline-flex items-center cursor-pointer mb-8 ml-2">
                            <input type="checkbox"
                                checked={dateModify}
                                onChange={() => setDateModify(!dateModify)}
                                className="sr-only peer"
                                name="emergency_contact"
                            />
                            <div className="w-11 h-6 peer-focus:outline-none rounded-full peer bg-gray-500 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-Yellow"></div>
                            <span className="ml-3 text-sm font-medium text-white">Modificar fecha</span>
                        </label>}
                    </div>
                    {dateModify && typeForm === "update" && <h1 className="text-Yellow font-bold text-sm text-left mb-8 px-2">¡Importante! <span className="text-white">Para modificar la fecha, la nueva fecha límite de aplicación tiene que ser igual o mayor a la actual.</span></h1>}
                    <div className="flex flex-wrap">

                        <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>

                            <label htmlFor="title" className="text-white mb-8 lg:text-sm xl:text-base">
                                Titulo del puesto <span className="text-red-500">*</span>
                            </label>

                            {isLoading ? <Skeleton className="form-control block w-full mt-2 px-4 py-3 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                            :
                            <input
                                name="name"
                                onChange={getInputs}
                                value={profesionalServices.name}
                                type="text"
                                className="form-control block w-full mt-2 px-4 py-3 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                            />}

                            {alert.filter(input => input.input == "name" && input.status === true).map((message, key) => (
                                <span key={key} className="text-red-500 text-start">{message.message}</span>
                            ))}
                           
                        </div>
                    
                        <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>

                            <label htmlFor="typeof" className="text-white mb-8 lg:text-sm xl:text-base">
                                Tipo de servicio <span className="text-red-500">*</span>
                            </label>

                            {isLoading ? <Skeleton className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                            :
                            <select
                                name="otherTypeService"
                                onChange={getInputs}
                                value={profesionalServices.otherTypeService}
                                className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none">
                                <option className="text-white" value={""}>Selecciona el tipo</option>
                                <option value="1" className="text-white">Consultoría</option>
                                <option value="2" className="text-white">Asesoramiento</option>
                                <option value="3" className="text-white">Diseño Gráfico</option>
                                <option value="4" className="text-white">Desarrollo de software</option>
                            </select>}

                            {alert.filter(input => input.input == "typeService" && input.status === true).map((message, key) => (
                                <span key={key} className="text-red-500 text-start">{message.message}</span>
                            ))}
                           
                        </div>

                        <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                            <label htmlFor="modality" className="text-white mb-8 lg:text-sm xl:text-base">
                                Remuneración <span className="text-red-500">*</span>
                            </label>
                            {isLoading ? <Skeleton className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                            :
                            <select
                                name="idTypeSalary"
                                onChange={getInputs}
                                value={profesionalServices.idTypeSalary}
                                className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none">
                                <option className="text-white" selected>Selecciona el tipo</option>
                                <option value={"1"} className="text-white">Por hora</option>
                                <option value={"2"} className="text-white">Por proyecto</option>
                            </select>}

                            {alert.filter(input => input.input == "typeSalary" && input.status === true).map((message, key) => (
                                <span key={key} className="text-red-500 text-start">{message.message}</span>
                            ))}
                          
                        </div>
                      
                        {dateModify && <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                            <label htmlFor="dateLimitToApply" className="text-white mb-8 lg:text-sm xl:text-base">
                                Fecha limite de aplicación <span className="text-red-500">*</span>
                            </label>
                            {isLoading ? <Skeleton className="form-control block w-full mt-2 px-4 py-3 text-lg text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                            :
                            <input
                                name="dateLimitToApply"
                                onChange={getInputs}
                                value={profesionalServices.dateLimitToApply}
                                type="date"
                                className="form-control block w-full mt-2 px-4 py-3 text-lg text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                            />}

                            {alert.filter(input => input.input == "dateLimitToApply" && input.status === true).map((message, key) => (
                                <span key={key} className="text-red-500 text-start">{message.message}</span>
                            ))}
                         
                        </div>}

                        <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                            <label htmlFor="title" className="text-white mb-8 lg:text-sm xl:text-base">
                                Salario <span className="text-red-500">*</span>
                            </label>
                            {isLoading ? <Skeleton className="form-control block w-full mt-2 px-4 py-3 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                            :
                            <input
                                name="salary"
                                onChange={getInputs}
                                value={profesionalServices.salary}
                                type="number"
                                className="form-control block w-full mt-2 px-4 py-3 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                            />}
                           {alert.filter(input => input.input == "salary" && input.status === true).map((message, key) => (
                                <span key={key} className="text-red-500 text-start">{message.message}</span>
                            ))}
                        </div>
                       
                        <div className={`mb-6 text-start px-2 w-full`}>
                            <label htmlFor="description" className="text-white mb-8 lg:text-sm xl:text-base">
                                Descripción de la pasantía <span className="text-red-500">*</span>
                            </label>
                            <div className="relative">
                                <Skeleton className={`${isLoading ? "block" : "hidden"} absolute w-full h-full`} />
                                
                                <textarea
                                    name="description"
                                    onChange={getInputs}
                                    value={profesionalServices.description}
                                    className={`${isLoading ? "invisible" : "visible"} form-control block w-full mt-2 px-4 py-3 text-lg font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none`}
                                    rows={4}
                                    placeholder="Descripción"
                                />
                            </div>

                            {alert.filter(input => input.input == "description" && input.status === true).map((message, key) => (
                                <span key={key} className="text-red-500 text-start">{message.message}</span>
                            ))}
                            
                        </div>

                    </div>

                    <div className="flex flex-wrap justify-center text-justify md:text-left">
                        <div className="w-full md:w-1/3 flex items-center">
                            
                            <div className="relative w-full">
                                <Skeleton className={`${isLoading ? "block" : "hidden"} absolute w-full h-full`} />
                                <button
                                    type="submit"
                                    className={`${isLoading ? "invisible" : "visible"} inline-block px-7 py-5 bg-Yellow text-white font-medium lg:text-sm xl:text-base leading-snug rounded shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out w-full text-center`}>

                                    {(typeForm == "update") && <>Actualizar servicio profesional</>}
                                    {(typeForm == "create") && <>Publicar servicio profesional</> }
                                    
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default FormProfesionalServices;