import imagenesDetallesProject from "../assets/imgDetallesProject/imgDetallesProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Modal from "../components/modals/Modal";
import { useEffect, useState } from "react";
import axios from "axios";
import { ProjectInfo } from "../Interfaces/InfoProyect";
import { HandleSession } from "../function/Login";
import CardsProjectSkeleton from "../components/Skeleton/cardsProyect/cardsProyectSkeleton";
import LazyLoad from 'react-lazyload';
import { TransitionGroup } from 'react-transition-group';
import React, { Suspense } from "react";

import imgListProyect from '../assets/imgs/no_hay_proyectos.svg';
import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";
import Internships from "../components/opportunities/internships/internships";
import ProfessionalServices from "../components/opportunities/professionalServices/professionalServices";
import Projects from "../components/TabsProfileFreelancers/content-tabs/Projects";
import NewViemProjects from "../components/opportunities/projects/projects";
import JobVacancies from "../components/opportunities/jobVacancies/jobVacancies";
import NotOpportunies from "../assets/imgs/no_hay_proyectos.svg";


const ListProject = () => {

    const internshipOpportunity = {
        application_deadline: "",
        created_at: "",
        description: "",
        end_date: "",
        id: 0,
        id_company: 0,
        id_type_internship: 0,
        id_type_opportunities: 0,
        id_work_modality: 0,
        job_title: "",
        location: "",
        remuneration: "",
        schedules: "",
        start_date: "",
        status: 0,
        updated_at: ""
    };
    const [internships, setInternships] = useState([internshipOpportunity]);

    const initialPlaza = {
        application_deadline: "",
        created_at: "",
        description: "",
        end_date: "",
        id: "",
        id_company: "",
        id_type_contract: "",
        id_type_job_vacancy: "",
        id_type_opportunities: "",
        id_type_schedule: "",
        id_work_modality: "",
        job_title: "",
        location: "",
        salary: "",
        schedules: "",
        start_date: "",
        status: "",
        updated_at: "",
    };
    const [jobVacancies, setjobVacancies] = useState([initialPlaza]);

    const initialProfessionalServices = {
        application_deadline: "",
        created_at: "",
        description: "",
        id: "",
        id_company: "",
        id_type_opportunities: "",
        id_type_salary: "",
        id_type_service: "",
        name: "",
        other_type_service: "",
        salary: "",
        status: "",
        updated_at: ""
    };
    const [professionalServices, setProfessionalServices] = useState([initialProfessionalServices]);

    const initialProject = {
        accept_proposals_files: "",
        allowPublish: "",
        application_deadline: "",
        assigned_status: "",
        budget_id: "",
        company_project_file: "",
        created_at: "",
        delivery_date: "",
        description: "",
        file_folder_path: "",
        freelance_id: "",
        id: "",
        id_type_opportunities: "",
        knowledges_tags: "",
        project_name: "",
        public_info: "",
        publicationStatus: "",
        publication_date: "",
        rating: "",
        s_types_id: "",
        status: "",
        updated_at: "",
        user_id: "",
    };
    const [projects, setProjects] = useState([initialProject]);

    const [type_user_session, settype_user_session] = useState(0);

    // Cookies
    const cookies = new Cookies();
    const getSesion = cookies.get("Bearer");

    useEffect(() => {

        if (getSesion.type) {
            settype_user_session(getSesion.type);
        } else {
            settype_user_session(getSesion.type);
        };

        const ListInternships = async () => {
            let urlListOpportunity = `${process.env.REACT_APP_API_URI}opportunity/search?id_type_opportunity=1`;
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    // 'ApiToken': `Bearer ${getSesion.token}`
                }
            };

            try {
                const resp = await axios.get(urlListOpportunity, config);
                const getInternships = resp.data.data;

                if (getInternships.length != 0) {
                    setInternships(getInternships);
                } else {
                    setInternships([internshipOpportunity]);
                };

            } catch (err) {
                console.error(err);
                setInternships([internshipOpportunity]);
            };
        };
        ListInternships();

        const ListJob = async () => {
            let urlListOpportunity = `${process.env.REACT_APP_API_URI}opportunity/search?id_type_opportunity=2`;
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    // 'ApiToken': `Bearer ${getSesion.token}`
                }
            };

            try {
                const resp = await axios.get(urlListOpportunity, config);
                const getJob = resp.data.data;

                console.log("getJob ==> ", getJob);

                if (getJob.length != 0) {
                    setjobVacancies(getJob);
                } else {
                    setjobVacancies([initialPlaza]);
                };

            } catch (err) {
                console.error(err);
                setjobVacancies([initialPlaza]);
            };
        };
        ListJob();

        const professionalServices = async () => {

            let urlListOpportunity = `${process.env.REACT_APP_API_URI}opportunity/search?id_type_opportunity=3`;

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    // 'ApiToken': `Bearer ${getSesion.token}`
                }
            };

            try {
                const resp = await axios.get(urlListOpportunity, config);

                const getProfessionalServices = resp.data.data;


                if (getProfessionalServices.length != 0) {
                    setProfessionalServices(getProfessionalServices);
                } else {
                    setProfessionalServices([initialProfessionalServices]);
                }

            } catch (err) {
                console.error(err);
                setProfessionalServices([initialProfessionalServices]);
            };

        };
        professionalServices();

        const projects = async () => {

            let urlListOpportunity = `${process.env.REACT_APP_API_URI}opportunity/search?id_type_opportunity=4`;

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    // 'ApiToken': `Bearer ${getSesion.token}`
                }
            };

            try {

                const resp = await axios.get(urlListOpportunity, config);
                const getprojects = resp.data.data;

                console.log("getprojects->", getprojects);

                if (getprojects.length != 0) {
                    setProjects(getprojects);
                } else {
                    setProjects([initialProject]);
                };

            } catch (err) {
                console.error(err);
                setProjects([initialProject]);
            };

        };
        projects();
    }, []);

    return (
        <>
            <div className="bg-turquoise">
                <section className="block -mt-px md:flex md:flex-wrap">
                    <div className="bg-gradient-to-b from-Light-purple via-turquoise to-turquoise md:w-full lg:w-100% flex justify-center pb-5 lg:py-5 lg:px-10 px-6">
                        <div className="mx-1 lg:mx-8 w-full">
                            <div className="flex flex-wrap justify-center mt-6 mb-8 lg:mt-0">
                                <h1 className="w-full text-Yellow text-center font-bold text-2xl sm:text-3xl">Oportunidades</h1>
                                { (internships[0].job_title == "") 
                                    && (jobVacancies[0].job_title == "") 
                                    && (professionalServices[0].name == "") 
                                    && (projects[0].project_name == "") 
                                    &&  <>
                                            <h5 className="w-full text-white text-center font-bold text-lg sm:text-xl">No se encontraron oportunidades</h5>
                                            <img src={NotOpportunies} className="w-3/5 mt-10" alt="No hay projectos" />
                                        </>
                                }
                            </div>

                            {(internships[0].job_title != "") && <Internships data={internships} type_user_session={type_user_session} />}

                            {(jobVacancies[0].job_title != "") && <JobVacancies data={jobVacancies} type_user_session={type_user_session} />}

                            {(professionalServices[0].name != "" && <ProfessionalServices data={professionalServices} type_user_session={type_user_session} />)}

                            {(projects[0].project_name) && <NewViemProjects data={projects} type_user_session={type_user_session} />}
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}


export default ListProject;