import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { faPenToSquare, faFolderPlus, faFilePen, faMinus } from "@fortawesome/free-solid-svg-icons";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";


interface Props {
   animation: String;
   idFreeRouter: String | undefined;
   state: String
}

const initialStateWorkExperience = {
   cargo: "",
   created_at: "",
   descripcion_puesto: "",
   fecha_fin: "",
   fecha_inicio: "",
   id: 0,
   nombre_empresa: "",
   updated_at: "",
   user_id: 0,
   actual_work:0
}

const WorkExperience = ({ animation, idFreeRouter, state }: Props) => {

   const [workExperiences, setWorkExperience] = useState([initialStateWorkExperience]);

   const monthNames = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];

   const [IsLoading, setIsLoading] = useState(true);

   const MySwal = withReactContent(Swal);


   // Cookies
   const cookies = new Cookies();

   const getSesion = cookies.get("Bearer"); 


   function getDate(date:string){
      
      const formatDate = date; 
      var partesFecha = formatDate.split("-");
      // console.log(partesFecha);

      const monthR = (Number(partesFecha[1]) - 1);
      const dateWorkExperiences = monthNames[monthR] +' '+ partesFecha[0];
      
      return dateWorkExperiences;

   };

   const { id } = cookies.get('Bearer');
      let urlCountry = "";

      if (state == "Public") {
         
         urlCountry = `${process.env.REACT_APP_API_URI}freelancer/list/work-experience/${idFreeRouter}`;
         
      } else if (state == "session") {
      
         urlCountry = `${process.env.REACT_APP_API_URI}freelancer/list/work-experience/${id}`;
      
      };

      async function fetchData(id: number) {
         setIsLoading(true);
         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
               'ApiToken': `Bearer ${getSesion.token}`
            }
         };

         try {

            setWorkExperience([initialStateWorkExperience])

            const resp = await axios.get(urlCountry, config);

            const dataExperienceFormal = resp.data.data;

            const experienceFormal = dataExperienceFormal.filter((element: { work_experience_type: number; }) =>{
               return element.work_experience_type == 1;
            });

            setWorkExperience(experienceFormal);

            setIsLoading(false);

         } catch (err) {
            // Handle Error Here
            setWorkExperience([]);
            setIsLoading(false);
            console.error(err);
         }
      };

   useEffect(() => {

      fetchData(id);

   }, []);

   const deleteWorkExperience = async (id:number) => {
      MySwal.fire({
         title: '¿Estás seguro?',
         text: "¡No podrás revertir esto!",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#d33',
         cancelButtonColor: '#3085d6',
         confirmButtonText: 'Sí, eliminar!',
         cancelButtonText: 'Cancelar'
       }).then(async (result) => {
         if (result.isConfirmed) {
            let url = `${process.env.REACT_APP_API_URI}freelancer/delete/work-experience/${id}`;
            let config = {
               headers: {
                   'Content-Type': 'application/json',
                   'Accept': 'application/json',
                   'ApiToken': `Bearer ${getSesion.token}`
                   }
            };
            try {
               setIsLoading(true);
               const resp = await axios.put(url, {}, config);
               console.log('deleteWorkExperience', resp.data);
               setIsLoading(false);
               MySwal.fire(
                  '¡Registro eliminado!',
                  '',
                  'success'
               )

               fetchData(id);
            } catch (err) {
               console.error('Error', err);
               setIsLoading(false);
               MySwal.fire(
                  'Error!',
                  '¡Algo salió mal!',
                  'error'
               );
            }
            // console.log("Se eliminó el registro", id);
         }
      });
    }

   return (
      <>

         {
            (state == "session") &&
            <div className="w-full text-center">
               <Link to="/registrar-experiencia-laboral/0" className="mb-4 inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded-lg shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
               Agregar
                  {/* <FontAwesomeIcon className="text-white text-lg" icon={faFolderPlus} /> */}
               </Link>
            </div>
         }

         <div className={`text-white ${animation}`} id="contenedorExperienciaLaboral" role="tabpanel" aria-labelledby="settings-tab">

            <div className="flex flex-wrap">

               <>
                  {IsLoading 
                  ? 
                     <>
                        <div className="w-full sm:w-1/2 p-2">
                           <div className="bg-cyan-900 p-5 rounded-lg ">
                              <h2 className="text-start"><Skeleton count={1} width={150} /></h2>
                              <h5 className="text-orange-500 text-start text-xs"><Skeleton count={1} width={240} />  <Skeleton count={1} width={240} /></h5>
                              <div className="mt-4">
                                 <div className="text-start float-left"><Skeleton count={1}  /></div>
                                 <p className="text-justify text-xs"><Skeleton count={4} /></p>
                              </div>
                           </div>
                        </div>
                        <div className="w-full sm:w-1/2 p-2">
                           <div className="bg-cyan-900 p-5 rounded-lg ">
                              <h2 className="text-start"><Skeleton count={1} width={150} /></h2>
                              <h5 className="text-orange-500 text-start text-xs"><Skeleton count={1} width={240} />  <Skeleton count={1} width={240} /></h5>
                              <div className="mt-4">
                                 <div className="text-start float-left"><Skeleton count={1}  /></div>
                                 <p className="text-justify text-xs"><Skeleton count={4} /></p>
                              </div>
                           </div>
                        </div>
                        <div className="w-full sm:w-1/2 p-2">
                           <div className="bg-cyan-900 p-5 rounded-lg ">
                              <h2 className="text-start"><Skeleton count={1} width={150} /></h2>
                              <h5 className="text-orange-500 text-start text-xs"><Skeleton count={1} width={240} />  <Skeleton count={1} width={240} /></h5>
                              <div className="mt-4">
                                 <div className="text-start float-left"><Skeleton count={1}  /></div>
                                 <p className="text-justify text-xs"><Skeleton count={4} /></p>
                              </div>
                           </div>
                        </div>
                        <div className="w-full sm:w-1/2 p-2">
                           <div className="bg-cyan-900 p-5 rounded-lg ">
                              <h2 className="text-start"><Skeleton count={1} width={150} /></h2>
                              <h5 className="text-orange-500 text-start text-xs"><Skeleton count={1} width={240} />  <Skeleton count={1} width={240} /></h5>
                              <div className="mt-4">
                                 <div className="text-start float-left"><Skeleton count={1}  /></div>
                                 <p className="text-justify text-xs"><Skeleton count={4} /></p>
                              </div>
                           </div>
                        </div>
                     </>
                     :
                     (workExperiences.length !== 0)
                     ?
                     workExperiences.map((workExperience, key) => {
                        return (
                           (workExperience.nombre_empresa != "" &&
                              <>
                                 <div className="w-full sm:w-1/2 xl:w-1/3 p-5">
                                 <div className="bg-Light-purple p-6 rounded-md shadow-md h-full flex">
                                    <div className="flex flex-wrap justify-between items-center mb-4 sm:mb-0">
                                       <div className="flex flex-wrap justify-center md:justify-start items-center w-full md:w-2/3 order-2 md:order-1 mt-5 md:mt-0">
                                          <div className="text-lg font-semibold text-white w-full pr-4">
                                             {workExperience.nombre_empresa}
                                          </div>
                                          <div className="text-sm text-white w-full pr-4">
                                             {workExperience.cargo}
                                          </div>
                                          <div className="text-sm text-white w-full pr-4">
                                             {workExperience.descripcion_puesto.length > 100 ? workExperience.descripcion_puesto.substring(0, 100) + "..." : workExperience.descripcion_puesto}
                                          </div>
                                          <div className="text-xs text-orange-500 w-full pr-4 text-start my-2">
                                          { workExperience.actual_work === 2 ?
                                             <>Desde: {getDate(workExperience.fecha_inicio)} / {getDate(workExperience.fecha_fin)} </>
                                             : workExperience.actual_work === 1 &&
                                             <div>
                                                <h5 className="text-orange-500 text-start text-xs">{getDate(workExperience.fecha_inicio)} / actualidad </h5>  
                                             </div>
                                          }
                                          </div>
                                       </div>
                                       <div className="flex justify-center md:justify-end space-x-2 w-full md:w-1/3 order-1 md:order-2">
                                          {
                                             (state == "session") &&
                                             <>
                                             <Link title="Editar" to={`/registrar-experiencia-laboral/${workExperience.id}`} className="bg-Yellow hover:bg-yellow-600 text-white px-4 py-2 rounded-md"><FontAwesomeIcon className="text-white text-lg" icon={faFilePen} /></Link>
                                             <button title="Editar" onClick={
                                                () => deleteWorkExperience(Number(workExperience.id))
                                             } className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md flex justify-center items-center"><FontAwesomeIcon className="text-white text-lg" icon={faMinus} /></button>
                                             </>
                                          }
                                          
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              {/* <div key={key} className="w-full sm:w-1/2 p-2">
                                 <div className="bg-cyan-900 p-5 rounded-lg relative h-full">
                                    <h2 className="text-start">{workExperience.nombre_empresa}</h2>

                                    { workExperience.actual_work === 2 ?
                                          <h5 className="text-orange-500 text-start text-xs">Desde: {getDate(workExperience.fecha_inicio)} / {getDate(workExperience.fecha_fin)} </h5>
                                       : workExperience.actual_work === 1 ?
                                       <div>
                                          <h5 className="text-orange-500 text-start text-xs">{getDate(workExperience.fecha_inicio)} / actualidad </h5>  
                                       </div>
                                       :
                                       <div>
                                          
                                       </div>
                                    }

                                    <div className="mt-4">
                                       <div className="text-start float-left">{workExperience.cargo}</div>
                                       <br />
                                       <p className="text-justify text-xs whitespace-pre-line">{workExperience.descripcion_puesto}</p>
                                    </div>

                                    {
                                       (state == "session") &&
                                       <Link to={`/registrar-experiencia-laboral/${workExperience.id}`} className="mt-2 mb-4 inline-block p-1 bg-Yellow text-white font-medium text-xs leading-tight uppercase shadow-md hover:bg-amber-500 hover:shadow-lg focus:bg-amber-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-600 active:shadow-lg transition duration-150 ease-in-out absolute top-0 right-2 rounded-lg">
                                          <FontAwesomeIcon className="text-white text-lg" icon={faPenToSquare} />
                                       </Link>
                                    }
                                 </div>


                              </div> */}
                              </>
                           )
                        )
                     })
                  :
                  <p className="text-center w-full">No se encontraron experiencias laborales registradas</p>
                  }   
               </>

            </div>

         </div>

      </>
   )
}

export default WorkExperience