import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import { Link, useParams, useNavigate } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import documentsProject from "../assets/Icons/documentsProject.png";

function InformactionProject() {
    const id_internships = useParams();

    //Cookies
    const cookies = new Cookies();
    const getSesion = cookies.get("Bearer");
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();

    const initialProject = {
        accept_proposals_files:"",
        allowPublish: "",
        assigned_status:"",
        budget:{id: "", range: ''},
        budget_id:"",
        chat_room:"",
        company_name:"",
        company_project_file:"",
        delivery_date:"",
        description:"",
        id:"", 
        knowledges_tags:[{id:"",name:""}],
        photo:"",
        project_name:"",
        public_info:"",
        publication_date:"",
        rating:"",
        s_types_id:"",
        specialization_Name:""
    };

    const [projectsInfo, setProjectsInfo] = useState(initialProject);

    const projects = async () => {
        // let urlListOpportunity = `${process.env.REACT_APP_API_URI}opportunity/search?id_type_opportunity=4`;
        let urlListOpportunity = `${process.env.REACT_APP_API_URI}project/get/${id_internships.id}?id_type_opportunities=4`;
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        };
        try {
            const resp = await axios.get(urlListOpportunity, config);
            const getprojects = resp.data;
            console.log(getprojects);
            // console.log(getprojects);
            setProjectsInfo(getprojects);

        } catch (err) {
            console.error(err);
        };

    };

    const initialStateListSpecialization = {
        created_at: "",
        id: 0,
        name: "",
        updated_at: ""
    };
    const [specializations, setSpecializations] = useState([initialStateListSpecialization]);

    async function getSpecialties() {
        const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/types`;
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };
        try {
            const resp = await axios.get(urlCountry, config);
            setSpecializations(resp.data.specializationTypes);
        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    };

    const initialStateBudget = {
        id: "",
        range: ""
    };

    const [listBudget, setListBudget] = useState([initialStateBudget]);

    async function getBudget() {
        const urlBudget = `${process.env.REACT_APP_API_URI}budget`;
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };
        try {
            const resp = await axios.get(urlBudget, config);
            setListBudget(resp.data);
        } catch (err) {
            console.error(err);
        }
    };

    const applyJob = () => {
        MySwal.fire({
            title: "¿Está seguro?",
            text: "Sí, deseo enviar mi perfil para este proyecto",
            color: "#fff",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Enviar",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) sendData();
        });
    };

    const sendData = () => {
        const url = `${process.env.REACT_APP_API_URI}freelancer/apply-project/${getSesion.id}`;
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        }
        const newData = {
            budget: "",
            id_type_opportunities: 4,
            project_id: id_internships.id,
            project_proposal: "",
        };
        axios.post(url, newData, config)
            .then(response => {
                MySwal.fire({
                    title: "¡Completado!",
                    text: "Tu perfil ha sido enviado con éxito! En los próximos días recibirás una notificación sobre el proceso a través de tu correo electrónico.",
                    color: "#fff",
                    icon: "success"
                });
                setTimeout(() => { navigate('/perfil-freelancer'); }, 5000);
            })
            .catch(err => {
                console.log(err);
                MySwal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                });
            })
    }

    useEffect(() => {
        projects();
        getSpecialties();
        getBudget();
    }, [])

    return (
        <div className="bg-turquoise">
            <section className="block -mt-px md:flex md:flex-wrap">
                <div className="md:w-full lg:w-5/5 flex flex-wrap justify-center pb-5 lg:py-5 md:pt-4 lg-pt-0">
                    <div className="w-full md:w-full flex justify-center">
                        <div className="w-11/12">
                            <div className="flex justify-start pb-0 lg:pb-4 py-4">
                                <Link to="" onClick={() => window.history.go(-1)} className='w-16 h-16'>
                                    <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                                </Link>
                                <h1 className="text-Yellow text-center font-bold text-4xl w-full">Proyecto</h1>
                            </div>
                            <div className="flex flex-col ">
                                <h1 className="w-full text-left my-4 text-white lg:text-4xl md:text-3xl sm:text-3xl xs:text-xl">
                                    {projectsInfo.project_name}
                                </h1>
                                <div className="xl:w-full lg:w-full md:w-full sm:w-full xs:w-full mx-auto flex flex-col gap-4 items-start relative">
                                    <p className="w-fit text-white dark:text-gray-400 text-md">
                                        {projectsInfo.description}
                                    </p>
                                    <div className="w-full my-auto py-6 flex flex-col justify-center gap-2">
                                        <div className="w-full flex sm:flex-row xs:flex-col gap-2 justify-center">
                                            <div className="w-full">
                                                <dl className="text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                                                    <div className="flex flex-col pb-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Categoría</dt>
                                                        <dd className="text-lg text-white">
                                                            {
                                                                specializations.map((specialization) => {
                                                                    if (specialization.id == projectsInfo.s_types_id) {
                                                                        return (
                                                                            specialization.name
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </dd>
                                                    </div>
                                                    <div className="flex flex-col py-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Habilidades</dt>
                                                        <dd className="text-lg text-white pb-3">
                                                            {
                                                                projectsInfo.knowledges_tags.map((knowledges) =>{
                                                                    return(
                                                                            knowledges.name +" "
                                                                    ) 
                                                                })
                                                            }
                                                        </dd>
                                                    </div>
                                                    <div className="flex flex-col py-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Presupuesto disponible</dt>
                                                        <dd className="text-lg text-white">
                                                            { projectsInfo.budget.range }
                                                        </dd>
                                                    </div>
                                                </dl>
                                            </div>
                                            <div className="w-full">
                                                <dl className="text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                                                    <div className="flex flex-col pb-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Fecha de entrega del proyecto</dt>
                                                        <dd className="text-lg text-white">
                                                            {projectsInfo.delivery_date}
                                                        </dd>
                                                    </div>
                                                    {/* <div className="flex flex-col py-3">
                                                        <dt className="mb-1 text-Yellow md:text-lg dark:text-gray-400">Documento/s complementario</dt>
                                                        <dd className="text-lg text-white">
                                                            <img src={documentsProject} className='w-10 h-10' />
                                                        </dd>
                                                    </div> */}
                                                    <div className="flex flex-col pb-3">
                                                        <button onClick={() => applyJob()} className="w-1/2 text-center rounded-lg bg-Yellow text-white border-0 py-4 px-3 lg:text-base md:text-sm mt-4">
                                                            Enviar mi perfil
                                                        </button>
                                                    </div>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default InformactionProject;
