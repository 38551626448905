import { faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { StarRating } from "star-rating-react-ts";
import IconLoadingWhite from "../iconLoading/IconLoadingWhite";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from "universal-cookie";
import axios from "axios";
import { useState } from 'react';
import imgDefault from '../../assets/imgEditPerfil/user.png';

interface Props {
    proposal: any;
    dataWork: any;
    opportunityType: any;
    getProposalFree: any;
    setSelectedCards: any;
    selectedCards: any;
}

export default function ProposalOfProject({
    proposal,
    dataWork,
    opportunityType,
    getProposalFree,
    setSelectedCards,
    selectedCards,
}: Props) {

    const MySwal = withReactContent(Swal);
    const cookies = new Cookies();
    const getSesion = cookies.get('Bearer');

    const handleCardClick = (proposal: { id: number, user:any }): void => {
        const isAlreadySelected = selectedCards.some(
            (selectedCard: any) => selectedCard.id === proposal.id
        );
        isAlreadySelected && setSelectedCards(selectedCards.filter((c: any) => c.id !== proposal.id));
        selectedCards.length < 3 && !isAlreadySelected && setSelectedCards([...selectedCards, proposal]);
    };

    //Handel error img profile not found
   const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        (e.target as HTMLImageElement).src = imgDefault;  // Si la imagen no carga, usamos la imagen por defecto
    }

    return (
        <div className="w-full relative">
            <div className="flex flex-wrap">
                <div className="my-2 w-full pr-2 2xl:p-2">
                    <div className={` ${proposal.state == "1" ? "bg-purple-cards" : "bg-Dark-purple"} rounded flex flex-wrap py-4 px-4 sm:px-4`}>
                        <div className="flex justify-center mx-2 items-center w-full md:w-1/4 xl:w-22% 2xl:w-30%">
                            <Link to={`/freelancer/share/${proposal.freelancer.user.username}`} target="_blank">
                                <img className="w-20 h-20 rounded-full object-cover object-center" onError={handleError} src={proposal.photo ? `${process.env.REACT_APP_PHOTO_REGISTRO_URI}photo/student/${proposal.photo}` : imgDefault} style={{ maxWidth: "190px" }} alt="Foto del usuario" />
                                {/* <img src={`${process.env.REACT_APP_PHOTO_URI}freelancer/profile-img/${proposal.photo}`} className="h-24 w-24 md:h-16 md:w-16 2xl:w-24 2xl:h-24 rounded-full overflow-hidden object-cover object-center" alt="" /> */}
                            </Link>
                        </div>
                        <div className="w-full flex flex-wrap md:w-65% xl:w-65% 2xl:w-3/5 py-2">
                            {/* <h1 className="text-white font-roboto font-bold text-basic h-9 w-full overflow-hidden py-2">{proposal.nameProject}</h1> */}

                            <h3 className="text-white text-center w-full text-sm truncate">
                                {/* <FontAwesomeIcon className="pr-1" icon={faUser} /> */}
                                <span className="text-white">
                                    {proposal?.freelancer?.user?.names} {proposal.status_assigned} {proposal?.freelancer?.user?.lastnames}
                                </span>
                            </h3>
                            <h3 className="text-white text-center w-full text-sm truncate">
                                {/* <FontAwesomeIcon className="pr-1" icon={faEnvelope} /> */}
                                <span className="text-white">{proposal.freelancer.user.email}</span>
                            </h3>
                            {proposal.state == "1" && (
                                // <h3 className="text-white text-sm cursor-pointer">
                                //     <Link
                                //         to={`/mensajes-proyecto/empresa/${GetIdProyecto}/${proposal.freelace_id}/${assignedStatus}`}
                                //     >
                                //         <FontAwesomeIcon className="pr-1" icon={faComment} />
                                //         <span className="text-white">
                                //             {" "}
                                //             Mensajes +{newMessageCaht}
                                //         </span>
                                //     </Link>
                                // </h3>
                                <></>
                            )}
                        </div>

                        <section className="w-full">
                            <div className="flex justify-center">
                                {/* Action when the freelance is unassigned */}
                                {proposal.status_assigned === 2 && 
                                    <button onClick={() => handleCardClick(proposal)} className="h-9 flex justify-center bg-purple-cards items-center text-center font-roboto  xl:text-base text-white rounded px-4 md:px-2 lg:px-4 py-2 m-2 cursor-pointer">
                                        {selectedCards.some( (selectedCard: any) => selectedCard.id === proposal.id) ? 'Remover' : 'Seleccionar'}
                                    </button>
                                }
                                <Link to={`/freelancer/share/${proposal.freelancer.user.username}`} target="_blank" className="h-9 flex justify-center bg-Yellow items-center text-center font-roboto  xl:text-base text-white rounded px-4 md:px-2 lg:px-4 py-2 m-2 cursor-pointer">
                                    Ver perfil
                                </Link>
                            </div>
                        </section>

                        {/* Indicador de selección */}
                        {  selectedCards.some( (selectedCard: any) => selectedCard.id === proposal.id) && (
                            <div className="absolute top-3 right-3 w-6 h-6 bg-white rounded-full flex items-center justify-center text-Dark-purple">
                                ✓
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}