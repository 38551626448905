import React, { useEffect, useState } from 'react';
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";


import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';

import axios from 'axios';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Img
import logo_chambaticonLetras from '../../assets/logos/chambaticon_navbar.png';
import imagenesLogin from '../../assets/imgLogin/imagenesLogin';

import imgDefault from '../../assets/imgEditPerfil/user.png';


import '../../css/main.css';

// Functions
import { HandleSession } from '../../function/Login';
import Cookies from 'universal-cookie';
import { FirebaseConfig } from '../../config/FiraBase';
import PhotoProfileSkeleton from '../Skeleton/ProfileFreelancer/skeletonPhotoProfile';
import ModalLogin from '../modals/modalLogin';


const Navbar = () => {

   let location = useLocation();

   const [isActive, setIsActive] = useState(true);

   /* Funcion para mostrar contenido de navbar en responsive */
   const [toggleShowAndHide, setToggleShowAndHide] = useState<Boolean>(false);

   const [addClass, setaddClass] = useState<string>('hidden');

   const navigate = useNavigate();

   // Cookies
   const cookies = new Cookies();

   const MySwal = withReactContent(Swal);

   const [imgProfile, setImgProfile] = useState<any>(imgDefault);
   const [photo, setPhoto] = useState<null | string>(null);

   const getSesion = cookies.get('Bearer');
   // console.log(getSesion , 'ID Freelancer');

   const verifySession = HandleSession();


   async function getProfilePhotoFreelancer() {

      const idUser = cookies.get('Bearer');
      setMostrarImg(localStorage.getItem("photo")); 

      const urlProfileFreelancer = `${process.env.REACT_APP_API_URI}freelancer/profile/${idUser.id}`;

      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'ApiToken': `Bearer ${getSesion.token}`
         }
      };

      try {

         const resp = await axios.get(urlProfileFreelancer, config);
         // console.log("resp ==>", resp);

         if(resp.data.data.dui !=""){
            getProfileInfo("dui", resp.data.data.dui);
         }else{
            getProfileInfo("nit", resp.data.data.nit);
         };


         // setImgProfile(`${process.env.REACT_APP_PHOTO_URI}freelancer/profile-img/${resp.data.data.photo}`);


      } catch (err) {
         // Handle Error Here
         console.error(err);
      }
   };

   const [MostrarImg, setMostrarImg] = useState<null | string>(imgDefault);
   const [loadImgProfile, setloadImgProfile] = useState(true);

   async function getProfileInfo(typeDocument: string, document: string) {

      const url = `${process.env.REACT_APP_API_URI}registro/student-profile-image`;

      let config = {
         headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
         }
      };

      const verifyPhoto = {
         typeDocument: typeDocument,
         document: document
      };


      await axios.post(url, verifyPhoto, config)
         .then(response => {

            // console.log("response ==>", response);

            let imgCompany: any = "";

            if (response.data.data === null || response.data.data === "") {
               imgCompany = null;
            } else {
               imgCompany = response.data.data;
            };

            // console.log("imgCompany ==>", imgCompany);

            // setMostrarImg(imgCompany);
            setMostrarImg(localStorage.getItem("photo"));
            setTimeout(() => {
               setloadImgProfile(false);
            }, 1000);

         })
         .catch(err => {
            console.log(err);

         });
   };

   async function getProfilePhotoEmpresa() {
      const idUser = cookies.get('Bearer');

      const urlProfileCompany = `${process.env.REACT_APP_API_URI}company/profile/${idUser.id}`;
      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'ApiToken': `Bearer ${getSesion.token}`
         }
      };
      try {

         const resp = await axios.get(urlProfileCompany, config);

         console.log("Photo empresa ===>", resp.data.photo);


         let imgCompany: any = "";

         if (resp.data.photo === null || resp.data.photo === "") {
            imgCompany = imgDefault;
         } else {
            imgCompany = `${process.env.REACT_APP_PHOTO_URI}company-image/${resp.data.photo}`;
         };
         setMostrarImg(imgCompany);
         setTimeout(() => {
            setloadImgProfile(false);
         }, 1000);

         // setImgProfile(`${process.env.REACT_APP_PHOTO_URI}company-image/${resp.data.photo}`);

      } catch (err) {
         // Handle Error Here
         console.error(err);
      }
   }


   function showNavbar() {
      toggleShowAndHide ? setToggleShowAndHide(false) : setToggleShowAndHide(true);

      if (!toggleShowAndHide) {
         setaddClass('flex');
      } else {
         setaddClass('hidden')
      }
   }

   function hideNavbar() {
      toggleShowAndHide ? setToggleShowAndHide(false) : setToggleShowAndHide(true);

      if (toggleShowAndHide) {
         setaddClass('hidden');
      }
   }


   // AuthCheck google and Initialize FiraBase
   const firebaseConfig = FirebaseConfig();

   // Initialize my App with Firebase
   const app = initializeApp(firebaseConfig);

   const auth = getAuth();

   // Get info login  of with facebook and google
   const AuthCheck = onAuthStateChanged(auth, (user) => {
      // console.log(user);
      if (user) {
         // console.log('open authentication');  
      } else {
         // console.log("authentication not initialized");
      }
   });



   const closeSession = () => {

      // get Session
      const getSesion = cookies.get('Bearer');

      const url = `${process.env.REACT_APP_API_URI}auth/logout`;

      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'ApiToken': `Bearer ${getSesion.token}`,
         }
      }

      axios.get(url, config).then(response => {
         // console.log(response);
         MySwal.fire({
            position: 'center',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 3500
         }).then(() => {

            signOut(auth);
            cookies.remove('Bearer');
            // redirect on logout
             window.location.replace('/');

         });

      }).catch(err => {
         // console.log(err);
         MySwal.fire({
            position: 'center',
            icon: 'error',
            title: "Error al cerrar sesión",
            showConfirmButton: false,
            timer: 5000
         }).then(() => {

            cookies.remove('Bearer');
            window.location.replace('/');

         });
      })
   };

   

   
   // states modals
   const [addClassModal, setAddClassModal] = useState(false);
   const [IsLoadingModal, setIsLoadingModal] = useState(false);

   //Handel error img profile not found
   const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
         (e.target as HTMLImageElement).src = imgDefault;  // Si la imagen no carga, usamos la imagen por defecto
   }

   useEffect(() => {
      // validate buttons and logo

      // console.log("verifySession ==>", verifySession);

      // let imgCompany = "";

      // if (verifySession.photoSession === null || verifySession.photoSession === "" || verifySession.photoSession === undefined || verifySession.photoSession === "undefined") {

      //    imgCompany = imgDefault;

      if (verifySession.type === 4) {
         getProfilePhotoEmpresa();
      } else {
         getProfilePhotoFreelancer();
      };

      // };
   }, [])



   return (
      <>
         <nav className="body-font bg-Light-purple text-gray-600 border-gray-200 p-5" id='ancla'>
            <div className="flex flex-wrap justify-around md:justify-start items-center mx-auto">
               {(verifySession.Session == true) ? (
                  <div className='w-1/3 md:w-10% lg:w-1/4'>
                     <NavLink onClick={hideNavbar} className="items-center flex xl:hidden" to={""}>
                        <img src={imagenesLogin.logoGrandeChambaticon} className="w-12" alt="Chambaticon Logo" />
                     </NavLink>
                     <NavLink onClick={hideNavbar} className="items-center hidden xl:flex tamañoContenedorImg" to={""}>
                        <img src={logo_chambaticonLetras} className="mr-3 h-6 sm:h-9 tamañoImg" alt="Chambaticon Logo" />
                     </NavLink>
                  </div>
               ) : (
                  <div className='w-1/3 md:w-10% lg:w-40%'>
                     <NavLink onClick={hideNavbar} className="items-center flex lg:hidden" to={""}>
                        <img src={imagenesLogin.logoGrandeChambaticon} className="w-12 " alt="Chambaticon Logo" />
                     </NavLink>
                     <NavLink onClick={hideNavbar} className="items-center hidden lg:flex tamañoContenedorImg" to={""}>
                        <img src={logo_chambaticonLetras} className="mr-3 h-6 sm:h-9 tamañoImg" alt="Chambaticon Logo" />
                     </NavLink>
                  </div>
               )}

               {(verifySession.Session == true) && (
                  <div className="w-1/3 md:w-10% lg:w-14% xl:w-10% flex items-center justify-center md:justify-end md:order-last">

                     {(verifySession.type === 3) && (
                        <button type="button" className="flex text-sm bg-gray-800 rounded-full md:mr-0" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
                           <span className="sr-only">Open user menu</span>
                           <NavLink onClick={hideNavbar} to={"/perfil-freelancer"}>

                              {loadImgProfile
                                 ? <PhotoProfileSkeleton />
                                 : <img className="w-16 h-16 rounded-full object-cover object-center " src={MostrarImg === null ? imgDefault : `data:image/jpeg;base64,${MostrarImg}`} onError={handleError} style={{ maxWidth: "190px" }} alt="user photo" />
                              }

                           </NavLink>
                        </button>
                     )}
                     {(verifySession.type === 4) && (
                        <button type="button" className="flex text-sm bg-gray-800 rounded-full md:mr-0" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
                           <span className="sr-only">Open user menu</span>
                           <NavLink onClick={hideNavbar} to={"/perfil-empresa"}>
                              {loadImgProfile
                                 ? <PhotoProfileSkeleton />
                                 : <img className="w-16 h-16 rounded-full object-cover object-center "
                                    src={MostrarImg || ''}
                                    onError={handleError}
                                    style={{ maxWidth: "190px" }} alt="Logo" />
                              }

                              {/* <img className="w-16 h-16 rounded-full object-cover object-center" style={{ maxWidth: "50px", maxHeight: "50px" }} src={imgProfile} alt="user photo" /> */}
                           </NavLink>
                        </button>
                     )}
                     {(verifySession.type === 5) && (
                        <button type="button" className="flex text-sm bg-gray-800 rounded-full md:mr-0" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
                           <span className="sr-only">Open user menu</span>
                           <NavLink onClick={hideNavbar} to={"/perfil-empleador"}>
                              <img className="w-16 h-16 rounded-full object-cover object-center" style={{ maxWidth: "50px", maxHeight: "50px" }} src={imgProfile} alt="user photo" />
                           </NavLink>
                        </button>
                     )}
                  </div>
               )}

               <div className='w-1/3 md:hidden flex justify-end'>
                  <button onClick={showNavbar} data-collapse-toggle="mobile-menu-2" type="button" className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 " aria-controls="mobile-menu-2" aria-expanded="false">
                     <span className="sr-only">Open main menu</span>
                     <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                     <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                  </button>
               </div>



               {(verifySession.Session == false) ? (
                  <div className={`justify-end flex items-center md:w-90% lg:w-3/5 md:flex  md:order-1 animate__animated animate__fadeIn ${toggleShowAndHide ? addClass : addClass}`} id="mobile-menu-2">
                     <ul className="items-baseline flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
                        <li>
                           <NavLink onClick={hideNavbar} className="text-white lg:text-base md:text-sm hover:text-gray-300 active block py-2 pr-4 pl-3 rounded md:bg-transparent md:p-0 dark:text-white" to={""}>Inicio</NavLink>
                        </li>

                        <li>
                           <NavLink onClick={hideNavbar} className="text-white lg:text-base md:text-sm hover:text-gray-300 active block py-2 pr-4 pl-3 rounded md:bg-transparent md:p-0 dark:text-white" to={"/freelancers"}>Talentos</NavLink>
                        </li>

                  
                           {/* <li>
                           <NavLink onClick={hideNavbar} className="text-white lg:text-base md:text-sm hover:text-gray-300 active block py-2 pr-4 pl-3 rounded md:bg-transparent md:p-0 dark:text-white" to={"/proyectos"}>Oportunidades 11</NavLink>
                           </li> */}

                            <a onClick={() => setAddClassModal(true)} className="text-white cursor-pointer lg:text-base md:text-sm hover:text-gray-300 active block py-2 pr-4 pl-3 rounded md:bg-transparent md:p-0 dark:text-white">Oportunidades</a>
                      
                        

                        <li>
                           <div className='flex'>
                              <NavLink onClick={hideNavbar} className="text-white active block py-2 pr-4 pl-3 md:mx-2 md:bg-transparent md:p-0 dark:text-white" to={"/Login"}>
                                 <button className="inline-flex items-center rounded-full bg-Yellow border-0 py-1 px-6 transition-colors focus:outline-none hover:bg-amber-600 lg:text-base md:text-sm mt-4 md:mt-0">
                                    Talentos
                                 </button>
                              </NavLink>
                              <NavLink onClick={hideNavbar} className="text-white active block py-2 pr-4 pl-3 md:mx-2 md:bg-transparent md:p-0 dark:text-white" to={"/login-empresa"}>
                                 <button className="inline-flex items-center rounded-full bg-purple-600 border-0 py-1 px-6 transition-colors focus:outline-none hover:bg-purple-600 lg:text-base md:text-sm mt-4 md:mt-0">
                                    Empresa
                                 </button>
                              </NavLink>
                           </div>
                        </li>


                        {/* <li>
                        <NavLink className="text-white hover:text-gray-300 active block py-2 pr-4 pl-3 rounded md:bg-transparent md:p-0 dark:text-white" to={"/Registro"}>
                        <button className="inline-flex items-center rounded-2xl bg-Light-Blue border-0 py-1 px-3 focus:outline-none text-white hover:bg-gray-200  text-base mt-4 md:mt-0">
                           Registro
                        </button>
                        </NavLink>
                     </li> */}
                     </ul>
                  </div>
               ) : (
                  <div className={`justify-end flex items-center md:w-4/5 lg:w-3/5 xl:w-65% md:flex  md:order-1 animate__animated animate__fadeIn ${toggleShowAndHide ? addClass : addClass}`} id="mobile-menu-2">
                     <ul className="items-baseline md:px-5 lg:px-0 flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
                        <li>
                           <NavLink onClick={hideNavbar} className="text-white lg:text-base md:text-sm hover:text-gray-300 active block py-2 pr-4 pl-3 rounded md:bg-transparent md:p-0 dark:text-white" to={""}>Inicio</NavLink>
                        </li>

                        <li>
                           <NavLink onClick={hideNavbar} className="text-white lg:text-base md:text-sm hover:text-gray-300 active block py-2 pr-4 pl-3 rounded md:bg-transparent md:p-0 dark:text-white" to={"/freelancers"}>Talentos</NavLink>
                        </li>

                        
                        <li>
                           <NavLink onClick={hideNavbar} className="text-white lg:text-base md:text-sm hover:text-gray-300 active block py-2 pr-4 pl-3 rounded md:bg-transparent md:p-0 dark:text-white" to={"/proyectos"}>Oportunidades</NavLink>
                        </li>

                        <li>
                           <div onClick={hideNavbar} className="w-full text-white active block py-2 pr-4 pl-3 md:bg-transparent md:p-0 dark:text-white">
                              <button onClick={closeSession} className="w-full inline-flex items-center rounded-lg bg-Yellow border-0 py-1 px-3 transition-colors focus:outline-none hover:bg-amber-600 lg:text-base md:text-sm mt-4 md:mt-0">
                                 Cerrar Sesión
                              </button>
                           </div>
                        </li>
                     </ul>
                  </div>
               )}
            </div>
         </nav>
         <ModalLogin addClassModal={addClassModal} setAddClassModal={setAddClassModal} IsLoadingModal={IsLoadingModal} />
      </>
   )
}

export default Navbar;