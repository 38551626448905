
import { ChangeEvent, useEffect, useState } from "react";
import { ValidateEmail } from "../../function/Login";
import { InitialStateExperienceInformal, StateExperienceNoFile } from "../../Interfaces/experience";
import { alertLogin } from "../../Interfaces/Login";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import IconLoading from "../iconLoading/IconLoading";
import Cookies from "universal-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface Props {
    idFreelance: number;
}

// Types
type handleInputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>


// Initial State
const initialStateExperienceInformal = {
    experience_titular: "",
    url_repo: "",
    freelance_experience_description: "",
    fecha_inicio: "",
    fecha_fin: "",
    user_id: 0,
    currentJob: 0,
    work_experience_type: 2,
    briefcase_file: ''
};

const stateExperienceNoFile = {
    experience_titular:'',
    url_repo:'',
    freelance_experience_description:'',
    fecha_inicio:'',
    fecha_fin:'',
    user_id: 0,
    currentJob:'',
    work_experience_type:2
};

const initialStateInput = {
    input: "",
    message: '',
    state: false
};

const ExperienceInformal = ({ idFreelance }: Props) => {

    const [Experience, setExperience] = useState<InitialStateExperienceInformal>(initialStateExperienceInformal);
    
    const [ExperienceNoFile, setExperienceNoFile] = useState<StateExperienceNoFile>(stateExperienceNoFile);

    const [alert, setAlert] = useState<alertLogin[]>([initialStateInput]);

    const [idUser, setIdUser] = useState<number>(0);

    const MySwal = withReactContent(Swal);

    const navigate = useNavigate();

    const params = useParams();

    const [sendingData, setSendingData] = useState(false);

    let fileRefProfile = null;

    // Cookies
    const cookies = new Cookies();

    const getSesion = cookies.get("Bearer"); 

    const [File, setFile] = useState({
        file: {
            lastModified: "",
            lastModifiedDate: "",
            name: "",
            size: "",
            type: "",
            webkitRelativePath: ""
        }
    });

    const [characterTextLength, setCharacterTextLength] = useState(200);

    const [lengthText, setLengthText] = useState(0);

    useEffect(() => {
        
        ListInfoWorkExperience();

    }, []);

    // List info Experience Freelancer
    const ListInfoWorkExperience = async () => {
        if (Number(params.id) != 0) {

            const idExperience = params.id;
            const urlCountry = `${process.env.REACT_APP_API_URI}freelancer/get-work-experience/${idExperience}`;

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ApiToken': `Bearer ${getSesion.token}`
                }
            };

            try{
                const resp = await axios.get(urlCountry, config);
                console.log("resp ? editar:", resp);
                if (resp.data.experiencia.actual_work == 1){

                    const checkboxCurrentJob = document.getElementById('currentJob',) as HTMLInputElement | null;

                    if (checkboxCurrentJob != null) {
                        checkboxCurrentJob.checked = true;
                    }

                };

                setExperience({
                    experience_titular: resp.data.experiencia.experience_titular,
                    url_repo: resp.data.experiencia.url_repo,
                    freelance_experience_description: resp.data.experiencia.freelance_experience_description,
                    fecha_inicio: resp.data.experiencia.fecha_inicio,
                    fecha_fin: resp.data.experiencia.fecha_fin,
                    user_id: 0,
                    currentJob: resp.data.experiencia.actual_work,
                    work_experience_type: 2,
                    briefcase_file: ''
                });

                if(resp.data.experiencia.freelance_experience_description != null){
                    setLengthText(resp.data.experiencia.freelance_experience_description.length);
                }


            } catch (err) {
                console.log(err);
            }

        }
    }

    const handleInputChange = ({ target: { name, value, id } }: handleInputChange) => {
        // console.log(name, value);

        if (name == 'freelance_experience_description'){
            setLengthText(value.length);
            if(value.length >= 1000){
                setLengthText(1000);
                setExperience({...Experience, [name]:value.substring(0,1000)});
            } else {
                setExperience({ ...Experience, [name]: value });
            }
        } else {
            if (name == "currentJob") {

                const checkboxCurrentJob = document.getElementById(id,) as HTMLInputElement | null;
    
                if (checkboxCurrentJob?.checked) {
                    setExperience({ ...Experience, [name]: 1 });
                } else {
                    setExperience({ ...Experience, [name]: 2 });
                }
    
            } else {
                setExperience({ ...Experience, [name]: value });
            }
        }
    };

    const handleWorkExperiencePortafolio = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        Experience.user_id = idFreelance;

        let verifyValues;
        
        if (Experience.currentJob == 1) {

            verifyValues = [
                {
                    name: "textRequired",
                    value: Experience.experience_titular
                },
                {
                    name: "urlRequired",
                    value: Experience.url_repo
                },
                {
                    name: "description",
                    value: Experience.freelance_experience_description
                },
                {
                    name: "dateInit",
                    value: Experience.fecha_inicio
                }
            ];
        } else {
            verifyValues = [
                {
                    name: "textRequired",
                    value: Experience.experience_titular
                },
                {
                    name: "urlRequired",
                    value: Experience.url_repo
                },
                {
                    name: "description",
                    value: Experience.freelance_experience_description
                },
                {
                    name: "dateInit",
                    value: Experience.fecha_inicio
                },
                {
                    name: "dateEnd",
                    value: Experience.fecha_fin
                }
            ];
        }

        const validateInputs = ValidateEmail(verifyValues);

        const state = validateInputs.filter(input => input.state === false).map((s) => { return false });

        setAlert(validateInputs);

        if (Experience.currentJob == 1) {
            if (state.length >= 4) sendData();
        } else {
            if (state.length >= 5) sendData();
        };

    };

    const sendData = () => {

        setSendingData(true);



        if ( Experience.briefcase_file == '' ){
            console.log("Experience ==> sin", Experience.briefcase_file);
             const dataSetNofile = {
                experience_titular: Experience.experience_titular,
                url_repo: Experience.url_repo,
                freelance_experience_description: Experience.freelance_experience_description,
                fecha_inicio: Experience.fecha_inicio,
                fecha_fin: Experience.fecha_fin,
                user_id: Experience.user_id,
                currentJob: Experience.currentJob,
                work_experience_type: 2
            };
            if (Number(params.id) == 0) {
                const url = `${process.env.REACT_APP_API_URI}freelancer/register/work-experience`;
    
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/json',
                        'ApiToken': `Bearer ${getSesion.token}`
                    }
                }
                console.log("Experience ==>", Experience);
    
                axios.post(url, dataSetNofile, config)
                    .then(response => {
                        MySwal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Portafolio guardado',
                            showConfirmButton: false,
                            timer: 3500
                        }).then(() => {
                            setSendingData(false);
                            navigate('/perfil-freelancer');
                            setExperience(initialStateExperienceInformal);
                        });
                    })
                    .catch(err => {

                        console.log("Experience ==> 1", Experience);
                        console.log(err);
                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            title: err.response.data.message,
                            showConfirmButton: false,
                            timer: 4500
                        });
                        setSendingData(false);
                    })
            } else {

                const url = `${process.env.REACT_APP_API_URI}freelancer/update/work-experience/${params.id}`;
    
                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'ApiToken': `Bearer ${getSesion.token}`
                    }
                };

                const dataSetNofile = {
                    experience_titular: Experience.experience_titular,
                    url_repo: Experience.url_repo,
                    freelance_experience_description: Experience.freelance_experience_description,
                    fecha_inicio: Experience.fecha_inicio,
                    fecha_fin: Experience.fecha_fin,
                    user_id: Experience.user_id,
                    currentJob: Experience.currentJob,
                    work_experience_type: 2
                };

                console.log("Experience ==> 2 else", Experience);

                axios.put(url, dataSetNofile, config)
                    .then(response => {
                
                        MySwal.fire({
                            position: 'center',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 3500
                        }).then(() => {
                            navigate('/perfil-freelancer');
                            setExperience(initialStateExperienceInformal);
                        });
                
                    })
                    .catch(err =>{

                        console.log("Experience ==> 2", Experience);
                        console.log(err);
                        setSendingData(false);
    
                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            title: err.response.data.message,
                            showConfirmButton: false,
                            timer: 3500
                        });
                    })
            }
        } else {
            console.log("Experience ==> con", Experience.briefcase_file);
            // const dataSetNofile = {
            //     experience_titular: Experience.experience_titular,
            //     url_repo: Experience.url_repo,
            //     freelance_experience_description: Experience.freelance_experience_description,
            //     fecha_inicio: Experience.fecha_inicio,
            //     fecha_fin: Experience.fecha_fin,
            //     user_id: Experience.user_id,
            //     currentJob: Experience.currentJob,
            //     work_experience_type: 2
            // }
        

            if (Number(params.id) == 0) {
                
                const url = `${process.env.REACT_APP_API_URI}freelancer/register/work-experience`;
    
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/json',
                        'ApiToken': `Bearer ${getSesion.token}`
                    }
                };
    
                axios.post(url, Experience, config)
                    .then(response => {
                        MySwal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Portafolio guardado',
                            showConfirmButton: false,
                            timer: 3500
                        }).then(() => {
                            setSendingData(false);
                            navigate('/perfil-freelancer');
                            setExperience(initialStateExperienceInformal);
                        });
                    })
                    .catch(err =>{
                        console.log("config ==>", config)
                        console.log("Experience ==> 3", Experience);
                        console.log(err);
                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            title: err.response.data.errors,
                            showConfirmButton: false,
                            timer: 4500
                        });

                        setSendingData(false);
                    })
            } else {

    
                const url = `${process.env.REACT_APP_API_URI}freelancer/update/work-experience/${params.id}`;
    
                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'ApiToken': `Bearer ${getSesion.token}`
                    }
                };
                
                const dataSetNofile = {
                    experience_titular: Experience.experience_titular,
                    url_repo: Experience.url_repo,
                    freelance_experience_description: Experience.freelance_experience_description,
                    fecha_inicio: Experience.fecha_inicio,
                    fecha_fin: Experience.fecha_fin,
                    user_id: Experience.user_id,
                    currentJob: Experience.currentJob,
                    work_experience_type: 2
                };

                axios.put(url, dataSetNofile, config)
                    .then(response => {
                
                        MySwal.fire({
                            position: 'center',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 3500
                        }).then(() => {
                            navigate('/perfil-freelancer');
                            setExperience(initialStateExperienceInformal);
                        });
                    })
                    .catch(err => {
                        console.log("Experience ==> 4", Experience);
                        console.log(err);
                        setSendingData(false);

                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            title: err.response.data.message,
                            showConfirmButton: false,
                            timer: 3500
                        });
                    })
            }
        }
    }

    const uploadFiles = (e: { target: { files: any; }; }) => {
        let files = e.target.files;
        let image = files[0];
        let imageUrl = URL.createObjectURL(image);
        fileRefProfile = image;

        if (fileRefProfile.type === "application/pdf") {
            setFile({ file: fileRefProfile });
            Experience.briefcase_file = fileRefProfile;
        } else {
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: "Solo se aceptan archivos .pdf",
                showConfirmButton: false,
                timer: 3500
            });
        };
    };

    return (
        <>
            <div className="w-full md:w-95% flex justify-center">
                <form className="w-full md:w-8/12" onSubmit={handleWorkExperiencePortafolio}>
                    <div className="lg:columns-1">
                        <div className="mb-6 text-start">
                            <label htmlFor="experience" className="text-white mb-8">Experiencia</label>
                            <input
                                type="text"
                                className="form-control block w-full mt-2 px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:outline-none"
                                value={Experience.experience_titular}
                                name="experience_titular"
                                id="2"
                                onChange={
                                    // Solo permitir 30 caracteres
                                    (e) => {
                                        if (e.target.value.length <= 30) {
                                            handleInputChange(e);
                                        }
                                    }
                                }
                            />
                            <div className="text-[rgba(500,100,0,0.5)] italic">
                                {Experience.experience_titular.length} / 30
                            </div>
                            {alert.filter(input => input.input == "textRequired" && input.state === true).map((message, key) => (
                                <span key={key} className="text-red-500 text-start">{message.message}</span>
                            ))}
                        </div>
                        <div className="mb-6 text-start">
                            <label htmlFor="url_repo" className="text-white mb-8">Link de referencia</label>
                            <input
                                type="text"
                                className="form-control block w-full mt-2 px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:outline-none"
                                name="url_repo"
                                value={Experience.url_repo}
                                onChange={handleInputChange}
                            />
                            {alert.filter(input => input.input == "urlRequired" && input.state === true).map((message, key) => (
                                <span key={key} className="text-red-500 text-start">{message.message}</span>
                            ))}
                        </div>
                    </div>
                    <div className="lg:columns-1">
                        <div className="mb-6 text-start">
                            <label htmlFor="address" className="text-white mb-8">Descripción de la experiencia</label>
                            <textarea className="form-control block w-full mt-2 px-4 py-2 text-md font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:outline-none"
                                rows={5}
                                name="freelance_experience_description"
                                id="freelance_experience_description"
                                value={Experience.freelance_experience_description}
                                // No permitir que la caja se expanda
                                style={{ resize: "none" }}
                                onChange={
                                    // Solo permitir 200 caracteres
                                    (e) => {
                                        if (e.target.value.length <= 200) {
                                            handleInputChange(e);
                                        }
                                    }
                                }
                                placeholder="Tú mensaje..."
                            >
                            </textarea>
                            <div className="text-[rgba(500,100,0,0.5)] italic">{lengthText} / {characterTextLength}</div>
                            {alert.filter(input => input.input == "description" && input.state === true).map((message, key) => (
                                <span key={key} className="text-red-500 text-start">{message.message}</span>
                            ))}
                        </div>
                    </div>
                    <div className="lg:columns-1">
                        <div className="mb-6 text-start btn-editpro">
                            <label htmlFor="url_repo" className="text-white mb-8">Documento</label>
                            <input
                                type="file"
                                className="btn-editpro form-control block w-full mt-2 pr-4 text-xs md:text-lg text-holder-gray font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                name="documents"
                                onChange={uploadFiles}
                            />
                            {/* {alert.filter(input => input.input == "documents" && input.state === true).map((message, key) => (
                                <span key={key} className="text-red-500 text-start">{message.message}</span>
                            ))} */}
                        </div>
                    </div>
                    <div className="lg:columns-2 flex flex-wrap items-center">
                        <div className="mb-6 text-start w-full md:w-47% md:mx-1">
                            <label htmlFor="dateInit" className="text-white mb-8">Desde (Fecha de inicio)</label>
                            <input
                                // type="date"
                                type="month"
                                className="form-control block w-full mt-2 px-4 py-2 text-lg text-holder-gray font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:outline-none"
                                name="fecha_inicio"
                                value={Experience.fecha_inicio}
                                id="1" //do not remove ID, it is important to validate
                                onChange={handleInputChange}
                            />
                            {alert.filter(input => input.input == "dateInit" && input.state === true).map((message, key) => (
                                <span key={key} className="text-red-500 text-start">{message.message}</span>
                            ))}
                        </div>
                        { (Experience.currentJob == 2 || Experience.currentJob == 0 )
                            ?
                            <div className="mb-6 text-start w-full md:w-47% md:mx-1">
                                <label htmlFor="dateBirth" className="text-white mb-8">Hasta (Fecha de finalización)</label>
                                <input
                                    // type="date"
                                    type="month"
                                    className="form-control block w-full mt-2 px-4 py-2 text-lg text-holder-gray font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:outline-none"
                                    name="fecha_fin"
                                    id="1" //do not remove ID, it is important to validate
                                    value={Experience.fecha_fin}
                                    onChange={handleInputChange}
                                />
                                {alert.filter(input => input.input == "dateEnd" && input.state === true).map((message, key) => (
                                    <span key={key} className="text-red-500 text-start">{message.message}</span>
                                ))}
                            </div>
                            :
                            <div className="mb-6 text-start w-full md:w-47% md:mx-1">
                                <p className="text-gray-500 text-sm italic">
                                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                     Si quieres colocar fecha de finalización desmarca chamba actual
                                </p>
                            </div>
                        }
                    </div>
                    <div className="columns-1 py-5 px-2 flex justify-start items-center">
                        <input
                            className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none accent-pink-500  checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white checked:after:w-1" 
                            type="checkbox"
                            id="currentJob"
                            name="currentJob"
                            onChange={handleInputChange}
                        />
                        <span className="text-white text-xs lg:text-xs xl:text-base">Chamba actual</span>
                    </div>
                    <div className="columns-1 flex justify-center">
                        <div className="text-center py-12 lg:text-left w-full md:w-1/2">
                            <button
                                type="submit"
                                className="inline-block px-7 py-3 bg-Yellow text-white font-medium text-sm leading-snug rounded-lg shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out w-full"
                            >
                                {!sendingData &&
                                    <>
                                        {Number(params.id) == 0 ? "Registrar portafolio" : "Actualizar portafolio"}
                                    </>
                                }

                                {sendingData &&
                                    <IconLoading />
                                }
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default ExperienceInformal;