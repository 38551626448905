import axios from "axios";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

function Internships({data, type_user_session}) {
    
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            // breakpoint: { max: 1024, min: 800 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            // breakpoint: { max: 800, min: 0 },
            items: 1,
        },
    };

    const typeWork = (param) => {
        const id_work_modality = param;
        const modality = ["", "Presencial", "Remoto", "Híbrido"];
        return modality[id_work_modality];
    };

    const typeInternshi = (param) => {
        const id_type_internshi = param;
        const typeInternshi = ["", "Remunerada", "No remunerada"];
        return typeInternshi[id_type_internshi];
    };

    return (
        <div className="container px-5 py-4 mx-auto">
            <div className="flex flex-col w-full mb-12">
                <h1 className="text-white font-bold text-2xl sm:text-3xl">Pasantías</h1>
            </div>
            <div className="w-full mx-auto">
                <Carousel
                    responsive={responsive}
                    showDots={true}
                    arrows={false}
                    autoPlay={true}
                    infinite={true}
                    autoPlaySpeed={2000}
                    dotListClass="custom-dot-list-style"
                    className={`flex flex-wrap w-full mx-auto`}
                >
                    {data.map((Internship) => {
                        return (
                            <>
                                <div className={`p-1 h-96 md:h-80 w-full style-items-carousel`}>
                                    <div className="min-w-96 max-w-sm p-5 bg-purple-button border border-purple-button rounded-lg shadow h-full relative w-full">
                                        <div className="mb-2 text-xl font-bold tracking-tight text-Yellow break-words">
                                            {Internship.job_title}
                                        </div>
                                        <p className="mb-3 font-normal text-white text-justify break-words">
                                            {
                                                Internship.description.length > 100
                                                    ? Internship.description.substring(0, 100) + "..."
                                                    : Internship.description
                                            }
                                        </p>
                                        <div className="pb-2 absolute bottom-14">
                                            <span className="inline-block bg-gray-200 rounded-md px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                                                Inicio: {Internship.start_date}
                                            </span>

                                            <span className="inline-block bg-gray-200 rounded-md px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                                                {typeInternshi(Internship.id_type_internship)}
                                            </span>

                                            <span className="inline-block bg-gray-200 rounded-md px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                                                {typeWork(Internship.id_work_modality)}
                                            </span>

                                            <span className="inline-block bg-gray-200 rounded-md px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                                                {Internship.schedules}
                                            </span>
                                        </div>

                                        {type_user_session === 3 && (
                                            <Link
                                                to={`/informacion-pasantia/${Internship.id}`}
                                                className="flex justify-end absolute bottom-4 right-4"
                                            >
                                                <button
                                                    className="flex items-center gap-2 px-6 py-3 text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none bg-gray-900/10 active:bg-gray-900/20 cursor-pointer"
                                                    type="button"
                                                >
                                                    Ver más
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        className="w-4 h-4"
                                                    >
                                                        <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                                        ></path>
                                                    </svg>
                                                </button>
                                            </Link>
                                        )} 
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </Carousel>
            </div>
        </div>
    );
}

export default Internships;
