export function to12Hours(hora24) {
    // Divide la hora en horas y minutos
    const [horas, minutos] = hora24.split(':').map(Number);

    // Verifica si es AM o PM
    const periodo = horas >= 12 ? 'PM' : 'AM';

    // Convierte las horas a formato de 12 horas
    const horas12 = horas % 12 || 12;

    // Crea la hora en formato 12 horas
    const hora12 = `${horas12}:${minutos.toString().padStart(2, '0')} ${periodo}`;

    return hora12;
}

export function to24Hours(hora12) {
    // Divide la hora en horas, minutos y AM/PM
    const [hora, minutos, periodo] = hora12.match(/(\d+):(\d+)\s*(AM|PM)/i).slice(1);
  
    // Convierte las horas a número
    let horas24 = parseInt(hora, 10);
  
    // Ajusta las horas según AM o PM
    if (periodo.toLowerCase() === 'pm' && horas24 !== 12) {
      horas24 += 12;
    } else if (periodo.toLowerCase() === 'am' && horas24 === 12) {
      horas24 = 0;
    }
  
    // Crea la hora en formato 24 horas
    const hora24 = `${horas24.toString().padStart(2, '0')}:${minutos}`;
  
    return hora24;
}
  
  