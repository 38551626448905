import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
// Skeleton IMG Profile
import ImgProfileSkeleton from "../Skeleton/ProfileCompany/imgProfileSkeleton";

import imagenesEditPerfil from "../../assets/imgEditPerfil/imagenesEditPerfil.js";
import imgDefault from '../../assets/imgEditPerfil/user.png';
import imagenesEditProject from "../../assets/imgEditProject/imgEditProject";

// Interfaces
import { InitialStateCountry, InitialStateDepartment, InitialStateDistrito, InitialStateMunicipalities } from "../../Interfaces/departmentsAndmuniti";
import { SocialMedia, UpdatedInfoFreelancer } from "../../Interfaces/UpdatedInfoFreelancer";
import { ValidateEmail } from "../../function/Login";
import { alertLogin } from "../../Interfaces/Login";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Cookies from 'universal-cookie';
import { useNavigate, Link } from "react-router-dom";

//input mask
import InputMask from 'react-input-mask';
// import React from "react";
import EditFreelancerSkeleton from "../Skeleton/ProfileFreelancer/editFreelancerSkeleton";

//Datepicker
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import format from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import IconLoading from '../iconLoading/IconLoading';

import mapboxgl, { FullscreenControl, GeolocateControl, Marker, NavigationControl } from 'mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"

// @ts-ignore
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { updateCurrentUser } from "firebase/auth";
import { error } from "console";

import { differenceInYears, set } from "date-fns";

registerLocale('es', es);

// type
type handleInputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>

const initialStateUpdatedInfoFreelancer = {
    names: "",
    lastnames: "",
    email: "",
    birthDate: "",
    phone: "",
    phone_alternative: "",
    sexo: "",
    dui: "",
    nit: "",
    city: "",
    district_id: "",
    department: "",
    Distrito:"",
    // method: "",
    billing: "",
    method_details: "",
    iva: "",
    iva_register: "",
    languages: "",
    direction: "",
    about_me: "",
    imagen1: "",
    imagen2: "",
    photo: "",
    id: "",
    facebook: 0,
    instagram: 0,
    twitter: 0,
    linkedin: 0,
    portfolio: '',
    otherLanguage: "",
    latitude: 13.69398964025628,
    longitude: -89.23642266136574,
    briefcase_link: '',
    schedules: "",
    parent: 0
};

const initialStateCountry = {
    code: 0,
    id: 0,
    name: ""
}

const initialStateDepartments = {
    code: 0,
    id: 0,
    name: ""
}

const initialStateInput = {
    input: "",
    message: '',
    state: false
}

const initialStateSocialMedia = {
    facebook: "",
    instagram: "",
    twitter: "",
    linkedin: ""
}

const initialStateLanguages = {
    name: "",
    id: 0 || ""
}

let checkedState: any = [];

const initialStateMunicipalities = {
    name: "",
    id: 0
}

const initialStateDistrito = {
    name: "",
    id: 0
}

const initialStatePatment = {
    created_at: "",
    id: "",
    name: "",
    status: "",
    updated_at: "",
}

interface Props {
    date: null
}

const EditProfileFreelancer = () => {

    const [updatedInfo, setUpdatedInfo] = useState<UpdatedInfoFreelancer>(initialStateUpdatedInfoFreelancer);

    const [socialMedia, setSocialMedia] = useState<SocialMedia>(initialStateSocialMedia);

    const [Countries, setCountries] = useState<InitialStateCountry[]>([initialStateCountry]);

    const [departments, setDepartments] = useState<InitialStateDepartment[]>([initialStateDepartments]);

    const [municipalities, setMunicipalities] = useState<InitialStateMunicipalities[]>([initialStateMunicipalities]);

    const [Distrito, setDistrito] = useState<InitialStateDistrito[]>([initialStateDistrito]);

    const [paymentMethod, setPaymentMethod] = useState([initialStatePatment]);

    const [disabledInput, setDisabledInput] = useState(false);

    const [DUI, setDUI] = useState("");

    const [NIT, setNIT] = useState("");

    const [duiExist, setDuiExist] = useState(false);

    const [mostrarDui, setMostrarDui] = useState(false);

    const [mostrarNit, setMostrarNit] = useState(false);

    const [mostrarDuiConNit, setMostrarDuiConNit] = useState(false);

    const [billing, setBilling] = useState([
        { id: "CCFF", name: "Consumidor final" },
        { id: "CCF", name: "Comprobante de crédito fiscal" }
    ]);

    const [selectIva, setSelectIva] = useState([
        { id: 1, name: "Si" },
        { id: 0, name: "No" }
    ]);

    const [schedules, setSchedules] = useState([
        { id: 1, name: "Si" },
        { id: 2, name: "No" }
    ]);

    const [parent, setparent] = useState([
        { id: 1, name: "Si" },
        { id: 2, name: "No" }
    ]);

    const [Languages, setLanguages] = useState([initialStateLanguages]);


    const [MostrarImg, setMostrarImg] = useState(imgDefault);

    const [imgOld, setImgOld] = useState(imgDefault);

    const [closeImg, setCloseImg] = useState(false);

    const [loadImgProfile, setloadImgProfile] = useState(true);

    const [phone, setPhone] = React.useState<string>(""); //Input Mask

    const [alert, setalert] = useState<alertLogin[]>([initialStateInput]);

    const MySwal = withReactContent(Swal);

    const [sendingData, setSendingData] = useState(false);

    const navigate = useNavigate();

    // Cookies
    const cookies = new Cookies();

    const getSesion = cookies.get('Bearer');

    const [IsLoading, setIsLoading] = useState(true);

    const [disabledInputIVA, setdisabledInputIVA] = useState(true);
    const [mensajeDeValidacion, setmensajeDeValidacion] = useState(false);

    let fileRefProfile = null;
    let fileRePortada = null;

    let backupLanguage = "";

    const [characterTextLength, setCharacterTextLength] = useState(250);
    const [lengthText, setLengthText] = useState(0);

    mapboxgl.accessToken = 'pk.eyJ1IjoiZ2Vvcmdlc3YiLCJhIjoiY2s5YTJjaGV0MDB0NzNsbXFuM3EyeThnYSJ9.F7uxDu4RAFlHz61e2cxNjw';

    const mapContainer = useRef<any>();
    const map = useRef<any>();
    const [lng, setLng] = useState(-88.9426);
    const [lat, setLat] = useState(13.8767);
    const [zoom, setZoom] = useState(9);

    const [UbicacionMap, setUbicacionMap] = useState({
        lng: 0,
        lat: 0
    });

    // Funcion para ver si es mayor a 18 años
    function esMayorDeEdad(date: string) {
        const birthDate = new Date(date);
        const today = new Date();
        const age = differenceInYears(today, birthDate);
        if (age < 18) {
            return false;
        } else {
            return true;
        }
    }


    useEffect(() => {

        let checkboxOtherLanguage = document.getElementById("other") as HTMLInputElement || null;
        async function fetchData() {
            const idUser = cookies.get('Bearer');
            const urlProfileFreelancer = `${process.env.REACT_APP_API_URI}freelancer/profile/${idUser.id}`;
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ApiToken': `Bearer ${getSesion.token}`
                }
            };
            try {
                const resp = await axios.get(urlProfileFreelancer, config);
                console.log("===> data", resp);
                updatedInfo.names = resp.data.data.user.names;
                updatedInfo.lastnames = resp.data.data.user.lastnames;
                updatedInfo.email = resp.data.data.user.email;
                
                if(resp.data.data.about_me == "" || resp.data.data.about_me == null || resp.data.data.about_me == "null" || resp.data.data.about_me == " null"){
                    updatedInfo.about_me = "No se proporcionó ninguna descripción. ¡Haznos saber más sobre ti!"; 
                }else{
                    updatedInfo.about_me = resp.data.data.about_me;
                };
                
                updatedInfo.direction = resp.data.data.direction;
                updatedInfo.dui = resp.data.data.dui;
                updatedInfo.phone = resp.data.data.phone;
                updatedInfo.phone_alternative = resp.data.data.phone_alternative;
                updatedInfo.facebook = resp.data.data.social_media_links[0];
                updatedInfo.twitter = resp.data.data.social_media_links[1];
                updatedInfo.linkedin = resp.data.data.social_media_links[2];
                updatedInfo.instagram = resp.data.data.social_media_links[3];
                updatedInfo.sexo = resp.data.data.gender;
                updatedInfo.birthDate = resp.data.data.birth_date;
                updatedInfo.briefcase_link = resp.data.data.briefcase_link;
                updatedInfo.schedules = resp.data.data.schedules;
                updatedInfo.parent = resp.data.data.parent;

                if (resp.data.data.about_me != null) {
                    setLengthText((resp.data.data.about_me).length);
                }

                (resp.data.data.languages).map((otherLanguageMap: any) => {
                    if (otherLanguageMap.id == "other") {
                        SetExistOtherLanguage(true);
                        updatedInfo.otherLanguage = otherLanguageMap.name;
                        backupLanguage = otherLanguageMap.name;
                    }
                });

                if (resp.data.data.iva == 1) {
                    updatedInfo.iva = resp.data.data.iva;
                    setDisabledInput(true);
                }
                updatedInfo.billing = resp.data.data.billing;
                updatedInfo.iva_register = resp.data.data.iva_register;

                if (resp.data.data.billing == "CCF") {
                    setdisabledInputIVA(true);
                } else if (resp.data.data.billing == "CCFF") {
                    setdisabledInputIVA(false);
                };

                updatedInfo.iva = resp.data.data.iva;

                if (resp.data.data.iva === "1") {
                    setDisabledInput(true);
                } else if (resp.data.data.iva === "0") {
                    setDisabledInput(false);
                }


                getMunicipalities(resp.data.data.state.id);
                getDistrito(resp.data.data.city.id);

                //Seteo de valores de dui y nit si son diferentes de null
                if (resp.data.data.dui !== null) {
                    setDUI(resp.data.data.dui);
                } 

                if (resp.data.data.nit !== null) {
                    setNIT(resp.data.data.nit);
                }

                if (resp.data.data.dui !== null && resp.data.data.dui !== undefined && resp.data.data.dui !== '') {
                    setDuiExist(true);
                }

                //Validacion para mostrar el dui o nit
                if (esMayorDeEdad(resp.data.data.birth_date) && resp.data.data.dui === null && resp.data.data.nit !== undefined && resp.data.data.nit !== '') {
                    setMostrarDuiConNit(true)
                } else if (esMayorDeEdad(resp.data.data.birth_date) && resp.data.data.dui !== null) {
                    setMostrarDui(true)
                } else {
                    setMostrarNit(true)
                }

                // let imgCompany = "";

                // if (resp.data.data.photo === null || resp.data.data.photo === "") {
                //     imgCompany = imgDefault;
                // } else {
                //     imgCompany = `${process.env.REACT_APP_PHOTO_URI}freelancer/profile-img/${resp.data.data.photo}`;
                // }

                // setMostrarImg(imgCompany);
                // setImgOld(imgCompany);
                getProfileInfo("dui", resp.data.data.dui);

                // setTimeout(() => {
                //     setloadImgProfile(false);
                // }, 1000);

                setTimeout(() => {
                    const departmentVALUE = document.getElementById('departmentvalue',) as HTMLInputElement | null;
                    const cityId = document.getElementById('cityId',) as HTMLInputElement | null;
                    if (departmentVALUE != undefined) {
                        departmentVALUE.value = resp.data.data.state.id;
                        updatedInfo.department = resp.data.data.state.id;
                    };
                    if (cityId != undefined) {
                        cityId.value = resp.data.data.city.id;
                        updatedInfo.city = resp.data.data.city.id;
                        updatedInfo.district_id = resp.data.data.district_id;
                    }
                    if (resp.data.data.languages) {
                        checkedState = resp.data.data.languages.map((item: any) => {
                            const checkboxElements = document.getElementById("language" + item.id) as HTMLInputElement | null;
                            const checkboxElements2 = document.getElementById(item.id) as HTMLInputElement | null;
                            if (checkboxElements != null) {
                                checkboxElements.checked = true;
                            }
                            if (checkboxElements2 != null) {
                                checkboxElements2.checked = true;
                            }
                            return String(item.id)
                        })
                    } else {
                        checkedState = [];
                    }
                }, 1500);

                let size = 150;
                setIsLoading(false);

            } catch (err) {
                console.error(err);
            }
        };

        async function getPaymentMethod() {
            const urlCountry = `${process.env.REACT_APP_API_URI}freelancer/payment-method`;
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ApiToken': `Bearer ${getSesion.token}`
                }
            };
            try {
                const resp = await axios.get(urlCountry, config);
                setPaymentMethod(resp.data);

            } catch (err) {
                console.log(err);
            }
        }
        fetchData();
        getPaymentMethod();
    }, []);

    async function getProfileInfo(typeDocument: string, document: string) {

        const url = `${process.env.REACT_APP_API_URI}registro/student-profile-image`;

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
            }
        };

        const verifyPhoto = {
            typeDocument: typeDocument,
            document: document
        };

        console.log("verifyPhoto ==>", verifyPhoto);

        await axios.post(url, verifyPhoto, config)
            .then(response => {
                console.log("response ==> ", response);

                let imgCompany: any = "";

                if (response.data.data === null || response.data.data === "") {
                    imgCompany = null;
                } else {
                    imgCompany = response.data.data;
                }

                setMostrarImg(imgCompany);
                setImgOld(imgCompany);

                setTimeout(() => {
                    setloadImgProfile(false);
                }, 1000);

            })
            .catch(err => {
                console.log(err);

            });
    };


    const handleInputChange = ({ target: { name, value, id, } }: handleInputChange) => {
        if (name == 'about_me') {
            setLengthText(value.length);
            if (value.length >= 500) {
                setLengthText(500);
                setUpdatedInfo({ ...updatedInfo, [name]: value.substring(0, 500) });
            } else {
                setUpdatedInfo({ ...updatedInfo, [name]: value });
            }
        } else {
            if (name == 'phone_alternative' || name == 'phone' || name == 'dui') setPhone(value);

            if (name == "dui") {
                setDUI(value);
            }
    
            if (name == "nit") {
                setNIT(value);
            }

            if (name === "department") getMunicipalities(value);
            // console.log("name ==>", name, value);
            if (name === "city") getDistrito(value);

            if (name === "iva") {
                if (value === "1") { setDisabledInput(true); } else if (value === "0") { setDisabledInput(false); }
            }
            if (value == "CCF") {
                setdisabledInputIVA(true);
                updatedInfo.iva = "1";
                setDisabledInput(true);
                console.log('Consumidor final');
            } else if (value == "CCFF") {
                setdisabledInputIVA(false);
                updatedInfo.iva = "0";
                setDisabledInput(false);
                console.log('Comprobante de crédito fiscal');
            }
            setUpdatedInfo({ ...updatedInfo, [name]: value });
        }
    };

    const [CheckboxOtherLanguageIsChecked, setCheckboxOtherLanguageIsChecked] = useState(true);

    const handleInputChangelanguages = ({ target: { name, value, id, } }: handleInputChange) => {

        const checkbox = document.getElementById(id,) as HTMLInputElement | null;

        if (checkbox?.id == "other") {
            if (!checkbox?.checked) {
                setCheckboxOtherLanguageIsChecked(false);
            } else {
                setCheckboxOtherLanguageIsChecked(true);
            }
        }

        if (checkbox?.id == "language10") {
            if (!checkbox?.checked) {
                setCheckboxOtherLanguageIsChecked(false);
            } else {
                setCheckboxOtherLanguageIsChecked(true);
            }
        }
        if (checkbox?.checked) {
            checkedState.push(value);
            console.log(checkedState)
        } else {
            checkedState.map((infoArray: string, index: number) => {
                if (infoArray === value) {
                    checkedState.splice(index, 1);
                }
            });
        }
    };

    //Validacion
    const handleUpdatedInfoValidate = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!disabledInput) {
            updatedInfo.iva_register = "";
        };
 
        if (updatedInfo.dui !== null && updatedInfo.dui !== undefined && updatedInfo.dui !== '') {
            console.log(updatedInfo.dui, "DUI")
            // Si tiene guion se lo quitamos
            let duiTemp = updatedInfo.dui;

            if (duiTemp.includes('-')) {
                duiTemp = duiTemp.replace('-', '');
            }
            //Ahora le ponemos el guion en la posición correcta que seria antes del ultimo digito
            duiTemp = duiTemp.slice(0, -1) + '-' + duiTemp.slice(-1);

            setDUI(duiTemp);
        }

        if (updatedInfo.nit !== null && updatedInfo.nit !== undefined && updatedInfo.nit !== '') {

            // Si tiene guion se lo quitamos
            let nitTemp = updatedInfo.nit;

            if (nitTemp.includes('-')) {
                nitTemp = nitTemp.replace('-', '');
            }

            console.log(nitTemp.slice(10, 13), "NITdvsdgdfsgfds")

            //Poner guiones en este formato 9999-999999-999-9
            nitTemp = nitTemp.slice(0, 4) + '-' + nitTemp.slice(4, 10) + '-' + nitTemp.slice(10, 13) + '-' + nitTemp.slice(-1);

            setNIT(nitTemp);
        }

        let verifyValues = [
            { name: "name", value: updatedInfo.names },
            { name: "lastnames", value: updatedInfo.lastnames },
            { name: "gmail", value: updatedInfo.email },
            { name: "dateOfBirth", value: updatedInfo.birthDate },
            { name: "tel", value: updatedInfo.phone },
            { name: "sex", value: updatedInfo.sexo },
            // { name: "dui", value: updatedInfo.dui },
            { name: "country", value: updatedInfo.city },
            { name: "department", value: updatedInfo.department },
            { name: "distrito", value: updatedInfo.district_id },
            { name: "language", value: checkedState.length },
            { name: "address", value: updatedInfo.direction },
            { name: "description", value: updatedInfo.about_me },
            { name: "otherLanguage", value: updatedInfo.otherLanguage },
            { name: "iva", value: updatedInfo.iva },
            { name: "schedules", value: updatedInfo.schedules },
            { name: "parent", value: updatedInfo.parent }
        ];

        // console.log(verifyValues, "verifyValues")

        if (mostrarDuiConNit) {
            // deleteItems.push("dui");
            verifyValues.push({ name: "nit", value: NIT });
        } else if (mostrarDui) {
            // deleteItems.push("nit");
            verifyValues.push({ name: "dui", value: DUI });
        } else if (mostrarNit) {
            // deleteItems.push("dui");
            verifyValues.push({ name: "nit", value: NIT });
        }

        console.log("verifyValues ==>", verifyValues);

        const validateInputs = ValidateEmail(verifyValues);

        console.log("validateInputs ==>", validateInputs);
        setalert(validateInputs);
        updatedInfo.id = getSesion.id;
        const state = validateInputs.filter(input => input.state === false).map((s) => { return false });
        
        // if ((updatedInfo.facebook == undefined || updatedInfo.facebook == "") && (updatedInfo.instagram == undefined || updatedInfo.instagram == "") && (updatedInfo.twitter == undefined || updatedInfo.twitter == "") && (updatedInfo.linkedin == undefined || updatedInfo.linkedin == "")) {
        if ((updatedInfo.linkedin == undefined || updatedInfo.linkedin == "")) {
            
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: "Perfil de LinkedIn requerido.",
                showConfirmButton: false,
                timer: 5500
            });

        } else {
            if (updatedInfo.imagen1 == "") {
                delete updatedInfo.imagen1
            }
            if (updatedInfo.imagen2 == "") {
                delete updatedInfo.imagen2
            }
            updatedInfo.latitude = UbicacionMap.lat;
            updatedInfo.longitude = UbicacionMap.lng;
            if (Number(updatedInfo.iva) == 1) {
                if (updatedInfo.iva_register == "" || updatedInfo.iva_register == null || updatedInfo.iva_register == undefined) {
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "Registro de IVA requerido",
                        showConfirmButton: false,
                        timer: 5500
                    });
                } else {
                    if (state.length < 16) {
                        setmensajeDeValidacion(true);
                    } else {
                        setmensajeDeValidacion(false);
                    }
                    if (state.length >= 16) sendData();
                }
            } else if (Number(updatedInfo.iva) == 0) {
                if (state.length < 16) {
                    setmensajeDeValidacion(true);
                } else {
                    setmensajeDeValidacion(false);
                }
                if (state.length >= 16) sendData();
            };
        }
    };

    function sendData() {
        if (CheckboxOtherLanguageIsChecked == false) {
            updatedInfo.otherLanguage = "";
        }
        setSendingData(true);
        const url = `${process.env.REACT_APP_API_URI}freelancer/complete-profile/${getSesion.id}`;
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        }
        
        console.log("updatedInfo ==>", updatedInfo)

        let objectNew = { ...updatedInfo };
        
        if (mostrarDuiConNit && DUI !== '') {
            objectNew.dui = DUI;
            delete objectNew.nit;
        } else {
            objectNew.nit = NIT;
            delete objectNew.dui;
        }

        if (mostrarDui && DUI !== '' && DUI !== undefined && DUI !== null) {
            objectNew.dui = DUI;
            delete objectNew.nit;
        } else if (mostrarNit && NIT !== '' && NIT !== undefined && NIT !== null) {
            objectNew.nit = NIT;
            delete objectNew.dui;
        }

        console.log("inputs ==>", objectNew);

        axios.post(url, objectNew, config)
            .then(response => {
                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                }).then(()=>{
                    sendDataLegn();
                    setUpdatedInfo(initialStateUpdatedInfoFreelancer);
                    setDepartments([initialStateDepartments]);
                    setSocialMedia(initialStateSocialMedia);
                    setSendingData(false);
                    navigate('/perfil-freelancer');
                });
            })
            .catch(err => {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 5500
                });
                setSendingData(false);
            })
    };

    //Agregar y Actualizar idioma
    const sendDataLegn = () => {
        const url2 = `${process.env.REACT_APP_API_URI}freelancer/update-languages/${getSesion.id}`;
        let config2 = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        }
        axios.post(url2, { languagesAry: checkedState }, config2)
            .then(response => {
                checkedState = "";
            })
            .catch(err => {
                console.log(err.response);
            })
    }

    // const uploadProfile = (e: { target: { files: any; }; }) => {
    //     let files = e.target.files;
    //     let image = files[0];
    //     fileRefProfile = image;
    //     let imageUrl = URL.createObjectURL(image);
    //     updatedInfo.imagen1 = fileRefProfile;
    //     setloadImgProfile(true);
    //     setMostrarImg(URL.createObjectURL(image));
    //     setTimeout(() => {
    //         setCloseImg(true);
    //         setloadImgProfile(false);
    //     }, 1000);
    // }

    const reverseImg = () => {
        setloadImgProfile(true);
        setMostrarImg(imgOld);
        setCloseImg(false);
        setTimeout(() => {
            setloadImgProfile(false);
        }, 1000);
    }

    // const resetInput = (e: React.MouseEvent<HTMLElement>) => {
    //     updatedInfo.photo = '';
    // }

    const uploadProfilePortada = (e: { target: { files: any; }; }) => {
        let files = e.target.files;
        let image = files[0];
        fileRePortada = image;
        let imageUrl = URL.createObjectURL(image);
        updatedInfo.imagen2 = fileRePortada;
    }

    async function getMunicipalities(id: string) {
        try {
            const urlCity = `${process.env.REACT_APP_API_URI}city/${id}`;
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ApiToken': `Bearer ${getSesion.token}`
                }
            };
            const resp = await axios.get(urlCity, config);
            setMunicipalities(resp.data);
        } catch (err) {
            console.error(err);
        }
    };

    async function getDistrito(id: string) {
        try {
            const urlCity = `${process.env.REACT_APP_API_URI}district/${id}`;
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ApiToken': `Bearer ${getSesion.token}`
                }
            };
            const resp = await axios.get(urlCity, config);
            console.log("Todos los distritos ==>", resp)

            setDistrito(resp.data);
        } catch (err) {
            console.error(err);
        }
    };



    const [ExistOtherLanguage, SetExistOtherLanguage] = useState(false);

    useEffect(() => {
        async function getState() {
            const urlCountry = `${process.env.REACT_APP_API_URI}state/3`;
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ApiToken': `Bearer ${getSesion.token}`
                }
            };
            try {
                const resp = await axios.get(urlCountry, config);
                setDepartments(resp.data);
            } catch (err) {
                console.error(err);
            }
        };
        getState();

        async function getLanguages() {
            const urlCountry = `${process.env.REACT_APP_API_URI}freelancer/get/languages`;
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ApiToken': `Bearer ${getSesion.token}`
                }
            };
            try {

                const resp = await axios.get(urlCountry, config);
                setLanguages(resp.data.log);

            } catch (err) {
                console.error(err);
            }
        };
        getLanguages();
    }, []);


    useEffect(() => {
        setTimeout(() => {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v12',
                center: [lng, lat],
                zoom: zoom,
                minZoom: 2,
                maxZoom: 24
            });
            const geocoder = new MapboxGeocoder({
                accessToken: "pk.eyJ1IjoiZ2Vvcmdlc3YiLCJhIjoiY2s5YTJjaGV0MDB0NzNsbXFuM3EyeThnYSJ9.F7uxDu4RAFlHz61e2cxNjw",
                countries: 'sv',
                bbox: [-90.0955545723, 13.1490168319, -87.7235029772, 14.4241327987],
                filter: (item: any) => {
                    return item.context.map((i: { id: string; text: string; }) => {
                        return (i.id.split('.').shift() === 'country' && i.text === 'El Salvador');
                    }).reduce((acc: any, cur: any) => {
                        return acc || cur;
                    });
                },
                placeholder: "Buscar",
            });

            const locateU = new GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true
            });

            let marker: { remove: () => void; };

            map.current.on('click', (e: { lngLat: { lng: number; lat: number; }; }) => {
                setUbicacionMap({
                    lng: lng,
                    lat: lat
                });
                if (typeof marker == 'object') {
                    marker.remove();
                }
                marker = new Marker().setLngLat([e.lngLat.lng, e.lngLat.lat]).addTo(map.current);
                map.current.flyTo({
                    center: e.lngLat,
                    zoom: 15
                });
            });

            geocoder.on('result', (ev: any) => {
                setUbicacionMap({
                    lng: ev.result.geometry.coordinates[0],
                    lat: ev.result.geometry.coordinates[1]
                });
            });
            locateU.on('geolocate', (e: any) => {
                let lon = e.coords.longitude;
                let lat = e.coords.latitude;
                let position = [lon, lat];

                setUbicacionMap({
                    lng: lon,
                    lat: lat
                });

                map.current.flyTo({
                    center: position,
                    zoom: 9
                });
            });
            map.current.addControl(geocoder);
            map.current.addControl(new NavigationControl());
            map.current.addControl(new FullscreenControl());
            map.current.addControl(locateU);
        }, 3500);
    }, []);


    const [habilitarOtherLanguage, setHabilitarOtherLanguages] = useState(false);

    return (
        <>
            {IsLoading &&  <EditFreelancerSkeleton/>}
            {!IsLoading &&
                <>
                    <div className="flex flex-wrap">
                        {/* <div className="hidden lg:block w-1/6 py-14 bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">
                            <img className="py-10" src={imagenesEditPerfil.logo_blanco_mitad} alt="" />
                        </div> */}

                        <form className="w-full lg:w-6/6 pt-4 pb-2 px-6" onSubmit={handleUpdatedInfoValidate}>
                            <div className="flex flex-wrap">
                                <div className="w-full md:w-35% pt-4 pb-2 btn-file ">
                                    <div className="w-full sm:w-15%">
                                        <Link to="" onClick={() => window.history.go(-1)}>
                                            <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                                        </Link>
                                    </div>
                                    <div className="mb-2 flex justify-center relative">

                                        <label htmlFor="photo" className="cursor-pointer">
                                            {closeImg &&
                                                <button className='flex items-center justify-center text-red-600 text-xs px-1.5 py-1 bg-white absolute left-24 xl:left-56 z-50 rounded-full font-bold' onClick={reverseImg}>
                                                    <FontAwesomeIcon icon={faX} />
                                                </button>
                                            }
                                            {loadImgProfile
                                                ? <ImgProfileSkeleton />
                                                : <img className="w-48 relative h-48 border-8 rounded-full object-cover align-middle border-Yellow flex justify-center" src={MostrarImg === null ? imgDefault : `data:image/jpeg;base64,${MostrarImg}`} style={{ maxWidth: "190px" }} />
                                            }
                                            {/* <input type="file" id='photo' className="hidden bg-sky-700 hover:bg-sky-600 text-xs text-white rounded-full py-2 px-2 m-1" onClick={resetInput} value={''} onChange={uploadProfile} /> */}
                                        </label>
                                    </div>
                                    <div className="">
                                        <div className="flex justify-center">
                                            <label htmlFor="nombreCompleto" className="text-white text-center">Foto de portada</label>
                                        </div>
                                        <div className="flex justify-center">
                                            <input type="file" className="bg-sky-700 hover:bg-sky-600 text-xs text-white rounded-full py-2 px-2 m-1" onChange={uploadProfilePortada} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-65%">
                                    <h2 className="text-Yellow text-2xl md:text-3xl text-center font-semibold py-2 xl:py-3 mb-2">Editar Perfil</h2>
                                    <div className="flex flex-wrap">
                                        <div className="text-start w-full md:w-1/2 p-2">
                                            <label htmlFor="nombreCompleto" className="text-white">Nombre</label>
                                            <input
                                                type="text"
                                                className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                name="names"
                                                value={updatedInfo.names}
                                                onChange={handleInputChange}
                                            />
                                            {alert.filter(input => input.input == "name" && input.state === true).map((message, key) => (
                                                <span key={key} className="text-red-500">{message.message}</span>
                                            ))}
                                        </div>
                                        <div className="text-start w-full md:w-1/2 p-2">
                                            <label htmlFor="nombreCompleto" className="text-white">Apellido</label>
                                            <input
                                                type="text"
                                                className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                                name="lastnames"
                                                value={updatedInfo.lastnames}
                                                onChange={handleInputChange}
                                            />
                                            {alert.filter(input => input.input == "lastnames" && input.state === true).map((message, key) => (
                                                <span key={key} className="text-red-500">{message.message}</span>
                                            ))}
                                        </div>
                                        <div className="text-start w-full md:w-1/2 p-2">
                                            <label htmlFor="email" className="text-white">Correo electrónico</label>
                                            <input
                                                type="email"
                                                className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                name="email"
                                                value={updatedInfo.email}
                                                onChange={handleInputChange}
                                            />
                                            {alert.filter(input => input.input == "gmail" && input.state === true).map((message, key) => (
                                                <span key={key} className="text-red-500">{message.message}</span>
                                            ))}
                                        </div>
                                        <div className="text-start w-full md:w-1/2 p-2">
                                            <label htmlFor="dateBirth" className="text-white">Fecha de nacimiento</label>
                                            {<input
                                                type="date"
                                                className="form-control block w-full px-4 py-2 text-xl text-holder-gray font-normal text- bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                name="birthDate"
                                                value={updatedInfo.birthDate}
                                                onChange={handleInputChange}
                                            />}
                                            {alert.filter(input => input.input == "dateOfBirth" && input.state === true).map((message, key) => (
                                                <span key={key} className="text-red-500">{message.message}</span>
                                            ))}
                                        </div>
                                        <div className="text-start w-full md:w-1/2 p-2">
                                            <label htmlFor="nickname" className="text-white">Teléfono principal</label>
                                            <InputMask
                                                className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                mask={phone !== "" ? "9999-9999" : ""}
                                                placeholder="0000-0000"
                                                name="phone"
                                                value={updatedInfo.phone}
                                                onChange={handleInputChange}
                                            />
                                            {alert.filter(input => input.input == "tel" && input.state === true).map((message, key) => (
                                                <span key={key} className="text-red-500">{message.message}</span>
                                            ))}
                                        </div>
                                        <div className="text-start w-full md:w-1/2 p-2">
                                            <label htmlFor="nickname" className="text-white">Teléfono secundario</label>
                                            <InputMask
                                                className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                mask={phone !== "" ? "9999-9999" : ""}
                                                placeholder="0000-0000"
                                                name="phone_alternative"
                                                value={updatedInfo.phone_alternative}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full">
                                    <div className="flex flex-wrap items-end">
                                        {/* <div className="text-start w-full md:w-1/3 p-2">
                                            <label htmlFor="pais" className="text-white">Selecciona tu pais</label>
                                            <select
                                                name="pais"
                                                className="form-control block w-full px-4 py-2.5 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                                id="pais"
                                                defaultValue={'El Salvador'}
                                            >
                                                <option value="El Salvador">El Salvador</option>
                                            </select>
                                        </div> */}

                                        <div className="text-start w-full md:w-1/3 p-2">
                                            <label htmlFor="department" className="text-white">Selecciona tu estado/departamento<br />de residencia</label>
                                            <select
                                                name="department"
                                                className="form-control block w-full px-4 py-2.5 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                                onChange={handleInputChange}
                                                id="departmentvalue"
                                                value={updatedInfo.department}
                                            >
                                                <option value="" selected>Seleccione un departamento</option>
                                                {
                                                    departments.map((department) => {
                                                        return <option id={'department' + department.id} value={department.id}>{department.name}</option>
                                                    })
                                                }
                                            </select>
                                            {alert.filter(input => input.input == "department" && input.state === true).map((message, key) => (
                                                <span key={key} className="text-red-500">{message.message}</span>
                                            ))}
                                        </div>

                                        <div className="text-start w-full md:w-1/3 p-2">
                                            <label htmlFor="sex" className="text-white">Selecciona tu ciudad/municipio<br />de residencia</label>
                                            <select
                                                name="city"
                                                className="form-control block w-full px-4 py-2.5 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                                id="cityId"
                                                value={updatedInfo.city}
                                                onChange={handleInputChange}
                                            >
                                                <option value="" selected> Selecciona tu Municipio </option>
                                                {
                                                    municipalities.map((city) => {
                                                        return (
                                                            <option value={city.id}>{city.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {alert.filter(input => input.input == "country" && input.state === true).map((message, key) => (
                                                <span key={key} className="text-red-500">{message.message}</span>
                                            ))}
                                        </div>

                                        <div className="text-start w-full md:w-1/3 p-2">
                                            <label htmlFor="sex" className="text-white">Selecciona tu Distrito <br />de residencia</label>
                                            <select
                                                name="district_id"
                                                className="form-control block w-full px-4 py-2.5 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                                id="district_id"
                                                value={updatedInfo.district_id}
                                                onChange={handleInputChange}
                                            >
                                                <option value="" selected> Selecciona tu Municipio </option>
                                                {
                                                    Distrito.map((distrito) => {
                                                        return (
                                                            <option value={distrito.id}>{distrito.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {alert.filter(input => input.input == "distrito" && input.state === true).map((message, key) => (
                                                <span key={key} className="text-red-500">{message.message}</span>
                                            ))}
                                        </div>


                                        <div className="w-full flex flex-wrap">
                                            <div className="text-start w-full md:w-2/2 p-2">
                                                <label htmlFor="direction" className="text-white">Dirección</label>
                                                <input
                                                    type="text"
                                                    className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                    name="direction"
                                                    value={updatedInfo.direction}
                                                    onChange={handleInputChange}
                                                />
                                                {alert.filter(input => input.input == "address" && input.state === true).map((message, key) => (
                                                    <span key={key} className="text-red-500">{message.message}</span>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="w-full flex flex-wrap">
                                            <div className="text-start w-full md:w-1/2 p-2">
                                                <div className="py-6" ref={map}>
                                                    <div ref={mapContainer} className="map-container" />
                                                </div>
                                            </div>
                                            <div className="w-full md:w-1/2">
                                                <div className="text-start w-full p-2">
                                                    <label htmlFor="direction" className="text-white">Acerca de ti</label>
                                                    <textarea
                                                        className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                        rows={12}
                                                        name="about_me"
                                                        value={updatedInfo.about_me}
                                                        onChange={handleInputChange}
                                                    >
                                                    </textarea>
                                                    <div className="text-[rgba(500,100,0,0.5)] italic">{lengthText} / {characterTextLength}</div>
                                                    {alert.filter(input => input.input == "description" && input.state === true).map((message, key) => (
                                                        <span key={key} className="text-red-500">{message.message}</span>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="text-start w-full md:w-1/4 p-2">
                                                <label htmlFor="sex" className="text-white">Selecciona tu sexo</label>
                                                <select
                                                    name="sexo"
                                                    className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                                    value={updatedInfo.sexo}
                                                    onChange={handleInputChange}
                                                    defaultValue={'defaultsex'}
                                                >
                                                    <option className="text-white" value="" hidden selected>...</option>
                                                    <option className="text-white" value="2">Mujer</option>
                                                    <option className="text-white" value="1">Hombre</option>
                                                    <option className="text-white" value="3">Prefiero no decirlo</option>
                                                </select>
                                                {alert.filter(input => input.input == "sex" && input.state === true).map((message, key) => (
                                                    <span key={key} className="text-red-500">{message.message}</span>
                                                ))}
                                            </div>
                                            {
                                                mostrarDui 
                                                ?
                                                <div className="text-start w-full md:w-1/4 p-2">
                                                    <label htmlFor="dui" className="text-white">
                                                        Ingresa tu DUI
                                                    </label>
                                                    <InputMask
                                                        className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                        mask={"99999999-9"}
                                                        placeholder="00000000-0"
                                                        name="dui"
                                                        value={DUI}
                                                        onChange={handleInputChange}
                                                    />
                                                    {alert.filter(input => input.input == "dui" && input.state === true).map((message, key) => (
                                                        <span key={key} className="text-red-500">{message.message}</span>
                                                    ))}
                                                </div>
                                                :
                                                mostrarNit
                                                &&
                                                <div className="text-start w-full md:w-1/4 p-2">
                                                    <label htmlFor="nit" className="text-white">Ingresa tu NIT</label>
                                                    <InputMask
                                                        className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                        mask={"9999-999999-999-9"}
                                                        placeholder="999-999999-999-9"
                                                        name="nit"
                                                        value={NIT}
                                                        onChange={handleInputChange}
                                                    />
                                                    {alert.filter(input => input.input == "nit" && input.state === true).map((message, key) => (
                                                        <span key={key} className="text-red-500">{message.message}</span>
                                                    ))}
                                                </div>
                                            }
                                            {
                                                mostrarDuiConNit
                                                &&
                                                <div className="text-start w-full md:w-1/4 p-2">
                                                    <label htmlFor="dui" className="text-white">
                                                        Ingresa tu DUI
                                                    </label>
                                                    <span className="text-xs ml-1 text-white italic">(NIT: {NIT})</span>
                                                    <InputMask
                                                        className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                        mask={"99999999-9"}
                                                        placeholder="00000000-0"
                                                        name="dui"
                                                        value={DUI}
                                                        onChange={handleInputChange}
                                                    />
                                                    {alert.filter(input => input.input == "dui" && input.state === true).map((message, key) => (
                                                        <span key={key} className="text-red-500">{message.message}</span>
                                                    ))}
                                                </div>
                                            }
                                            <div className="text-start w-full md:w-1/4 p-2">
                                                {(disabledInputIVA == false) &&
                                                    <>
                                                        <label htmlFor="sex" className="text-white">IVA</label>
                                                        <select
                                                            disabled
                                                            name="iva"
                                                            className="form-control block w-full px-4 py-2.5 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                                            id="ivaId"
                                                            value={updatedInfo.iva}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value="001" selected> Seleccione una opción </option>
                                                            {
                                                                selectIva.map((iva) => {
                                                                    return (
                                                                        <option value={iva.id}>{iva.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        {alert.filter(input => input.input == "iva" && input.state === true).map((message, key) => (
                                                            <span key={key} className="text-red-500">{message.message}</span>
                                                        ))}
                                                    </>
                                                }
                                                {(disabledInputIVA == true) &&
                                                    <>
                                                        <label htmlFor="sex" className="text-white">IVA</label>
                                                        <select
                                                            name="iva"
                                                            className="form-control block w-full px-4 py-2.5 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                                            id="ivaId"
                                                            value={updatedInfo.iva}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value="001" selected> Seleccione una opción </option>
                                                            {
                                                                selectIva.map((iva) => {
                                                                    return (
                                                                        <option value={iva.id}>{iva.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        {alert.filter(input => input.input == "iva" && input.state === true).map((message, key) => (
                                                            <span key={key} className="text-red-500">{message.message}</span>
                                                        ))}
                                                    </>
                                                }
                                            </div>
                                            <div className="text-start w-full md:w-1/4 p-2">
                                                <label htmlFor="nombreCompleto" className="text-white">Registro de IVA</label>
                                                {(disabledInput) &&
                                                    <>
                                                        <input
                                                            type="text"
                                                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                            name="iva_register"
                                                            value={updatedInfo.iva_register}
                                                            onChange={handleInputChange}
                                                        />
                                                        {alert.filter(input => input.input == "iva_register" && input.state === true).map((message, key) => (
                                                            <span key={key} className="text-red-500">{message.message}</span>
                                                        ))}
                                                    </>
                                                }
                                                {!(disabledInput) &&
                                                    <>
                                                        <input
                                                            disabled
                                                            type="text"
                                                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-800 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                            name="iva_register"
                                                        />
                                                        {alert.filter(input => input.input == "iva" && input.state === true).map((message, key) => (
                                                            <span key={key} className="text-red-500">{message.message}</span>
                                                        ))}
                                                    </>
                                                }
                                            </div>

                                            <div className="text-start w-full md:w-1/4 p-2">
                                                <label htmlFor="sex" className="text-white">Horario rotatorio</label>
                                                <select
                                                    name="schedules"
                                                    className="form-control block w-full px-4 py-2.5 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                                    id="schedulesId"
                                                    value={updatedInfo.schedules}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="001" selected> Seleccione una opción </option>
                                                    {
                                                        schedules.map((horarios) => {
                                                            return (
                                                                <option value={horarios.id}>{horarios.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                {alert.filter(input => input.input == "schedules" && input.state === true).map((message, key) => (
                                                    <span key={key} className="text-red-500">{message.message}</span>
                                                ))}
                                            </div>

                                            <div className="text-start w-full md:w-1/4 p-2">
                                                <label htmlFor="sex" className="text-white">¿Es padre o madre de familia?</label>
                                                <select
                                                    name="parent"
                                                    className="form-control block w-full px-4 py-2.5 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                                    id="fatherOrMotherId"
                                                    value={updatedInfo.parent}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="001" selected> Seleccione una opción </option>
                                                    {
                                                        parent.map((parent) => {
                                                            return (
                                                                <option value={parent.id}>{parent.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                {alert.filter(input => input.input == "parent" && input.state === true).map((message, key) => (
                                                    <span key={key} className="text-red-500">{message.message}</span>
                                                ))}
                                            </div>


                                            <div className="text-start w-full p-1 my-3">
                                                <label htmlFor="" className="text-white">Idiomas que domino</label>
                                                <div className="flex flex-wrap pl-4 pt-2 justify-start">
                                                    {
                                                        Languages.map((item) => {
                                                            return (
                                                                <div className="flex items-center pr-5 w-full md:w-1/4">
                                                                    <input className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white" type="checkbox" id={'language' + item.id} name="languages" onChange={handleInputChangelanguages} value={item.id} />
                                                                    <span className="text-white pl-2">{item.name}</span>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    {/* {
                                                        (ExistOtherLanguage == false)
                                                            ?
                                                            <div className="flex items-center pr-5 w-full md:w-1/4">
                                                                <input className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2 checked:after:rotate-45 checked:after:absolute checked:after:border-white" type="checkbox"
                                                                    onChange={handleInputChangelanguages}
                                                                    id='language10'
                                                                    name="languages"
                                                                    value="10"
                                                                    onClick={function () {
                                                                        (habilitarOtherLanguage === true)
                                                                            ?
                                                                            setHabilitarOtherLanguages(false)
                                                                            :
                                                                            setHabilitarOtherLanguages(true)
                                                                    }} />
                                                                <span className="text-white pl-2">
                                                                    {
                                                                        (updatedInfo.otherLanguage == "")
                                                                            ?
                                                                            "Otro idioma"
                                                                            :
                                                                            updatedInfo.otherLanguage
                                                                    }
                                                                </span>
                                                            </div>
                                                            :
                                                            <div className="flex items-center pr-5 w-full sm:w-1/2 xl:w-1/4">
                                                                <input className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white" type="checkbox" id={'other'} name="languages" onChange={handleInputChangelanguages} value={'other'} />
                                                                <span className="text-white pl-2">{updatedInfo.otherLanguage}</span>
                                                            </div>
                                                    } */}

                                                </div>
                                                <div className="flex flex-wrap">
                                                    {(habilitarOtherLanguage == true) &&
                                                        <div className="text-start w-full md:w-1/2 p-2">
                                                            <label htmlFor="otherLanguage" className="text-white">Otro idioma</label>
                                                            <input
                                                                type="text"
                                                                className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                                name="otherLanguage"
                                                                value={updatedInfo.otherLanguage}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    }
                                                    {(ExistOtherLanguage == true && CheckboxOtherLanguageIsChecked == true) &&
                                                        <div className="text-start w-full md:w-1/2 p-2">
                                                            <label htmlFor="otherLanguage" className="text-white">Editar idioma agregado</label>
                                                            <input
                                                                type="text"
                                                                className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                                name="otherLanguage"
                                                                value={updatedInfo.otherLanguage}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    }

                                                </div>
                                                {alert.filter(input => input.input == "language" && input.state === true).map((message, key) => (
                                                    <span key={key} className="text-red-500">{message.message}</span>
                                                ))}
                                            </div>
                                            <div className="text-start flex justify-center items-center w-full py-4">
                                                <hr className="w-4 xl:w-36 lg:w-24" />
                                                <label htmlFor="" className="text-white px-2">Agregar al menos una red social</label>
                                                <hr className="w-4 xl:w-36 lg:w-24" />
                                            </div>
                                            <div className="text-start w-full md:w-1/2 p-2">
                                                <label htmlFor="facebook" className="text-white">Usuario de Facebook</label>
                                                <input
                                                    type="text"
                                                    className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                    name="facebook"
                                                    value={updatedInfo.facebook}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="text-start w-full md:w-1/2 p-2">
                                                <label htmlFor="instagram" className="text-white">Usuario de Instagram</label>
                                                <input
                                                    type="text"
                                                    className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                    name="instagram"
                                                    value={updatedInfo.instagram}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="text-start w-full md:w-1/2 p-2">
                                                <label htmlFor="facebook" className="text-white">Usuario de Twitter</label>
                                                <input
                                                    type="text"
                                                    className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                    name="twitter"
                                                    value={updatedInfo.twitter}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="text-start w-full md:w-1/2 p-2">
                                                <label htmlFor="instagram" className="text-white">Usuario de Linkedin</label>
                                                <input
                                                    type="text"
                                                    className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                    name="linkedin"
                                                    value={updatedInfo.linkedin}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="text-start w-full md:w-2/2 p-2">
                                                <label htmlFor="direction" className="text-white">Link de portafolio</label>
                                                <input
                                                    type="url"
                                                    className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                                    name="briefcase_link"
                                                    value={updatedInfo.briefcase_link}
                                                    onChange={handleInputChange}
                                                />
                                                {alert.filter(input => input.input == "briefcase_link" && input.state === true).map((message, key) => (
                                                    <span key={key} className="text-red-500">{message.message}</span>
                                                ))}
                                            </div>
                                            <div className="mx-12 w-full p-1 my-3 flex-col items-center text-center">
                                                <div className="text-center p-2">
                                                    {mensajeDeValidacion ? (
                                                        <div style={{ color: 'red' }}>Verifica que ningun campo este vacio!</div>
                                                    ) : (<></>)}
                                                </div>
                                                <button
                                                    type="submit"
                                                    className=" w-full md:w-1/3 lg:w-1/4 inline-block px-7 py-3 bg-Yellow text-white font-medium text-sm leading-snug uppercase rounded-lg shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out"
                                                >
                                                    {!sendingData && <> Guardar cambios </>}
                                                    {sendingData && <IconLoading />}
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* <div className="w-full md:w-1/2 lg:w-7/12 flex flex-wrap justify-center pb-5 lg:py-5">
                        <form className="w-4/5 lg:w-3/4 flex flex-wrap" onSubmit={handleUpdatedInfoValidate}>

                        </form>
                    </div> */}
                </>
            }
        </>
    )
}
export default EditProfileFreelancer;
