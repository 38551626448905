import imgPerfilFreelancer from "../assets/imgPerfilFreelancer/imgPerfilFreelancer";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import profile from "../assets/imgEditPerfil/user.png";
import axios from "axios";
import Cookies from "universal-cookie";
import { EmployerInterface } from "../Interfaces/listInfoEmployer";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link, useNavigate } from 'react-router-dom';
import { faGear } from "@fortawesome/free-solid-svg-icons";

const initialStateEmployer = {
  about_me: '',
  activity_id: 0,
  complete_profile_status: 0,
  country_id: 0,
  cover_photo: '',
  create_at: '',
  email: '',
  email_verified_at: '',
  id: 0,
  laguages: '',
  lastnames: '',
  names: '',
  password: '',
  phone: '',
  phone_alternative: '',
  photo: '',
  provider: '',
  remember_token: '',
  social_media_links: '',
  status: 0,
  type_id: 0,
  updated_at: '',
  user_id: 0,
  username: ''
}


const Employer = () => {

  const cookies = new Cookies();

  const getSesion = cookies.get('Bearer');

  const [dataEmployer, setDataEmployer] = useState<EmployerInterface>(initialStateEmployer);

  const [activity, setActivity] = useState(0);

  const [country, setCountry] = useState(0);

  const navigate = useNavigate()

  useEffect(() => {
    getEmployer();
  }, []);

  const getEmployer = async () => {
    try {
      const urlEmployer = `${process.env.REACT_APP_API_URI}employer/profile`;
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'ApiToken': `Bearer ${getSesion.token}`
        }
      };
      await axios(urlEmployer, config)
        .then(response => {
          getEconomicActivity(response.data.activity_id);
          getCountry(response.data.country_id);
          setDataEmployer(response.data);
        });
    } catch (error) {
      console.warn(error);
    }
  }

  const getCountry = async (idCountry: number) => {
    try {
      const urlEmployer = `${process.env.REACT_APP_API_URI}country/${idCountry}`;
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      };
      await axios(urlEmployer, config)
        .then(response =>{
          setCountry(response.data.name);
        })

    } catch (error) {
      console.warn(error);
    }
  }

  async function getEconomicActivity(idActivity: number) {
    const urlActivitySingle = `${process.env.REACT_APP_API_URI}economic-activity/${idActivity}`;
    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'ApiToken': `Bearer ${getSesion.token}`
      }
    };
    try {
      const resp = await axios.get(urlActivitySingle, config);
      setActivity(resp.data.name);
    } catch (err) {
      console.error(err);
    }
  };

  const actionStatus = ( event: React.MouseEvent<HTMLButtonElement> ) => {

    const button: HTMLButtonElement = event.currentTarget;

    if (dataEmployer.complete_profile_status == 2){
    
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Debes Completar tu perfil',
        showConfirmButton: false,
        timer: 3500
      });
    
    }else{
      switch ( button.name ) {
        case 'my-proyects':
          navigate("/");
          break;
        case 'create-proyects':
          navigate("/");
          break;
        case 'plans':
          navigate("/");
          break;
        case 'config-plan':
          navigate("/");
          break;
        default:
          navigate("/");
          break;
      }
    }
  }

  return (
    <div className="flex flex-wrap">
      <div className="w-10% hidden md:flex">
        <img src={imgPerfilFreelancer.fondo_login_izquierdo_sin_icono2} className="w-full" alt="" />
      </div>
      <div className="w-full md:w-90% bg-turquoise p-5">
        <div className="h-96 w-90 border border-Light-Blue p-5 rounded-lg bg-employeer w-full min-w-full relative mb-16">
          <div className="flex flex-wrap justify-center sm:justify-start">
            <div className="container mx-auto h-16 ">
              <div className="w-full h-16  flex justify-end ">
                <a target="_blank" className="text-white hover:text-Yellow transition-colors p-2 drop-shadow-[0_3px_3px_rgba(0,0,0,0.75)] hover:drop-shadow-[0_3px_3px_rgba(0,0,0,0.4)]" href="https://www.facebook.com/Chambaticon-103086529154814/">
                  <FontAwesomeIcon size="2x" icon={faFacebookSquare} />
                </a>
                <a target="_blank" className="text-white hover:text-Yellow transition-colors p-2 drop-shadow-[0_3px_3px_rgba(0,0,0,0.75)] hover:drop-shadow-[0_3px_3px_rgba(0,0,0,0.4)]" href="https://instagram.com/chambaticon?igshid=YmMyMTA2M2Y=">
                  <FontAwesomeIcon size="2x" icon={faInstagram} />
                </a>
                <a target="_blank" className="text-white hover:text-Yellow transition-colors p-2 drop-shadow-[0_3px_3px_rgba(0,0,0,0.75)] hover:drop-shadow-[0_3px_3px_rgba(0,0,0,0.4)]" href="https://www.linkedin.com/company/chambaticon/">
                  <FontAwesomeIcon size="2x" icon={faLinkedin} />
                </a>
              </div>
            </div>
            <div className="container bg-Light-purple w-90% h-30 rounded-xl mt-4 absolute -bottom-18 md:-bottom-12 left-4 md:left-8 lg:left-11">
              <div className="flex flex-wrap px-4 md:px-1 py-5">
                <div className="w-full md:w-1/2 lg:w-2/5 flex flex-wrap items-center">
                  <div className="w-full md:w-2/5 flex justify-center relative">
                    <img className="w-20 h-20" src={profile} alt="imagen-empleador" />
                    <Link to={'/editar-perfil-empleador'} className="absolute left-6 text-xl text-white hover:text-Light-Blue drop-shadow-[0_3px_3px_rgba(0,0,0,0.75)] hover:rotate-180 transition ease-in-out delay-75"><FontAwesomeIcon icon={faGear} /></Link>
                  </div>
                  <div className="w-full md:w-3/5 lg:w-3/5 flex flex-wrap justify-center md:justify-start py-2">
                    <h1 className="w-full text-white text-center md:text-start">{dataEmployer.names} {dataEmployer.lastnames}</h1>
                    <h2 className="w-full text-orange-500 text-center md:text-start">Estado del perfil</h2>
                    <h3 className="w-full text-white text-center md:text-start">En validacion</h3>
                  </div>
                </div>
                {
                  <div className="w-full md:w-1/2 lg:w-3/5 px-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                    <div className="flex items-center">
                      <button
                        className="bg-orange-500 hover:bg-orange-400 text-white text-sm font-bold px-4 h-10 rounded-lg w-full"
                        name="my-proyects"
                        onClick={actionStatus}
                      >
                        Mis Proyectos
                      </button>
                    </div>
                    <div className="flex items-center">
                      <button 
                        className="bg-blue-500 hover:bg-blue-400 text-white text-sm font-bold px-4 h-10 rounded-lg w-full"
                        name="create-proyects"
                        onClick={actionStatus}
                      >
                        Crear proyecto
                      </button>
                    </div>
                    <div className="flex items-center">
                      <button 
                        className="w-1/2 bg-black text-white text-sm font-bold px-4 h-10 rounded-lg mr-3"
                        name="plans"
                        onClick={actionStatus}
                      >
                        Planes
                      </button>
                      <button 
                        className="w-1/2 bg-white text-sm font-bold px-4 h-10 rounded-lg"
                        name="config-plan"
                        onClick={actionStatus}
                      >
                        $
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>
        <div className="mt-3">
          <div className="flex flex-wrap w-full">
            <div className="my-12 flex rounded-md w-full h-full items-center containermarginProfileEmployer">
              <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
                <div className="bg-Light-purple rounded-xl p-4">
                  <div className="text-white font-semibold">
                    <p className="text-orange-500">Acerca de</p>
                    <p className="text white">Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora itaque est sequi reiciendis sunt temporibus qui, veritatis exercitationem libero mollitia facilis repudiandae dolor delectus iusto minima, nam sed commodi perspiciatis?Lorem ipsum dolor sit, amet consectetur adipisicing elit. Unde quisquam delectus optio eum eaque, quibusdam, odio, libero aut neque reprehenderit corrupti obcaecati. Obcaecati, cumque amet neque qui totam provident commodi.</p>
                    <p className="text-orange-500">Redes Sociales</p>
                    <a target="_blank" href="https://www.facebook.com/Chambaticon-103086529154814/"><FontAwesomeIcon className="text-white hover:text-Yellow transition-colors p-2 " size="2x" icon={faFacebookSquare} /></a>
                    <a target="_blank" href="https://instagram.com/chambaticon?igshid=YmMyMTA2M2Y="><FontAwesomeIcon className="text-white hover:text-Yellow transition-colors p-2" size="2x" icon={faInstagram} /></a>
                    <a target="_blank" href="https://www.linkedin.com/company/chambaticon/"><FontAwesomeIcon className="text-white hover:text-Yellow transition-colors p-2" size="2x" icon={faLinkedin} /></a>
                  </div>
                </div>
                <div className="bg-Light-purple rounded-xl p-4">
                  <div className="text-start text-white font-semibold w-full">
                    <p className="text-orange-500">Actividad Economica</p>
                    <p className="text-white h-6 mb-2">{ activity }</p>
                    <p className="text-orange-500">Telefono Principal</p>
                    <p className="text-white h-6 mb-2">{dataEmployer.phone}</p>
                    <p className="text-orange-500">Telefono Secundario</p>
                    <p className="text-white h-6 mb-2">{ dataEmployer.phone_alternative }</p>
                    <p className="text-orange-500">Email</p>
                    <p className="text-white h-6 mb-2">{ dataEmployer.email }</p>
                    <p className="text-orange-500">Pais</p>
                    <p className="text-white h-6 mb-2">{ country }</p>
                    <p className="text-orange-500">Idiomas que habla el empleador</p>
                    <p className="text-white h-6 mb-2">{ dataEmployer.laguages }</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
export default Employer;
