
import { Link, useNavigate, useParams } from "react-router-dom";
import imagenesEditProject from "../../assets/imgEditProject/imgEditProject";
import CreateProject from "../../components/createProject/createProject";


import axios from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Project } from "../../Interfaces/Project";

import Select from 'react-select';
import { ValidateEmail } from "../../function/Login";
import { async } from "@firebase/util";
import Cookies from "universal-cookie";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import IconLoading from "../iconLoading/IconLoading";
import { validateData } from "../validateData";
import UpdatedProjectSkeleton from "../Skeleton/formUpdatedProject/updatedProjectSkeleton";
import { date } from "@formkit/tempo";
import Skeleton from "react-loading-skeleton";

const options = [
   { value: 'chocolate', label: 'Chocolate' },
   { value: 'strawberry', label: 'Strawberry' },
   { value: 'vanilla', label: 'Vanilla' }
];

const initialStateOption = {
   value: "",
   label: ""
};

const initialStateProject = {
   id_type_opportunities: "",
   s_types_id: "",
   project_name: "",
   user_company_id: "",
   publication_date: "",
   delivery_date: "",
   // end_date: "",
   dp: "",
   description: "",
   budget: "",
   public_info: "",
   // file:"",
   knowledges: []
};

const initialStateListSpecialization = {
   created_at: "",
   id: 0,
   name: "",
   updated_at: ""
};

const initialStateInput = {
   input: "",
   message: '',
   state: false
}

const initialStateBudget = {
   id: "",
   range: ""
}

type handleInputChangeSpecialtie = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;

const CreateProjectComponent = ({ typeForm }: any) => {

   const [infoProject, setInfoProject] = useState<Project>(initialStateProject);

   const [File, setFile] = useState({
      file: {
         lastModified: "",
         lastModifiedDate: "",
         name: "",
         size: "",
         type: "",
         webkitRelativePath: ""
      }
   });

   const [oldFile, setOldFile] = useState({
      file: {
         lastModified: "",
         lastModifiedDate: "",
         name: "",
         size: "",
         type: "",
         webkitRelativePath: ""
      }
   });

   interface alertLogin {
      input?: string,
      message?: string | boolean,
      status?: boolean
   }

   const params = useParams();

   const [specializations, setSpecializations] = useState([initialStateListSpecialization]);

   const [optionStates, setOptionStates] = useState([initialStateOption]);

   const [SelectedValues, setSelectedValues] = useState([]);

   const [listBudget, setListBudget] = useState([initialStateBudget]);

   const [alert, setAlert] = useState<alertLogin[]>([initialStateInput]);

   const [termsAndConditions, setTermsAndConditions] = useState<boolean | undefined>(false);

   const [sendingData, setSendingData] = useState(false);

   const [characterTextLength, setCharacterTextLength] = useState(2500);

   const [lengthText, setLengthText] = useState(0);

   const [IsLoading, setIsLoading] = useState(typeForm === "update" ? true : false);

   const [dateModify, setDateModify] = useState(typeForm == "update" ? false : true);

   const [oldDataProject, setOldDataProject] = useState<Project>(initialStateProject);

   const [knowledges, setKnowledges] = useState([]);

   let checkBoxInfoPublic:any = useRef(null);
   let checkBoxInfoPublic2:any = useRef(null);

   let checkBoxDocumentProposal:any = useRef(null);
   let checkBoxDocumentProposal2:any = useRef(null);

   let checkBoxTermsAndConditions:any = useRef(null);

   // Cookies
   const cookies = new Cookies();

   const getSesion = cookies.get('Bearer');

   const MySwal = withReactContent(Swal);

   const navigate = useNavigate();

   const dateToday = new Date();

   const [dateLimitPublish, setDateLimitPublish] = useState<string>('');

   const [dDay, setDDay] = useState<string>('');

   let fileRefProfile = null;

   // Handle values input
   const handleInputProject = ({ target: { name, value, id, } }: handleInputChangeSpecialtie) => {

      if (name == 'description') {
         setLengthText(value.length);
         if (value.length >= 2500) {
            setLengthText(2500);
            setInfoProject({ ...infoProject, [name]: value.substring(0, 2500) });
         } else {
            setInfoProject({ ...infoProject, [name]: value });
         }
      } else {
         if (name == "termsAndConditions") {
            const checkbox = document.getElementById("termsAndConditions",) as HTMLInputElement | null;
            const valueCheck = checkbox?.checked;
            setTermsAndConditions(valueCheck);
         } else {
            setInfoProject({ ...infoProject, [name]: value });
         };

         if (name == "s_types_id") knowledged([value]);
      }
   };


   // Handle Values Select
   const selectInputRef = useRef<any>();

   const valueOpcion = (e: any) => {
      console.log("🚀 ~ valueOpcion ~ e:", e)
      if (e.length > 0) {
         const values = e.map((option: any) => { return option.value })
         setSelectedValues(values);
      }
   }

   // Clear values in select
   const clearValuesSelected = () => {
      selectInputRef.current.setValue("");
   };


   // handle form end validations
   const handleFormProject = async (event: React.FormEvent<HTMLFormElement>) => {

      event.preventDefault()

      infoProject.user_company_id = getSesion.id;
      infoProject.id_type_opportunities = "4";
      infoProject.knowledges = SelectedValues;

      let inputs = Object.entries(infoProject).map(([key, value]) => {
         return {
            name: key,
            value: value,
         };
      });

      //Agregar el archivo a los inputs para validar
      inputs.push({ name: "documents", value: File.file.name });

      // Agregar el terminos y condiciones a los inputs para validar
      inputs.push({ name: "termsAndConditions", value: termsAndConditions ? true : ""});

      if (SelectedValues.length > 0) {
         inputs = inputs.filter(input => input.name !== "knowledges");
      }

      if (dateModify === false) {
         inputs = inputs.filter(input => input.name !== "publication_date");
         inputs = inputs.filter(input => input.name !== "delivery_date");
      }

      console.log(inputs, "inputs");

      // clearValuesSelected();

      const datosValidados = await validateData(inputs);

      console.log(datosValidados, "datosValidados");

      // let verifyValues = [
      //    { name: "name", value: infoProject.project_name },
      //    { name: "specialty", value: infoProject.s_types_id },
      //    { name: "budget", value: infoProject.budget },
      //    { name: "dateInit", value: infoProject.delivery_date },
      //    // { name: "dateEnd", value: infoProject.end_date },
      //    { name: "publication_date", value: infoProject.publication_date },
      //    { name: "stateProfile", value: infoProject.public_info },
      //    { name: "description", value: infoProject.description },
      //    { name: "amount", value: String(infoProject.knowledges.length) },
      //    { name: "documents", value: File.file.name },
      //    { name: "receiveDocuments", value: infoProject.dp },
      //    { name: "termsAndConditions", value: String(termsAndConditions) },
      // ];

      setAlert(datosValidados);

      let totalValidaciones = datosValidados.filter(input => input.status !== false).map(() => { return false });

      //Eliminar termsAndConditions de las validaciones
      inputs = inputs.filter(input => input.name !== "termsAndConditions");

      console.log(infoProject, "infoProject");

      console.log(params.id, "params.id")

      if (totalValidaciones.length === 0 && termsAndConditions === true) {
         sendData();
      } else {
         MySwal.fire({
            position: 'center',
            icon: 'error',
            title: 'Por favor, asegúrate de llenar todos los campos requeridos.',
            showConfirmButton: false,
            timer: 3000
         });
      }
   };


   // Create project

   const sendData = () => {

      setSendingData(true);

      let url = "";

      if (typeForm === "create") {
         url = `${process.env.REACT_APP_API_URI}company/create/project/${getSesion.id}`;
      } else if (typeForm === "update") {
         url = `${process.env.REACT_APP_API_URI}company/update/project/${params.id}`;
      }

      let config = {
         // multipart/form-data
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'ApiToken': `Bearer ${getSesion.token}`
         }
      };

      console.log(infoProject, "infoProject");

      // axios.post(url, infoProject, config)
      axios.post(url, infoProject, config)
         .then(response => {

            console.log(response.data, "response.data");

            if (response.data.message === "Número máximo de proyectos publicados por mes alcanzado.") {
               MySwal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: response.data.message,
                  showConfirmButton: true,
                  text: "¿Deseas adquirir un plan para publicar más proyectos?",
                  showDenyButton: true,
                  denyButtonText: "No, gracias",
                  confirmButtonText: "Sí, adquirir",
                  confirmButtonColor: "#f7d154",
                  denyButtonColor: "#f7d154",
                  timer: 3500
               }).then((response) => {
                  if (response.isConfirmed) {
                     navigate('/Planes');
                  } else if (response.isDenied) {
                     navigate('/perfil-empresa');
                  }
               });

            } else {
               if (typeForm === "create"){
                  sendFileProject(response.data.data.id);
                  MySwal.fire({
                     position: 'center',
                     icon: 'success',
                     title: response.data.message,
                     showConfirmButton: false,
                     timer: 3500
                  }).then(() => {
                     navigate('/perfil-empresa');
                  });
               } else {
                  if (File.file.name !== oldFile.file.name) {
                     sendFileProject(response.data.data.id);
                     MySwal.fire({
                        position: 'center',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 3500
                     }).then(() => {
                        navigate('/perfil-empresa');
                     });
                  } else {
                     MySwal.fire({
                        position: 'center',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 3500
                     }).then(() => {
                        navigate('/perfil-empresa');
                     });
                     setSendingData(false);
                  };
               }
            };
            setSendingData(false);
         })
         .catch(err => {
            console.log(err.response.data, "err.response.data");
            MySwal.fire({
               position: 'center',
               icon: 'error',
               title: err.response.data.message,
               showConfirmButton: false,
               timer: 5500
            });
            setSendingData(false);
         })
   };

   const sendFileProject = (projectId: Number) => {

      const url = `${process.env.REACT_APP_API_URI}company/upload-project-file/${projectId}`;

      let config = {
         // multipart/form-data
         headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'ApiToken': `Bearer ${getSesion.token}`
         }
      };

      axios.post(url, File, config)
         .then(response => {
            // MySwal.fire({
            //    position: 'center',
            //    icon: 'success',
            //    title: response.data.message,
            //    showConfirmButton: false,
            //    timer: 4000
            // }).then(() => {
            //    navigate('/proyectos');
            // });
            setSendingData(false);
         })
         .catch(err => {

            MySwal.fire({
               position: 'center',
               icon: 'error',
               title: err.response.data.error,
               showConfirmButton: false,
               timer: 4000
            });
            setSendingData(false);
         })
   }

   // handle files
   const uploadFiles = (e: { target: { files: any; }; }) => {

      let files = e.target.files;

      let image = files[0];

      let imageUrl = URL.createObjectURL(image);

      if (image.type === "application/pdf") {
         fileRefProfile = image;
         setFile({ file: fileRefProfile })
      } else {
         MySwal.fire({
            position: 'center',
            icon: 'error',
            title: "Solo se aceptan archivos .pdf",
            showConfirmButton: false,
            timer: 3500
         });

      };
   };

   // Get knowledged
   async function knowledged(ids: string[]) {

      setOptionStates([initialStateOption]);

      const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/Knowledge/${ids}`;

      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'ApiToken': `Bearer ${getSesion.token}`
         }
      };
      try {

         setOptionStates([initialStateOption]);

         const resp = await axios.get(urlCountry, config);

         if (resp.data.data.length > 0) {

            resp.data.data.map((infoData: any) => {
               setOptionStates(current => [...current, { value: infoData.id, label: infoData.name }]);
            });
         };

      } catch (err) {
         setOptionStates([initialStateOption]);
      };
   };

   async function getInfoProject() {

      setIsLoading(true);

      // const urlCountry = `${process.env.REACT_APP_API_URI}project/get/${params.id}`;
      let urlListOpportunity = `${process.env.REACT_APP_API_URI}project/get/${params.id}?id_type_opportunities=4`;


      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      };
      try {

         await axios.get(urlListOpportunity, config).then((resp) => {

            console.log(resp.data, "resp.data");

            setTermsAndConditions(true);
            if (resp.data) {
               if (resp.data.public_info == "1") {

                  if (checkBoxInfoPublic.current != null) { checkBoxInfoPublic.current.checked = true; };
         
               } else if (resp.data.public_info == "2") {
         
                  if (checkBoxInfoPublic2.current != null) { checkBoxInfoPublic2.current.checked = true; };
               };
         
               if (resp.data.accept_proposals_files == "1") {
         
                  if (checkBoxDocumentProposal.current != null) { checkBoxDocumentProposal.current.checked = true; }
         
               } else if (resp.data.accept_proposals_files == "2") {
         
                  if (checkBoxDocumentProposal2.current != null) { checkBoxDocumentProposal2.current.checked = true; }
               };

               const listDataNew = {
                  user_company_id: resp.data.user_company_id,
                  s_types_id: resp.data.s_types_id,
                  project_name: resp.data.project_name,
                  publication_date: resp.data.publication_date,
                  delivery_date: resp.data.delivery_date,
                  // end_date: resp.data.end_date,
                  dp: resp.data.accept_proposals_files,
                  description: resp.data.description,
                  budget: resp.data.budget.id,
                  public_info: resp.data.public_info,
                  id_type_opportunities: "4",
                  knowledges: []
               }

               

               // infoProject.s_types_id = resp.data.s_types_id;
               // infoProject.project_name = resp.data.project_name;
               // infoProject.delivery_date = resp.data.delivery_date;
               // infoProject.dp = resp.data.accept_proposals_files;
               // infoProject.description = resp.data.description;
               // setLengthText(resp.data.description.length);
               // infoProject.publication_date = resp.data.publication_date;
               // infoProject.budget = resp.data.budget.id;
               // infoProject.public_info = resp.data.public_info;
               // infoProject.id_type_opportunities = "4";

               File.file.name = resp.data.company_project_file;
               oldFile.file.name = resp.data.company_project_file;

               if (resp.data.knowledges_tags.length > 0) {

                  const knowledgesListDefault = resp.data.knowledges_tags.map((item: any) => {
                     return { "value": item.id, "label": item.name }
                  });
                  console.log("🚀 ~ knowledgesListDefault ~ knowledgesListDefault:", knowledgesListDefault)

                  const getknowledgesList = resp.data.knowledges_tags.map((item: any) => {
                     return item.id
                  });
                  console.log("🚀 ~ getknowledgesList ~ getknowledgesList:", getknowledgesList)

                  selectInputRef.current.setValue(knowledgesListDefault);
                  
                  setKnowledges(getknowledgesList);
                  setOptionStates(knowledgesListDefault);
               } else {
                  setKnowledges([]);
                  setOptionStates([]);
               }
               setOldDataProject(listDataNew);
               setInfoProject(listDataNew);
               checkBoxTermsAndConditions.current.checked = true;
            }
            setIsLoading(false)
         });
        
      } catch (err) {
         setIsLoading(false);
         console.error(err);
      }
   };

   // handle values default
   useEffect(() => {

      if (typeForm === "update") {
         getInfoProject();
      }

      // ObtenerPlanActual();
      async function getSpecialties() {

         const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/types`;

         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         };
         try {

            const resp = await axios.get(urlCountry, config);
            setSpecializations(resp.data.specializationTypes);

         } catch (err) {
            // Handle Error Here
            console.error(err);
         }
      };
      getSpecialties();

      async function getBudget() {
         const urlBudget = `${process.env.REACT_APP_API_URI}budget`;
         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         };

         try {
            const resp = await axios.get(urlBudget, config);

            setListBudget(resp.data);

         } catch (err) {
            console.error(err);
         }
      };
      getBudget();

      // console.log(dateToday.setMonth);
      if ((dateToday.getMonth() + 1) < 10) {
         const mounth = `0${dateToday.getMonth() + 1}`;
         if (dateToday.getDate() < 10) {
            const day = `0${dateToday.getDate()}`;
            let formatDate = `${dateToday.getFullYear() + `-` + mounth + `-` + day}`;
            setDateLimitPublish(formatDate);
         }
         else {
            const day = `${dateToday.getDate()}`;
            let formatDate = `${dateToday.getFullYear() + `-` + mounth + `-` + day}`;
            setDateLimitPublish(formatDate);
         }
      } else {
         const mounth = `${dateToday.getMonth() + 1}`;

         if (dateToday.getDate() < 10) {
            const day = `0${dateToday.getDate()}`;
            let formatDate = `${dateToday.getFullYear() + `-` + mounth + `-` + day}`;
            setDateLimitPublish(formatDate);
         }
         else {
            const day = `${dateToday.getDate()}`;
            let formatDate = `${dateToday.getFullYear() + `-` + mounth + `-` + day}`;
            setDateLimitPublish(formatDate);
         }
      }

      // Oportu();

   }, []);


   const [show, setShow] = useState(false);
   const [frutaSeleccionada, setFrutaSeleccionada] = useState('');
   const handleSeleccionDeFruta = (event: any) => {
      setFrutaSeleccionada(event.target.value);
      if (event.target.value == "1") {
         setShow(true);
      }
   };


   console.log(typeForm, "typeForm");

   return (
      <>
         <div className="w-full flex justify-center">

            <form className="w-full" onSubmit={handleFormProject}>

               <div className="bg-bluecard-big p-5 rounded">
                  <div className="md:flex items-center md:justify-between">
                     <h1 className="text-Yellow font-bold text-3xl text-left mb-8 px-2 flex items-center justify-between">Requerimientos del proyecto</h1>
                     {typeForm === "update" && IsLoading === false && <label className="relative inline-flex items-center cursor-pointer ml-2">
                        <input type="checkbox" 
                           checked={dateModify}
                           onChange={() => setDateModify(!dateModify)}
                           className="sr-only peer"
                        />
                        <div className="w-11 h-6 peer-focus:outline-none rounded-full peer bg-gray-500 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-Yellow"></div>
                        <span className="ml-3 text-sm font-medium text-white">Modificar fechas</span>
                     </label>}
                  </div>
                  {dateModify && typeForm === "update" && <h1 className="text-Yellow font-bold text-sm text-left px-2">¡Importante! <span className="text-white">Para modificar las fechas, la nueva fecha de publicación tiene que ser igual o mayor a la actual y por ende la fecha de entrega tiene que ser mayor a la fecha de publicación del proyecto.</span></h1>}
                  <div className="flex flex-wrap">
                     <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 md:pr-2 lg:pr-4 xl:pr-12 px-2`}>
                        <label htmlFor="s_types_id" className="text-white lg:text-sm xl:text-base mb-8">
                           Selecciona la categoría <span className="text-red-500">*</span>
                        </label>
                        {IsLoading ? <Skeleton className="block w-full px-4 py-3 mt-2 font-normal text-xl" />
                        :
                        <select
                           value={infoProject.s_types_id}
                           name="s_types_id"
                           onChange={handleInputProject}
                           className="bg-Light-Blue form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-sky-400 bg-clip-padding border border-solid border-transparent rounded transition ease-in-out md:m-0 focus:border-Yellow focus:outline-none focus:text-white">
                           <option className="text-white">...</option>
                           {
                              specializations.map((item, key) => {
                                 return <option key={key} value={item.id}>{item.name}</option>
                              })
                           }
                        </select>}
                        <p>
                           {
                              alert.filter(input => input.input == "s_types_id" && input.status === true).map((message, key) => (
                                 <span key={key} className="text-red-500">{message.message}</span>
                              ))
                           }
                        </p>
                     </div>

                     <div className={`mb-6 text-start btn-editpro w-full md:w-1/2 lg:w-2/3 px-2`}>
                        <span className="text-white lg:text-sm xl:text-base">
                           Conocimientos <span className="text-red-500">*</span>
                        </span>

                        <Skeleton className={`${IsLoading ? "block" : "hidden"} basic-multi-select z-20 w-full px-4 py-3 mt-2 text-xl font-normal`} />
                        <Select
                           isMulti
                           name="knowledges"
                           options={optionStates.filter((item) => item.label != "")}
                           className={`${IsLoading ? "hidden" : "block"} basic-multi-select z-20`}
                           classNamePrefix="select"
                           onChange={valueOpcion}
                           ref={selectInputRef}
                        />
                        {
                           alert.filter(input => input.input == "knowledges" && input.status === true).map((message, key) => (
                              <span key={key} className="text-red-500">{message.message}</span>
                           ))
                        }
                     </div>

                     <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 md:pr-2 lg:pr-4 xl:pr-12 px-2`}>
                        <label htmlFor="project_name" className="text-white lg:text-sm xl:text-base">Nombre del proyecto <span className="text-red-500">*</span></label>
                        {IsLoading ? <Skeleton className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                        :
                        <input
                           type="text"
                           className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                           name="project_name"
                           value={infoProject.project_name}
                           onChange={handleInputProject}
                        />}
                        {
                           alert.filter(input => input.input == "project_name" && input.status === true).map((message, key) => (
                              <span key={key} className="text-red-500">{message.message}</span>
                           ))
                        }
                     </div>
                     <div className={`mb-6 text-start btn-editpro w-full md:w-1/2 lg:w-2/3 px-2`}>
                        <label htmlFor="documents" className="text-white lg:text-sm xl:text-base">Adjuntar documento/s complementario <span className="text-red-500">*</span></label>
                        <input
                           type="file"
                           className="btn-editpro form-control w-full mt-2 pr-4 text-xs md:text-lg text-holder-gray font-normal bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                           name="documents"
                           id="documents"
                           onChange={uploadFiles}
                           accept=".pdf"
                           hidden
                        />
                        {IsLoading ? 
                           <label>
                              <div className="flex mt-2 z-10">
                                 <div className="relative w-full">
                                    <Skeleton className="py-4 px-4 w-full z-20 text-sm rounded-e-lg rounded-s-gray-100 rounded-s-2 flex items-center" />
                                 </div>
                              </div>
                           </label>
                        :
                        <label htmlFor="documents">
                           <div className="flex cursor-pointer mt-2 z-10">
                              <div className="flex-shrink-0 z-10 inline-flex items-center py-3 px-4 text-sm font-medium text-center rounded-s-lg bg-Azul-Ticongle text-white focus:border-Yellow rounded-l">Seleccionar archivo</div>
                              <div className="relative w-full">
                                 <div className="py-4 px-4 w-full z-20 text-sm rounded-e-lg rounded-s-gray-100 rounded-s-2 bg-input-blue text-holder-gray focus:border-Yellow h-full flex items-center">
                                    <span className="block md:hidden">
                                       {
                                          File.file.name 
                                          ?  (
                                                File.file.name.length > 15
                                                ?
                                                File.file.name.substring(0, 15) + "..."
                                                :
                                                File.file.name
                                             )
                                          : 
                                          "Ningún archivo seleccionado"}
                                    </span>
                                    <span className="hidden lg:block">
                                       {
                                          File.file.name 
                                          ?  (
                                                File.file.name.length > 40
                                                ?
                                                File.file.name.substring(0, 40) + "..."
                                                :
                                                File.file.name
                                             )
                                          : 
                                          "Ningún archivo seleccionado"}
                                    </span>
                                    <span className="hidden md:block lg:hidden">
                                       {File.file.name 
                                       ?  (
                                             File.file.name.length > 15
                                             ?
                                             File.file.name.substring(0, 15) + "..."
                                             :
                                             File.file.name
                                          )
                                       : 
                                       "Ningún archivo sele..."}
                                    </span>
                                 </div>
                              </div>
                           </div>
                        </label>}
                        {
                           alert.filter(input => input.input == "documents" && input.status === true).map((message, key) => (
                              <span key={key} className="text-red-500">{message.message}</span>
                           ))
                        }

                     </div>
                     <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 md:pr-2 lg:pr-4 xl:pr-12 px-2`}>
                        <label htmlFor="budget" className="text-white lg:text-sm xl:text-base">Presupuesto disponible <span className="text-red-500">*</span></label>
                        {IsLoading ? <Skeleton className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-Yellow bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                        :
                        <select
                           name="budget"
                           value={infoProject.budget}
                           onChange={handleInputProject}
                           className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-Yellow bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"  >
                           <option className="text-white">Selecciona un presupuesto</option>

                           {
                              listBudget.map((range, key) => {
                                 return <option key={key} value={range.id}>{range.range}</option>
                              })
                           }

                        </select>}
                        {
                           alert.filter(input => input.input == "budget" && input.status === true).map((message, key) => (
                              <span key={key} className="text-red-500">{message.message}</span>
                           ))
                        }
                     </div>
                     {
                        dateModify
                        &&
                        <>
                           <div className={`mb-6 text-start md:pr-2 w-full md:w-1/2 lg:w-1/3 px-2`}>
                              <label htmlFor="publication_date" className="text-white lg:text-sm xl:text-base">Fecha de publicación del proyecto <span className="text-red-500">*</span></label>
                              {IsLoading ? <Skeleton className="form-control block w-full mt-2 px-4 py-3 text-lg text-holder-gray font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                              :
                              <input
                                 type="date"
                                 className="form-control block w-full mt-2 px-4 py-3 text-lg text-holder-gray font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                 name="publication_date"
                                 value={infoProject.publication_date}
                                 onChange={handleInputProject}
                              />}
                              {
                                 alert.filter(input => input.input == "publication_date" && input.status === true).map((message, key) => (
                                    <span key={key} className="text-red-500">{message.message}</span>
                                 ))
                              }
                           </div>
                           <div className={`mb-6 text-start md:pr-2 w-full md:w-1/2 lg:w-1/3 px-2`}>
                              <label htmlFor="delivery_date" className="text-white lg:text-sm xl:text-base">Fecha de entrega del proyecto <span className="text-red-500">*</span></label>
                              {IsLoading ? <Skeleton className="form-control block w-full mt-2 px-4 py-3 text-lg text-holder-gray font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                              :
                              <input
                                 type="date"
                                 className="form-control block w-full mt-2 px-4 py-3 text-lg text-holder-gray font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                 name="delivery_date"
                                 value={infoProject.delivery_date}
                                 onChange={handleInputProject}
                              />}
                              {
                                 alert.filter(input => input.input == "delivery_date" && input.status === true).map((message, key) => (
                                    <span key={key} className="text-red-500">{message.message}</span>
                                 ))
                              }
                           </div>
                        </>
                     }
                     {/* <div className={"mb-6 text-start w-full md:w-1/2 lg:w-1/3 md:pr-2 lg:pr-4 xl:pr-12"}>
                     <label htmlFor="dateBirth" className="text-white lg:text-sm xl:text-base">Fecha limite del proyecto</label>
                     <input
                        type="date"
                        className="form-control block w-full mt-2 px-4 py-3 text-lg text-holder-gray font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                        name="end_date"
                        value={infoProject.end_date}
                        onChange={handleInputProject}
                     />
                     {
                        alert.filter(input => input.input == "dateEnd" && input.status === true).map((message, key) => (
                           <span key={key} className="text-red-500">{message.message}</span>
                        ))
                     }
                  </div> */}
                     <div className={`mb-6 text-start btn-editpro w-full px-2`}>
                        <label htmlFor="dateBirth" className="text-white lg:text-sm xl:text-base">Descripción del proyecto <span className="text-red-500">*</span></label>
                        <div className="relative">
                           <Skeleton className={`${IsLoading ? "block" : "hidden"} absolute w-full h-full`} />
                           <textarea
                              className={`${IsLoading ? "invisible" : "visible"} form-control block w-full mt-2 px-4 py-3 text-lg font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none`}
                              rows={7}
                              placeholder="Descripción"
                              name="description"
                              value={infoProject.description}
                              onChange={handleInputProject}
                           />
                        </div>
                        <div className="text-[rgba(500,100,0,0.5)] italic">{lengthText} / {characterTextLength}</div>
                        {
                           alert.filter(input => input.input == "description" && input.status === true).map((message, key) => (
                              <span key={key} className="text-red-500">{message.message}</span>
                           ))
                        }
                     </div>
                  </div>

                  <div className="text-justify md:text-left px-2">
                     <div>
                        <label htmlFor="" className="text-white lg:text-sm xl:text-base">¿Deseas publicar este proyecto utilizando los datos de tu empresa o prefieres publicarlo de forma anónima? <span className="text-red-500">*</span></label>
                     </div>
                     <div className="flex mt-2">

                        <div className="px-2 flex items-center">
                           <Skeleton className={`${IsLoading ? "block" : "hidden"} scale-150 w-3 h-3 mr-4 rounded-none`} />
                           
                           <input className={`${IsLoading ? "hidden" : "block"} scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white`} type="radio"
                              id="public_info1"
                              name="public_info"
                              value="1"
                              onChange={handleInputProject}
                              ref={checkBoxInfoPublic}
                           />
                           <span className="text-white lg:text-sm xl:text-base">Público </span>
                        </div>

                        <div className="px-2 flex items-center">
                           <Skeleton className={`${IsLoading ? "block" : "hidden"} scale-150 w-3 h-3 mr-4 rounded-none`} />
                           
                           <input className={`${IsLoading ? "hidden" : "block"} scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white`}
                              type="radio"
                              name="public_info"
                              id="public_info2"
                              value="2"
                              onChange={handleInputProject}
                              ref={checkBoxInfoPublic2}
                           />
                           <span className="text-white lg:text-sm xl:text-base">Anónima</span>
                        </div>
                     </div>
                     {
                        alert.filter(input => input.input == "public_info" && input.status === true).map((message, key) => (
                           <span key={key} className="text-red-500">{message.message}</span>
                        ))
                     }
                  </div>

                  <div className="flex flex-wrap text-justify md:text-left mt-8 px-2">
                     <div className="w-full md:w-1/3 pr-4 lg:pr-2 xl:pr-8">
                        <label htmlFor="" className="text-white lg:text-sm xl:text-base">¿Deseas recibir documento de propuesta? <span className="text-red-500">*</span></label>
                        <div className="flex mt-2">
                           <div className="px-2 flex items-center">
                              <Skeleton className={`${IsLoading ? "block" : "hidden"} scale-150 w-3 h-3 mr-4 rounded-none`} />

                              <input className={`${IsLoading ? "hidden" : "block"} scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white`} type="radio"
                                 name="dp"
                                 id="dp1"
                                 value="1"
                                 onChange={handleInputProject}
                                 ref={checkBoxDocumentProposal}
                              />
                              <span className="text-white lg:text-sm xl:text-base">Si</span>
                           </div>
                           <div className="px-2 flex items-center">
                              <Skeleton className={`${IsLoading ? "block" : "hidden"} scale-150 w-3 h-3 mr-4 rounded-none`} />

                              <input className={`${IsLoading ? "hidden" : "block"} scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white`}
                                 type="radio"
                                 name="dp"
                                 id="dp2"
                                 value="2"
                                 onChange={handleInputProject}
                                 ref={checkBoxDocumentProposal2}
                              />
                              <span className="text-white lg:text-sm xl:text-base">No</span>
                           </div>
                        </div>
                        {
                           alert.filter(input => input.input == "dp" && input.status === true).map((message, key) => (
                              <span key={key} className="text-red-500">{message.message}</span>
                           ))
                        }
                     </div>
                     <div className="w-full mt-8 md:mt-0 md:w-1/3">
                        <label htmlFor="" className="text-white lg:text-sm xl:text-base">Términos y condiciones <span className="text-red-500">*</span></label>
                        <div className="flex flex-wrap mt-2">
                           <div className="px-2 flex items-center">
                              <Skeleton className={`${IsLoading ? "block" : "hidden"} scale-150 w-3 h-3 mr-4 rounded-none`} />

                              <input className={`${IsLoading ? "hidden" : "block"} scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white`} type="checkbox"
                                 name="termsAndConditions"
                                 id="termsAndConditions"
                                 ref={checkBoxTermsAndConditions}
                                 onChange={handleInputProject}
                                 disabled={typeForm === "update" ? true : false}
                                 />
                              <span className="text-white lg:text-xs xl:text-base">Acepto los <a href="/terminos-de-servicio" target={"_blank"} className="hover:text-Yellow">términos y condiciones</a></span>
                           </div>
                           {
                              alert.filter(input => input.input == "termsAndConditions" && input.status === true).map((message, key) => (
                                 <span key={key} className="text-red-500">{message.message}</span>
                              ))
                           }
                        </div>
                     </div>
                     <div className="w-full md:w-1/3 mt-8 md:mt-0 flex items-center">

                        <div className="relative w-full">
                           <Skeleton className={`${IsLoading ? "block" : "hidden"} absolute w-full h-full`} />
                           <button
                              type="submit"
                              className={`${IsLoading ? "invisible" : "visible"} inline-block px-7 py-5 bg-Yellow text-white font-medium lg:text-sm xl:text-base leading-snug rounded shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out w-full text-center`}>

                              {sendingData 
                                 ?
                                 <IconLoading />
                                 :
                                    typeForm === "update" 
                                    ? 
                                    <>
                                       Actualizar mi proyecto
                                    </>
                                    :
                                    <>
                                       Publicar mi proyecto
                                    </>
                              }
                           </button>
                        </div>

                     </div>
                  </div>

               </div>

            </form>
         </div>
      </>
   )
}

export default CreateProjectComponent;