import axios from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import imagenesEditPerfil from "../../assets/imgEditPerfil/imagenesEditPerfil.js";
import imagenesEditProject from "../../assets/imgEditProject/imgEditProject";
import { ValidateEmail } from "../../function/Login";
import { UpdatedInfoCompany } from "../../Interfaces/Company.js";
import { InitialStateCountry, InitialStateDepartment } from "../../Interfaces/departmentsAndmuniti";
import { alertLogin } from "../../Interfaces/Login";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from "universal-cookie";
import { useNavigate, Link } from "react-router-dom";

import imgDefault from '../../assets/imgEditPerfil/user.png';
//Input Mask
import InputMask from 'react-input-mask';
import React from "react";
import CompanyEditSkeleton from "../Skeleton/ProfileCompany/editInfoSkeleton";
import IconLoading from "../iconLoading/IconLoading";

// Skeleton IMG Profile
import ImgProfileSkeleton from "../Skeleton/ProfileCompany/imgProfileSkeleton";

import mapboxgl, { FullscreenControl, GeolocateControl, Marker, NavigationControl } from 'mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"
// @ts-ignore
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';


// type
type handleInputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>


// inicialStateCompany
const initialStateUpdatedInfoCompany = {
    company_name: "",
    company_contact_name: "",
    states_id: "",
    country_id: "",
    gmail: "",
    company_description: "",
    phone: "",
    secondary_phone: "",
    activity_id: "",
    direction: "",
    photo:"",
    Facebook_link: "",
    Instagram_link: "",
    Twitter_link: "",
    Linkedin_link: "",
    id: "",
    plan: "",
    planes_id: "",
    complete_profile_status: 0,
    latitude: 0,
    longitude: 0
}

const initialStateCountry = {
    code: 0,
    id: 0,
    name: ""
}

const initialStateDepartments = {
    code: 0,
    id: 0,
    name: ""
}

const initialStateInput = {
    input: "",
    message: '',
    state: false
}

const initialStateEconomicActivityDefault = {
    id: 0,
    name: ""
}

const EditEmpresa = () => {

    const [UpdatedCompany, setUpdatedCompany] = useState<UpdatedInfoCompany>(initialStateUpdatedInfoCompany);

    const [Countries, setCountries] = useState<InitialStateCountry[]>([initialStateCountry]);

    const [departments, setDepartments] = useState<InitialStateDepartment[]>([initialStateDepartments]);

    const [alert, setAlert] = useState<alertLogin[]>([initialStateInput]);

    const [initialStateEconomicActivity, setInitialStateEconomicActivity] = useState([initialStateEconomicActivityDefault]);

    const [sendingData, setSendingData] = useState(false);

    const [stateProfileInput, setStateProfileInput] = useState('');

    const [inputPhone, setInputPhone] = useState(false);

    //Input Mask
    const [phone, setPhone] = React.useState<string>("");

    const [MostrarImg, setMostrarImg] = useState("second");

    const [imgOld, setImgOld] = useState(imgDefault);

    const [closeImg, setCloseImg] = useState(false);

    const [loadImgProfile, setloadImgProfile] = useState(true);

    // Cookies
    const cookies = new Cookies();

    const getSesion = cookies.get('Bearer');

    const navigate = useNavigate();

    const MySwal = withReactContent(Swal);

    const [IsLoading, setIsLoading] = useState(true);

    let fileRefProfile = null;

    const [maskChar, setMaskChar] = useState(null);

    const [characterTextLength, setCharacterTextLength] = useState(500);

    const [lengthText, setLengthText] = useState(0);

    const mapContainer = useRef<any>();
    
    const map = useRef<any>();

    const uploadProfile = (e: { target: { files: any; }; }) => {

        let files = e.target.files;


        let image = files[0];

        // setFilePreview({filePreview:URL.createObjectURL(image)});

        fileRefProfile = image;

        let imageUrl = URL.createObjectURL(image);


        UpdatedCompany.photo = fileRefProfile

        // console.log('fileRefProfile',fileRefProfile);

        // console.log('imageUrl', imageUrl);
        setloadImgProfile(true);
        setMostrarImg(URL.createObjectURL(image));
        setTimeout(() => {
            setCloseImg(true);
            setloadImgProfile(false);
        }, 1000);
    }

    const reverseImg = () => {
        setloadImgProfile(true);
        // console.log(imgOld);
        setMostrarImg(imgOld);
        setCloseImg(false);
        setTimeout(() => {
            setloadImgProfile(false);
        }, 1000);
    }

    const resetInput = (e: React.MouseEvent<HTMLElement>) => {
        UpdatedCompany.photo = '';
    }


    const handleInputChangeCompany = ({ target: { name, value, id, } }: handleInputChange) => {

        if (name == 'company_description'){
            setLengthText(value.length);
            if(value.length >= 500){
                setLengthText(500);
                setUpdatedCompany({...UpdatedCompany, [name]:value.substring(0,500)});
            } else {
                setUpdatedCompany({ ...UpdatedCompany, [name]: value });
            }
        }else {
            //Input Mask
            if (name == 'phone' || name == 'secondary_phone') {
                setPhone(value);
            }
            if (name == 'country_id' && value == '3') {
                setInputPhone(true);
            }
            else {
                setInputPhone(false);
            }          
            setUpdatedCompany({ ...UpdatedCompany, [name]: value });
        }


    };

    const handleUpdatedInfoValidate = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        UpdatedCompany.id = getSesion.id;

        let verifyValues = [
            { name: "name", value: UpdatedCompany.company_name },
            { name: "tel", value: UpdatedCompany.phone },
            // {name:"tel2",           value:UpdatedCompany.secondary_phone},
            { name: "country", value: UpdatedCompany.country_id },
            // {name:"department",     value:UpdatedCompany.department},
            { name: "address", value: UpdatedCompany.direction },
            { name: "description", value: UpdatedCompany.company_description },
            { name: "stateProfile", value: UpdatedCompany.states_id },
            { name: "economic_activity", value: UpdatedCompany.activity_id },
            // { name: "latitude", value: '' }, 
            // { name: "longitude", value: '' },
        ];

        const validateInputs = ValidateEmail(verifyValues);
        
        setAlert(validateInputs);

        const state = validateInputs.filter(input => input.state === false).map((s) => { return false });


        if ((UpdatedCompany.Facebook_link == undefined || UpdatedCompany.Facebook_link == "") && (UpdatedCompany.Instagram_link == undefined || UpdatedCompany.Instagram_link == "") && (UpdatedCompany.Twitter_link == undefined || UpdatedCompany.Twitter_link == "") && (UpdatedCompany.Linkedin_link == undefined || UpdatedCompany.Linkedin_link == "")) {
            
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: "Agregar al menos una red social",
                showConfirmButton: false,
                timer: 5500
            });

        } else {

            UpdatedCompany.latitude = UbicacionMap.lat;
            UpdatedCompany.longitude = UbicacionMap.lng;

            if (state.length >= 7){

                console.log("UpdatedCompany ==>", UpdatedCompany);

                if(UpdatedCompany.photo == "" || UpdatedCompany.photo == null || UpdatedCompany.photo == "null" || UpdatedCompany.photo == " null"){
                    
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "Por favor, subir su logotipo al perfil para completar la información de la empresa.",
                        showConfirmButton: false,
                        timer: 5000
                    });

                }else{
                    
                    console.log("====>", UpdatedCompany);
                    if (UpdatedCompany.photo.length > 0) {
                        // console.log("Se ha seleccionado un archivo.");
                        // // Aquí puedes hacer lo que necesites con el archivo seleccionado
                        // var file = UpdatedCompany.photo[0];
                        // console.log("Nombre del archivo:");
                        // console.log("Tamaño del archivo:", "bytes");
                        // console.log("Tipo de archivo:",);
                        UpdatedCompany.photo = "";
                    }
                    sendData();
                    // if(){

                    // }
                    
                    // sendData();
                };
            } ;

        }


        if (UpdatedCompany.photo == "") {

            // delete UpdatedCompany.photo;
            delete UpdatedCompany['photo'];

        };

    };

    function sendData() {

        setSendingData(true);

        const url = `${process.env.REACT_APP_API_URI}company/complete-profile/${getSesion.id}`;

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        }

        console.log('Data form ====>', UpdatedCompany);

        axios.post(url, UpdatedCompany, config)
            .then(response => {

                  console.log('Response company', response.data);

                if (response.data.Error != "El formato del archivo no es permitido solo se permiten archivos .jpg") {
                    MySwal.fire({
                        position: 'center',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 3500
                    }).then(() => {
                        navigate('/perfil-empresa');
                    });

                    setDepartments([initialStateDepartments]);
                    setUpdatedCompany(initialStateUpdatedInfoCompany);

                    setSendingData(false);

                } else {
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 3500
                    });
                    setSendingData(false);
                }



            })
            .catch(err => {
                console.log(err);                
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 4500
                });
                setSendingData(false);

            })

    };


    async function getDepartments(id: string) {

        const urlDepartments = `${process.env.REACT_APP_API_URI}state/${id}`;
    
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        try {
            const resp = await axios.get(urlDepartments, config);
            //    console.log(resp.data);
            if (resp.data.message === "No se encontraron estados o departamentos disponibles.") {

                setDepartments([initialStateDepartments]);

            } else {
                setDepartments(resp.data);
            }

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }

    };

    //  Get country
    useEffect(() => {

        async function fetchData() {
            const urlCountry = `${process.env.REACT_APP_API_URI}country`;
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            };
            try {
                const resp = await axios.get(urlCountry, config);
                //   console.log(resp.data);
                setCountries(resp.data);
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        fetchData();

        async function getEconomicActivedy() {
            const urlCountry = `${process.env.REACT_APP_API_URI}economic-activity`;
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            };
            try {
                const resp = await axios.get(urlCountry, config);
                // console.log('Econimic Actividy', resp.data)
                setInitialStateEconomicActivity(resp.data);
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        getEconomicActivedy();



    }, []);

    useEffect(() => {
        async function GetInfoCompany() {

            const idUser = cookies.get('Bearer');

            const urlCountry = `${process.env.REACT_APP_API_URI}company/profile/${idUser.id}`;
        
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ApiToken': `Bearer ${getSesion.token}`
                }
            };

            try{
                const resp = await axios.get(urlCountry, config);
            
                let imgCompany = "";

                if (resp.data.photo == null || resp.data.photo == "") {
                    imgCompany = imgDefault;
                } else {
                    imgCompany = `${process.env.REACT_APP_PHOTO_URI}company-image/${resp.data.photo}`;
                }

                setMostrarImg(imgCompany);
                setImgOld(imgCompany);
                setTimeout(() => {
                    setloadImgProfile(false);
                }, 1000);

                let facebook = "";
                let instagram = "";
                let twitter = "";
                let linkedin = "";

                if (resp.data.social_media_links !== null && resp.data.social_media_links !== undefined) {
                    if (resp.data.social_media_links.facebook_link != null || resp.data.social_media_links.facebook_link != "") {
                        facebook = resp.data.social_media_links.facebook_link;
                    };

                    if (resp.data.social_media_links.instagram_link != null || resp.data.social_media_links.instagram_link != "") {
                        instagram = resp.data.social_media_links.instagram_link;
                    };

                    if (resp.data.social_media_links.twitter_link != null || resp.data.social_media_links.twitter_link != "") {
                        twitter = resp.data.social_media_links.twitter_link;
                    };

                    if (resp.data.social_media_links.linkedin_link != null || resp.data.social_media_links.linkedin_link != "") {
                        linkedin = resp.data.social_media_links.linkedin_link;
                    };
                }

                if(resp.data.company_description != null){
                    setLengthText((resp.data.company_description).length);
                }

                let listInfo = {
                    company_name: resp.data.company_name,
                    company_contact_name: resp.data.company_contact_name,
                    states_id: resp.data.status,
                    country_id: resp.data.country_id,
                    gmail: resp.data.email,
                    company_description: resp.data.company_description,
                    phone: resp.data.phone,
                    secondary_phone: resp.data.secondary_phone,
                    activity_id: resp.data.activity_id,
                    direction: resp.data.direction,
                    latitude: resp.data.latitude,
                    longitude: resp.data.longitude,
                    photo: resp.data.photo,
                    Facebook_link: facebook,
                    Instagram_link: instagram,
                    Twitter_link: twitter,
                    Linkedin_link: linkedin,
                    id: resp.data.user_id,
                    plan: "",
                    planes_id: "",
                    complete_profile_status: resp.data.complete_profile_status
                };

                switch (resp.data.complete_profile_status) {
                    case 1:
                        setStateProfileInput('En validación');
                        break;
                    case 2:
                        setStateProfileInput('Incompleto');
                        break;
                    case 3:
                        setStateProfileInput('Verificado');
                        break;
                    case 4:
                        setStateProfileInput('Rechazado');
                        break;

                    default:
                        setStateProfileInput('Perfil Empresa');
                        break;
                };

                setUpdatedCompany(listInfo);
                
                if(resp.data.country_id == '3'){
                    setInputPhone(true);
                };

                setIsLoading(false);
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        GetInfoCompany();
    }, []);
    
    mapboxgl.accessToken = 'pk.eyJ1IjoiZ2Vvcmdlc3YiLCJhIjoiY2s5YTJjaGV0MDB0NzNsbXFuM3EyeThnYSJ9.F7uxDu4RAFlHz61e2cxNjw';

    const [lng, setLng] = useState(-88.9426);
    const [lat, setLat] = useState(13.8767);
    const [zoom, setZoom] = useState(9);

    const [UbicacionMap, setUbicacionMap] = useState({
        lng: 0,
        lat: 0
    });
    
    useEffect(() =>{

        if (map.current) return; // initialize map only once

        setTimeout(() => {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v12',
                center: [lng, lat],
                zoom: zoom,
                minZoom: 2,
                maxZoom: 24
            });

            const geocoder = new MapboxGeocoder({
                accessToken: "pk.eyJ1IjoiZ2Vvcmdlc3YiLCJhIjoiY2s5YTJjaGV0MDB0NzNsbXFuM3EyeThnYSJ9.F7uxDu4RAFlHz61e2cxNjw",
                countries: 'sv',
                bbox: [-90.0955545723, 13.1490168319, -87.7235029772, 14.4241327987],
                filter: (item: any) => {
                    return item.context.map((i: { id: string; text: string; }) => {
                        return (i.id.split('.').shift() === 'country' && i.text === 'El Salvador');
                    }).reduce((acc: any, cur: any) => {
                        return acc || cur;
                    });
                },
                placeholder: "Buscar",
            });

            const locateU = new GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true
            });

            let marker: { remove: () => void; };

            map.current.on('click', (e: { lngLat: { lng: number; lat: number; }; }) => {

                setUbicacionMap({
                    lng: lng,
                    lat: lat
                })

                if (typeof marker == 'object') {
                    marker.remove();
                }

                marker = new Marker().setLngLat([e.lngLat.lng, e.lngLat.lat]).addTo(map.current);
                map.current.flyTo({
                    center: e.lngLat,
                    zoom: 15
                });


            });

            geocoder.on('result', (ev: any) => {

                setUbicacionMap({
                    lng: ev.result.geometry.coordinates[0],
                    lat: ev.result.geometry.coordinates[1]
                })
            });

            locateU.on('geolocate', (e: any) => {
                let lon = e.coords.longitude;
                let lat = e.coords.latitude;
                let position = [lon, lat];

                setUbicacionMap({
                    lng: lon,
                    lat: lat
                });

                map.current.flyTo({
                    center: position,
                    zoom: 9
                });

            });


            map.current.addControl(geocoder);
            map.current.addControl(new NavigationControl());
            map.current.addControl(new FullscreenControl());
            map.current.addControl(locateU);
        }, 2500);

    }, []);

    return (
        <>
            {IsLoading && <CompanyEditSkeleton />}
            {!IsLoading &&
                <div className="bg-turquoise">
                    <section className="block justify-center md:flex md:flex-wrap bg-gradient-to-b from-Light-purple via-turquoise to-turquoise lg:px-10">
                        {/* <div className="hidden lg:flex w-1/6 py-14 bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">
                            <img className="py-10 opacity-50 h-1/2 lg:h-2/6 xl:h-1/2" src={imagenesEditPerfil.logo_blanco_mitad} alt="" />
                        </div> */}
                        <form className="w-full lg:w-full flex flex-wrap px-10 pt-10" onSubmit={handleUpdatedInfoValidate} >
                            <div className="w-full md:w-30%">
                                <div className="w-full sm:w-15%">
                                    <Link to="" onClick={() => window.history.go(-1)}>
                                        <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                                    </Link>
                                </div>
                                <div className="relative mb-2 flex justify-center">
                                    <label htmlFor="photo">
                                        {closeImg &&
                                            <button className='flex items-center justify-center text-red-600 text-xs px-1.5 py-1 bg-white absolute left-24 xl:left-56 z-50 rounded-full font-bold sm:w-auto' onClick={reverseImg}>
                                                <FontAwesomeIcon icon={faX} />
                                            </button>
                                        }
                                        {loadImgProfile
                                            ? <ImgProfileSkeleton />
                                            : 
                                            <div className="border-8 rounded-full border-Yellow relative m-auto top-0 bottom-0 left-0 right-0 cursor-pointer">
                                                <div className="bg-black opacity-20 cursor-pointer w-48 h-48 rounded-full object-cover align-middle">
                                                    <img className="w-48 relative h-48 rounded-full object-cover align-middle flex justify-center" src={MostrarImg} alt="" />
                                                </div>
                                                <h1 className="absolute top-1/2 left-1/2 text-white" style={{transform: "translate(-50%, -50%)"}}>
                                                    Editar foto
                                                </h1>
                                            </div>
                                        }
                                    </label>
                                    <input
                                        type="file"
                                        className="hidden bg-sky-700 hover:bg-sky-600 text-white text-xs rounded-full py-2 px-2 m-1"
                                        onClick={resetInput}
                                        id="photo"
                                        value={''}
                                        onChange={uploadProfile}
                                    />
                                </div>
                                <div className="">
                                    <h5 className="text-Yellow text-center">Estado del perfil</h5>
                                    <p className="text-white text-center">{stateProfileInput}</p>
                                </div>
                            </div>
                            <div className="w-full md:w-70%">
                                <h2 className="w-full text-Yellow text-2xl md:text-3xl text-center font-semibold py-4">Editar Perfil</h2>
                                <div className="w-full flex flex-wrap">
                                    {/* Name */}
                                    <div className="text-start w-full md:w-1/2 p-2">
                                        <label htmlFor="nombreCompleto" className="text-white">Ingresa nombre de la empresa</label>
                                        <input
                                            type="text"
                                            className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                            name="company_name"
                                            value={UpdatedCompany.company_name}
                                            onChange={handleInputChangeCompany}
                                        />
                                        {
                                            alert.filter(input => input.input == "name" && input.state === true).map(message => (
                                                <span className="text-red-500">{message.message}</span>
                                            ))
                                        }
                                    </div>

                                    <div className="text-start w-full md:w-1/2 p-2">
                                        <label htmlFor="sex" className="text-white">Correo electrónico</label>
                                        <input
                                            type="text"
                                            className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                            name="gmail"
                                            value={UpdatedCompany.gmail}
                                            onChange={handleInputChangeCompany}
                                            disabled
                                        />
                                        {
                                            alert.filter(input => input.input == "country" && input.state === true).map(message => (
                                                <span className="text-red-500">{message.message}</span>
                                            ))
                                        }
                                    </div>

                                    {/* Actividad Economic */}
                                    <div className="text-start w-full md:w-1/2 p-2">
                                        <label htmlFor="nickname" className="text-white">Actividad económica</label>
                                        {/* <input
                                        type="text"
                                        className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                        name="economic_activity"
                                        
                                    /> */}
                                        <select
                                            name="activity_id"
                                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                            value={UpdatedCompany.activity_id}
                                            onChange={handleInputChangeCompany}
                                        >
                                            <option value="" selected> Actividad económica</option>
                                            {
                                                initialStateEconomicActivity.map((Activity) => {
                                                    return <option value={Activity.id}>{Activity.name}</option>
                                                })
                                            }
                                        </select>

                                        {
                                            alert.filter(input => input.input == "economic_activity" && input.state === true).map(message => (
                                                <span className="text-red-500">{message.message}</span>
                                            ))
                                        }
                                    </div>
                                    {/* Country  */}
                                    <div className="text-start w-full md:w-1/2 p-2">
                                        <label htmlFor="sex" className="text-white">Selecciona tu páis</label>
                                        <select
                                            name="country_id"
                                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                            value={UpdatedCompany.country_id}
                                            onChange={handleInputChangeCompany}
                                        >
                                            <option value="" selected> Selecciona tu páis </option>
                                            {
                                                Countries.map((Countries) => {
                                                    return <option value={Countries.id}>{Countries.name}</option>
                                                })
                                            }
                                        </select>
                                        {
                                            alert.filter(input => input.input == "country" && input.state === true).map(message => (
                                                <span className="text-red-500">{message.message}</span>
                                            ))
                                        }
                                    </div>
                                    <div className="text-start w-full lg:w-2/2 p-2">
                                        <div className="text-start">
                                            <label htmlFor="nombreCompleto" className="text-white">Persona de contacto</label>
                                            <input
                                                type="text"
                                                id="nombreCompleto"
                                                name="company_contact_name"
                                                value={UpdatedCompany.company_contact_name}
                                                onChange={handleInputChangeCompany}
                                                className="bg-color-input-login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                            />
                                            {alert.filter(input => input.input == "company_contact_name" && input.state === true).map(message => (
                                                <span className="text-red-500 text-start">{message.message}</span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex flex-wrap">
                                {/* telephone */}
                                <div className="text-start w-full md:w-1/2 p-2">
                                    <label htmlFor="nickname" className="text-white">Teléfono principal </label>
                                    {/* { 
                                    inputPhone 
                                    ?
                                        <InputMask
                                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                            mask={phone !== "" ? "" : ""}
                                            // maskChar=""
                                            type="number"
                                            placeholder="Teléfono"
                                            name="phone"
                                            value={UpdatedCompany.phone}
                                            onChange={handleInputChangeCompany}
                                        />
                                    :
                                        <InputMask
                                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                            mask={phone !== "" ? "" : ""}
                                            // maskChar=""
                                            type="number"
                                            placeholder="Teléfono"
                                            name="phone"
                                            value={UpdatedCompany.phone}
                                            onChange={handleInputChangeCompany}
                                        />
                                } */}
                                    <input
                                        type="text"
                                        className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                        name="phone"
                                        value={UpdatedCompany.phone}
                                        onChange={handleInputChangeCompany}
                                    />
                                    {
                                        alert.filter(input => input.input == "tel" && input.state === true).map(message => (
                                            <span className="text-red-500">{message.message}</span>
                                        ))
                                    }
                                </div>

                                {/* Telephone secondary */}
                                <div className="text-start w-full md:w-1/2 p-2">

                                    <label htmlFor="nickname" className="text-white">Teléfono secundario</label>
                                    {/* {
                                    inputPhone
                                    ?
                                        <InputMask
                                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                            mask={phone !== "" ? "" : ""}
                                            // maskChar=""
                                            type="number"
                                            placeholder="Teléfono"
                                            name="secondary_phone"
                                            value={UpdatedCompany.secondary_phone}
                                            onChange={handleInputChangeCompany}
                                        />
                                    :
                                        <InputMask
                                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                            mask={phone !== "" ? "" : ""}
                                            maskPlaceholder={null}
                                            type="number"
                                            placeholder="Teléfono"
                                            name="secondary_phone"
                                            value={UpdatedCompany.secondary_phone}
                                            onChange={handleInputChangeCompany}
                                        />
                                } */}
                                    <input
                                        type="text"
                                        className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                        name="secondary_phone"
                                        value={UpdatedCompany.secondary_phone}
                                        onChange={handleInputChangeCompany}
                                    />
                                    {
                                        alert.filter(input => input.input == "tel2" && input.state === true).map(message => (
                                            <span className="text-red-500">{message.message}</span>
                                        ))
                                    }
                                </div>
                                {/* address */}
                                <div className="text-start w-full md:w-1/2 p-2">

                                    <label htmlFor="nickname" className="text-white">Dirección</label>
                                    <input
                                        type="text"
                                        className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                        name="direction"
                                        value={UpdatedCompany.direction}
                                        onChange={handleInputChangeCompany}
                                    />
                                    {
                                        alert.filter(input => input.input == "address" && input.state === true).map(message => (
                                            <span className="text-red-500">{message.message}</span>
                                        ))
                                    }
                                    <div className="py-7" ref={map}>
                                        <div ref={mapContainer} className="map-container" />
                                    </div>
                                </div>
                                {/* description of the person */}
                                <div className="text-start w-full md:w-1/2 p-2">
                                    <label htmlFor="direction" className="text-white">Descripción de la empresa</label>
                                    <textarea
                                        className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                        rows={15}
                                        name="company_description"
                                        value={UpdatedCompany.company_description}
                                        onChange={handleInputChangeCompany}
                                    ></textarea>
                                    <div className="text-[rgba(500,100,0,0.5)] italic pt-2">{lengthText} / {characterTextLength}</div>
                                    {
                                        alert.filter(input => input.input == "description" && input.state === true).map(message => (
                                            <span className="text-red-500">{message.message}</span>
                                        ))
                                    }
                                </div>
                                <div className="w-full">
                                    <div className="text-start flex justify-center items-center w-full">
                                        <hr className="w-12 xl:w-56 lg:w-32 2xl:w-96" />
                                        <label htmlFor="" className="text-white px-2">Agregar redes sociales</label>
                                        <hr className="w-12 xl:w-56 lg:w-32 2xl:w-96" />
                                    </div>
                                    <div className="w-full flex flex-wrap">


                                        {/* social networks */}
                                        {/* Facebook */}
                                        <div className="text-start w-full md:w-1/2 p-2">
                                            <label htmlFor="facebook" className="text-white">Usuario de Facebook</label>
                                            <input
                                                type="text"
                                                className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                                name="Facebook_link"
                                                value={UpdatedCompany.Facebook_link}
                                                onChange={handleInputChangeCompany}
                                            />
                                        </div>

                                        {/* instagram */}
                                        <div className="text-start w-full md:w-1/2 p-2">
                                            <label htmlFor="instagram" className="text-white">Usuario de Instagram</label>
                                            <input
                                                type="text"
                                                className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                                name="Instagram_link"
                                                value={UpdatedCompany.Instagram_link}
                                                onChange={handleInputChangeCompany}
                                            />
                                        </div>

                                        {/* Twitter */}
                                        <div className="text-start w-full md:w-1/2 p-2">
                                            <label htmlFor="facebook" className="text-white">Usuario de Twitter</label>
                                            <input
                                                type="text"
                                                className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                                name="Twitter_link"
                                                value={UpdatedCompany.Twitter_link}
                                                onChange={handleInputChangeCompany}
                                            />
                                            {/* {alert.filter(input => input.input == "twitter" && input.state === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))} */}
                                        </div>

                                        {/* linkedin */}
                                        <div className="text-start w-full md:w-1/2 p-2">
                                            <label htmlFor="instagram" className="text-white">Usuario de Linkedin</label>
                                            <input
                                                type="text"
                                                className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                                name="Linkedin_link"
                                                value={UpdatedCompany.Linkedin_link}
                                                onChange={handleInputChangeCompany}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-center w-full flex justify-center p-1 my-3">
                                        <button
                                            type="submit"
                                            className="w-full md:w-1/3 inline-block px-7 py-3 bg-Yellow text-white font-medium text-sm leading-snug uppercase rounded-lg shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out"
                                        >
                                            { !sendingData && <> Guardar cambios </> }
                                            { sendingData && <IconLoading /> }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            }
        </>
    )
}
export default EditEmpresa;
