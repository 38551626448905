import { useEffect, useState } from "react";
import IconLoading from "../iconLoading/IconLoading";
import Cookies from "universal-cookie";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { validateData } from "../validateData";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { to12Hours, to24Hours } from "../Utils/Utils";
import Skeleton from "react-loading-skeleton";

const FormPlaza = ({typeForm}) => {

    const editTypeOpportunity = useParams();

    const initialStateInput = {
        input: "",
        message: '',
        state: false
     }

    const MySwal = withReactContent(Swal);

    const cookies = new Cookies();
    const getSesion = cookies.get('Bearer');

    const [id, setId] = useState(0);

    const [dateModify, setDateModify] = useState(typeForm === "update" ? false : true);
    const [oldData, setOldData] = useState({
        title: "",
        typeof: "",
        montoMensual: "",
        // otherBenefits: "",
        // duration: "",
        dateInit: "",
        dateEnd: "",
        dateLimitToApply: "",
        schedules: "",
        modality: "",
        location: "",
        description: "",
    });

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const characterTextLength = 500;
    const [lengthText, setLengthText] = useState(0);
    const [alert, setAlert] = useState([initialStateInput]);
    const [showLocation, setShowLocation] = useState(false);
    const [infoPlaza, setInfoPlaza] = useState({
        title: "",
        typeOfPlace: "",
        typeOfContract: "",
        typeOfSchedule: "",
        salary: "",
        dateInit: "",
        dateEnd: "",
        dateLimitToApply: "",
        description: "",
        schedules: "",
        scheduleInit: "",
        scheduleEnd: "",
        location: "",
        // otherBenefits: "",
        modality: ""
    });

    const [sendingData, setSendingData] = useState(false);
    const [showSchedule, setShowSchedule] = useState(false);

    const handleInputPlaza = (e) => {
        if (e.target.name === "title") {
            if (e.target.value.length <= 40) {
                setInfoPlaza({
                    ...infoPlaza,
                    [e.target.name]: e.target.value
                });
            }
        } else if (e.target.name === "typeOfSchedule") {
            if (e.target.value === "1") {
                setShowSchedule(true);
            } else {
                setShowSchedule(false);
            }
            setInfoPlaza({
                ...infoPlaza,
                [e.target.name]: e.target.value
            });
        } else {
            setInfoPlaza({
                ...infoPlaza,
                [e.target.name]: e.target.value
            });
        }
    }

    const sendData = () => {
        const url = `${process.env.REACT_APP_API_URI}company/${typeForm}/project/${id}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        };

        let sendData = {
            application_deadline: infoPlaza.dateLimitToApply,
            description: infoPlaza.description,
            end_date: infoPlaza.dateEnd,
            id_type_contract: infoPlaza.typeOfContract,
            id_type_job_vacancy: infoPlaza.typeOfPlace,
            id_type_schedule: infoPlaza.typeOfSchedule,
            id_work_modality: infoPlaza.modality,
            job_title: infoPlaza.title,
            salary: infoPlaza.salary,
            location: infoPlaza.location,
            start_date: infoPlaza.dateInit,
            id_type_opportunities: 2,
        };

        if (showSchedule) {
            // Convertir a formato de 12 horas el horario
            let scheduleInit = to12Hours(infoPlaza.scheduleInit);
            let scheduleEnd = to12Hours(infoPlaza.scheduleEnd);

            sendData.schedules = `${scheduleInit} - ${scheduleEnd}`;
        }

        if (dateModify) {
            sendData.application_deadline = infoPlaza.dateLimitToApply;
            sendData.end_date = infoPlaza.dateEnd;
            sendData.start_date = infoPlaza.dateInit;
        } else {
            sendData.application_deadline = oldData.dateLimitToApply;
            sendData.end_date = oldData.dateEnd;
            sendData.start_date = oldData.dateInit;
        }

        console.log(sendData, "sendData");

        axios.post(url, sendData, config).then(response => {
            console.log(response);
            MySwal.fire({
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                navigate('/perfil-empresa');
            });
        }).catch(err => {
            console.log(err);
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 3500
             });
        })
    };

    const datosValidados = (e) => {
        e.preventDefault();
        let inputs = Object.entries(infoPlaza).map(([key, value]) => {
            return {
                name: key,
                value: value,
            };
        });

        console.log("🚀 ~ inputs ~ inputs:", inputs)

        if (dateModify == false) {
            inputs = inputs.filter(input => input.name !== "dateInit" && input.name !== "dateEnd" && input.name !== "dateLimitToApply");
        }

        if (showSchedule == false) {
            inputs = inputs.filter(input => input.name !== "scheduleInit" && input.name !== "scheduleEnd");
        }
    
        // Aquí quito schedules porque los campos que valido son scheduleInit y scheduleEnd
        inputs = inputs.filter(input => input.name !== "schedules");

        const datosValidados = validateData(inputs);
        
        console.log("🚀 ~ datosValidados ~ datosValidados:", datosValidados)

        setAlert(datosValidados);

        let totalValidaciones = datosValidados.filter(input => input.status !== false).map(() => { return false });

        console.log("🚀 ~ datosValidados ~ totalValidaciones:", totalValidaciones)

        if (totalValidaciones.length === 0) {
            sendData();
        } else {
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: 'Por favor, asegúrate de llenar todos los campos requeridos.',
                showConfirmButton: false,
                timer: 3000
            });
        }
    };

    const getInfoPasantia = async () => {

        setIsLoading(true);

        let urlListOpportunity = `${process.env.REACT_APP_API_URI}project/get/${editTypeOpportunity.id}?id_type_opportunities=2`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        };

        try {
            const resp = await axios.get(urlListOpportunity, config);
            const respInfoPlaza = resp.data;

            let scheduleInit = "";
            let scheduleEnd = "";

            try {
                scheduleInit = to24Hours(respInfoPlaza.schedules.split(" - ")[0]);
                scheduleEnd = to24Hours(respInfoPlaza.schedules.split(" - ")[1]);
            } catch (error) {
                scheduleInit = "";
                scheduleEnd = "";
            }

            const listDataNew = {
                title: respInfoPlaza.job_title,
                typeOfPlace: respInfoPlaza.id_type_job_vacancy,
                typeOfContract: respInfoPlaza.id_type_contract,
                typeOfSchedule: respInfoPlaza.id_type_schedule,
                salary: respInfoPlaza.salary,
                dateInit: respInfoPlaza.start_date,
                dateEnd: respInfoPlaza.end_date,
                dateLimitToApply: respInfoPlaza.application_deadline,
                description: respInfoPlaza.description,
                schedules: respInfoPlaza.schedules,
                scheduleInit: scheduleInit,
                scheduleEnd: scheduleEnd,
                location: respInfoPlaza.location,
                modality: respInfoPlaza.id_work_modality
            }

            if (respInfoPlaza.id_type_schedule == 1) {
                setShowSchedule(true);
            }

            setId(respInfoPlaza.id);

            setInfoPlaza(listDataNew);

            setOldData(listDataNew);

            setIsLoading(false);

        } catch (err) {
            console.error(err);
        };

    };

    useEffect(() => {
        if (typeForm === "update") {
            getInfoPasantia();
        } else if (typeForm === "create") {
            setId(getSesion.id);
        }
    }, []);

    return (
        <div className="w-full flex justify-center">
            <form className="w-full" onSubmit={datosValidados}>
                <div className="bg-bluecard-big p-5 rounded">
                    <div className="md:flex items-center md:justify-between">
                        <h1 className="text-Yellow font-bold text-3xl text-left mb-8 px-2">Requerimientos de la plaza</h1>
                        {typeForm === "update" && isLoading === false && <label className="relative inline-flex items-center cursor-pointer mb-8 ml-2">
                            <input type="checkbox" 
                                checked={dateModify}
                                onChange={() => setDateModify(!dateModify)}
                                className="sr-only peer"
                                name="emergency_contact"
                            />
                            <div className="w-11 h-6 peer-focus:outline-none rounded-full peer bg-gray-500 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-Yellow"></div>
                            <span className="ml-3 text-sm font-medium text-white">Modificar fechas</span>
                        </label>}
                    </div>
                    {dateModify && typeForm === "update" && <h1 className="text-Yellow font-bold text-sm text-left mb-8 px-2">¡Importante! <span className="text-white">Para modificar las fechas, la nueva fecha límite de aplicación tiene que ser igual o mayor a la actual y por ende las de inicio y de finalización tienen que ser mayor a la fecha límite de aplicación.</span></h1>}
                    <div className="flex flex-wrap">
                        <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                            <label htmlFor="typeOfPlace" className="text-white mb-8 lg:text-sm xl:text-base">
                                Tipo de plaza <span className="text-red-500">*</span>
                            </label>
                            {
                                isLoading 
                                ?
                                <Skeleton className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                                :
                                <select
                                    name="typeOfPlace"
                                    value={infoPlaza.typeOfPlace}
                                    onChange={handleInputPlaza}
                                    className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none">
                                    <option value={""} className="text-white">Selecciona el tipo</option>
                                    <option value={"1"} className="text-white">Fija</option>
                                    <option value={"2"} className="text-white">Temporal</option>
                                </select>
                            }
                            {
                                alert.filter(input => input.input == "typeOfPlace" && input.status === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))
                            }
                        </div>
                        <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                            <label htmlFor="title" className="text-white lg:text-sm xl:text-base flex justify-between">
                                <span className="text-red-500"><span className="text-white">Titulo del puesto </span>*</span><span className="text-Yellow"> {infoPlaza.title.length}/40</span>
                            </label>
                            {
                                isLoading
                                ?
                                <Skeleton 
                                className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                                :
                                <input
                                    type="text"
                                    className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                    name="title"
                                    value={infoPlaza.title}
                                    onChange={handleInputPlaza}
                                />
                            }
                            {
                                alert.filter(input => input.input == "title" && input.status === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))
                            }
                        </div>
                        <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                            <label htmlFor="typeOfContract" className="text-white lg:text-sm xl:text-base">
                                Tipo de contrato <span className="text-red-500">*</span>
                            </label>
                            {isLoading ? <Skeleton className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                            :
                            <select
                                name="typeOfContract"
                                value={infoPlaza.typeOfContract}
                                onChange={handleInputPlaza}
                                className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none">
                                <option value={""} className="text-white">Selecciona el tipo</option>
                                <option value={"1"} className="text-white">Tiempo completo</option>
                                <option value={"2"} className="text-white">Tiempo parcial</option>
                            </select>}
                            {
                                alert.filter(input => input.input == "typeOfContract" && input.status === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))
                            }
                        </div>

                        {/* {
                            infoPlaza.typeOfPlace === "2" &&
                            <>
                                <div className="mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2">
                                    <label htmlFor="montoMensual" className="text-white mb-8 lg:text-sm xl:text-base">
                                        Duración del contrato (En semanas/meses)
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control block w-full mt-2 px-4 py-3 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                        name="montoMensual"
                                        value={infoPlaza.montoMensual}
                                        onChange={handleInputPlaza}
                                    />
                                    {
                                        alert.filter(input => input.input == "montoMensual" && input.status === true).map(message => (
                                            <span className="text-red-500">{message.message}</span>
                                        ))
                                    }
                                </div>
                            </>
                        } */}

                        <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                            <label htmlFor="typeOfSchedule" className="text-white mb-8 lg:text-sm xl:text-base">
                                Tipo de horario <span className="text-red-500">*</span>
                            </label>
                            {isLoading ? <Skeleton className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                            :
                            <select
                                name="typeOfSchedule"
                                value={infoPlaza.typeOfSchedule}
                                onChange={handleInputPlaza}
                                className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none">
                                <option value={""} className="text-white">Selecciona el tipo</option>
                                <option value={"1"} className="text-white">Fijo</option>
                                <option value={"2"} className="text-white">Rotativo</option>
                            </select>}
                            {
                                alert.filter(input => input.input == "typeOfSchedule" && input.status === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))
                            }
                        </div>

                        <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                            <label htmlFor="modality" className="text-white mb-8 lg:text-sm xl:text-base">
                                Modalidad <span className="text-red-500">*</span>
                            </label>
                            {isLoading ? <Skeleton className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" /> 
                            :
                            <select
                                name="modality"
                                value={infoPlaza.modality}
                                onChange={handleInputPlaza}
                                className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none">
                                <option value={""} className="text-white">Selecciona el tipo</option>
                                <option value={"1"} className="text-white">Presencial</option>
                                <option value={"2"} className="text-white">Remoto</option>
                                {/* <option value={"3"} className="text-white">Híbrido</option> */}

                                {/* {
                                    listBudget.map((range) => {
                                        return <option value={range.id}>{range.range}</option>
                                    })
                                } */}

                            </select>}
                            {
                                alert.filter(input => input.input == "modality" && input.status === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))
                            }
                        </div>

                        {
                            showSchedule &&
                            <>
                                <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                                    <label htmlFor="schedules" className="text-white mb-8 lg:text-sm xl:text-base">
                                        Horario de trabajo <span className="text-red-500">*</span>
                                    </label>
                                    <div className="flex">
                                        <div className="w-full">
                                            {isLoading ? <Skeleton className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                                            :
                                            <input
                                                type="time"
                                                className="form-control block w-full px-4 py-2.5 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                                name="scheduleInit"
                                                value={infoPlaza.scheduleInit}
                                                onChange={handleInputPlaza}
                                            />}
                                            {
                                                alert.filter(input => input.input == "scheduleInit" && input.status === true).map(message => (
                                                    <span className="text-red-500">{message.message}</span>
                                                ))
                                            }
                                        </div>
                                        <div className="w-full">
                                            {isLoading ? <Skeleton className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                                            :
                                            <input
                                                type="time"
                                                className="form-control block w-full px-4 py-2.5 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                                name="scheduleEnd"
                                                value={infoPlaza.scheduleEnd}
                                                onChange={handleInputPlaza}
                                            />}
                                            {
                                                alert.filter(input => input.input == "scheduleEnd" && input.status === true).map(message => (
                                                    <span className="text-red-500">{message.message}</span>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                            <label htmlFor="location" className="text-white mb-8 lg:text-sm xl:text-base">
                                Ubicación <span className="text-red-500">*</span>
                            </label>
                            {isLoading ? <Skeleton className="form-control block w-full mt-2 px-4 py-3 text-xl text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                            :
                            <input
                                type="text"
                                className="form-control block w-full mt-2 px-4 py-3 text-xl text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                name="location"
                                value={infoPlaza.location}
                                onChange={handleInputPlaza}
                            />}
                            {
                                alert.filter(input => input.input == "location" && input.status === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))
                            }
                        </div>
                        <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                            <label htmlFor="salary" className="text-white mb-8 lg:text-sm xl:text-base">
                                Salario <span className="text-red-500">*</span>
                            </label>
                            {isLoading ? <Skeleton className="form-control block w-full mt-2 px-4 py-3 text-xl text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" /> 
                            :
                            <input
                                type="number"
                                className="form-control block w-full mt-2 px-4 py-3 text-xl text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                name="salary"
                                value={infoPlaza.salary}
                                onChange={handleInputPlaza}
                            />}
                            {
                                alert.filter(input => input.input == "salary" && input.status === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))
                            }
                        </div>
                        {/* <div className="mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2">
                            <label htmlFor="otherBenefits" className="text-white mb-8 lg:text-sm xl:text-base">
                                Beneficios adicionales
                            </label>
                            <input
                                type="text"
                                className="form-control block w-full mt-2 px-4 py-3 text-xl text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                name="otherBenefits"
                                value={infoPlaza.otherBenefits}
                                onChange={handleInputPlaza}
                            />
                            {
                                alert.filter(input => input.input == "otherBenefits" && input.status === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))
                            }
                        </div> */}
                        {
                            dateModify &&
                            <>
                                <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                                    <label htmlFor="dateInit" className="text-white mb-8 lg:text-sm xl:text-base">
                                        Fecha de inicio <span className="text-red-500">*</span>
                                    </label>
                                    {isLoading ? <Skeleton className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                                    :
                                    <input
                                        name="dateInit"
                                        type="date"
                                        value={infoPlaza.dateInit}
                                        onChange={handleInputPlaza}
                                        className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" 
                                    />}
                                    {
                                        alert.filter(input => input.input == "dateInit" && input.status === true).map(message => (
                                            <span className="text-red-500">{message.message}</span>
                                        ))
                                    }
                                </div>
                                <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                                    <label htmlFor="dateEnd" className="text-white mb-8 lg:text-sm xl:text-base">
                                        Fecha de finalización <span className="text-red-500">*</span>
                                    </label>
                                    {isLoading ? <Skeleton className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                                    :
                                    <input
                                        name="dateEnd"
                                        type="date"
                                        value={infoPlaza.dateEnd}
                                        onChange={handleInputPlaza}
                                        className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" 
                                    />}
                                    {
                                        alert.filter(input => input.input == "dateEnd" && input.status === true).map(message => (
                                            <span className="text-red-500">{message.message}</span>
                                        ))
                                    }
                                </div>
                                <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                                    <label htmlFor="dateLimitToApply" className="text-white mb-8 lg:text-sm xl:text-base">
                                        Fecha limite de aplicación <span className="text-red-500">*</span>
                                    </label>
                                    {isLoading ? <Skeleton className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                                    :
                                    <input
                                        name="dateLimitToApply"
                                        type="date"
                                        value={infoPlaza.dateLimitToApply}
                                        onChange={handleInputPlaza}
                                        className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" 
                                    />}
                                    {
                                        alert.filter(input => input.input == "dateLimitToApply" && input.status === true).map(message => (
                                            <span className="text-red-500">{message.message}</span>
                                        ))
                                    }
                                </div>
                            </>
                        }
                        <div className={`mb-6 text-start btn-editpro w-full px-2`}>
                            <label htmlFor="description" className="text-white mb-8 lg:text-sm xl:text-base">
                                Descripción del puesto <span className="text-red-500">*</span>
                            </label>
                            <div className="relative">
                                <Skeleton className={`${isLoading ? "block" : "hidden"} absolute w-full h-full`} />
                                <textarea
                                    className={`${isLoading ? "invisible" : "visible"} form-control block w-full mt-2 px-4 py-3 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none`}
                                    rows={4}
                                    placeholder="Descripción"
                                    name="description"
                                    value={infoPlaza.description}
                                    onChange={
                                        (e) => {
                                            if (e.target.value.length <= characterTextLength) {
                                                setLengthText(e.target.value.length);
                                                handleInputPlaza(e);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="text-[rgba(500,100,0,0.5)] italic">{infoPlaza.description.length} / {characterTextLength}</div>
                            {
                                alert.filter(input => input.input == "description" && input.status === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))
                            }
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-center text-justify md:text-left">
                        <div className="w-full md:w-1/3 flex items-center">

                            <div className="relative w-full">
                                <Skeleton className={`${isLoading ? "block" : "hidden"} absolute w-full h-full`} />
                                <button
                                    type="submit"
                                    className={`${isLoading ? "invisible" : "visible"} inline-block px-7 py-5 bg-Yellow text-white font-medium lg:text-sm xl:text-base leading-snug rounded shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out w-full text-center`}>

                                    {!sendingData ?
                                        <>
                                            {(typeForm == "update") && <>Actualizar plaza</> }
                                            {(typeForm == "create") && <>Publicar plaza</> }
                                        </>
                                        :
                                        <IconLoading />
                                    }
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default FormPlaza;