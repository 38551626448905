
import { ChangeEvent, useEffect, useState } from "react";
import { ValidateEmail } from "../../function/Login";
import { InitialStateExperience } from "../../Interfaces/experience";
import { alertLogin } from "../../Interfaces/Login";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import axios from 'axios';
import { useNavigate, useParams, Link } from "react-router-dom";
import IconLoading from "../iconLoading/IconLoading";
import Cookies from "universal-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface Props {
    animation: string;
    idFreelance: number;
 }

// Types
type handleInputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>


// Initial State
const initialStateExperience = {
    nombre_empresa: "",
    cargo: "",
    descripcion_puesto: "",
    fecha_inicio: "",
    fecha_fin: "",
    user_id: 0,
    currentJob: 0
};

const initialStateInput = {
    input: "",
    message: '',
    state: false
};

const ExperienceFormal = ({ animation, idFreelance }: Props) => {

    const [Experience, setExperience] = useState<InitialStateExperience>(initialStateExperience);

    const [alert, setAlert] = useState<alertLogin[]>([initialStateInput]);

    const [idUser, setIdUser] = useState<number>(0);

    const MySwal = withReactContent(Swal);

    const navigate = useNavigate();

    const params = useParams();

    const [sendingData, setSendingData] = useState(false);

    // Cookies
    const cookies = new Cookies();

    const getSesion = cookies.get("Bearer"); 

    const [characterTextLength, setCharacterTextLength] = useState(250);

    const [lengthText, setLengthText] = useState(0);

    const [paramURL, setParamURL] = useState<Number>()

    useEffect(() => {
        
        ListInfoWorkExperience();

        setParamURL(Number(params.id))

    }, []);

    // List info Experience Freelancer
    const ListInfoWorkExperience = async () => {
        if (Number(params.id) != 0) {

            const idExperience = params.id;

            const urlCountry = `${process.env.REACT_APP_API_URI}freelancer/get-work-experience/${idExperience}`;

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ApiToken': `Bearer ${getSesion.token}`
                }
            };
            try {

                const resp = await axios.get(urlCountry, config);

                console.log("resp ==> ?", resp);
                // actual_work

                if (resp.data.experiencia.actual_work == 1) {

                    const checkboxCurrentJob = document.getElementById('currentJob',) as HTMLInputElement | null;

                    if (checkboxCurrentJob != null) {
                        checkboxCurrentJob.checked = true;
                    }

                }
                setExperience({
                    nombre_empresa: resp.data.experiencia.nombre_empresa,
                    cargo: resp.data.experiencia.cargo,
                    descripcion_puesto: resp.data.experiencia.descripcion_puesto,
                    fecha_inicio: resp.data.experiencia.fecha_inicio,
                    fecha_fin: resp.data.experiencia.fecha_fin,
                    user_id: 0,
                    currentJob: resp.data.experiencia.actual_work
                });
                if(resp.data.experiencia.descripcion_puesto != null){
                    setLengthText((resp.data.experiencia.descripcion_puesto).length);
                }

            } catch (err) {
                console.log(err);
            }

        }
    }

    const handleInputChange = ({ target: { name, value, id } }: handleInputChange) => {
        // console.log(name, value);

        if (name == 'descripcion_puesto') {
            setLengthText(value.length);
            if(value.length >= 2000){
                setLengthText(2000);
                setExperience({...Experience, [name]:value.substring(0,2000)});
            } else {
                setExperience({ ...Experience, [name]: value });
            }
        } else {
            if (name == "currentJob") {

                const checkboxCurrentJob = document.getElementById(id,) as HTMLInputElement | null;
    
                if (checkboxCurrentJob?.checked) {
                    setExperience({ ...Experience, [name]: 1 });
                } else {
                    setExperience({ ...Experience, [name]: 2 });
                };
    
            } else {
                setExperience({ ...Experience, [name]: value });
            }
        }
    };

    const handleWorkExperience = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        
        Experience.user_id = idFreelance;

        let verifyValues;
        if (Experience.currentJob == 1) {

            verifyValues = [
                {
                    name: "name",
                    value: Experience.nombre_empresa
                },
                {
                    name: "cargo",
                    value: Experience.cargo
                },
                {
                    name: "description",
                    value: Experience.descripcion_puesto
                },
                {
                    name: "dateInit",
                    value: Experience.fecha_inicio
                }
            ];
        } else {
            verifyValues = [
                {
                    name: "name",
                    value: Experience.nombre_empresa
                },
                {
                    name: "cargo",
                    value: Experience.cargo
                },
                {
                    name: "description",
                    value: Experience.descripcion_puesto
                },
                {
                    name: "dateInit",
                    value: Experience.fecha_inicio
                },
                {
                    name: "dateEnd",
                    value: Experience.fecha_fin
                }
            ];
        }

        const validateInputs = ValidateEmail(verifyValues);
      
        const state = validateInputs.filter(input => input.state === false).map((s) => { return false });
      
        setAlert(validateInputs);

        if (Experience.currentJob == 1) {
            if (state.length >= 4) sendData();

        } else {
            if (state.length >= 5) sendData();
        };
    };

    const sendData = () => {

        setSendingData(true);

        if (Number(params.id) == 0) {
            const url = `${process.env.REACT_APP_API_URI}freelancer/register/work-experience`;

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'ApiToken': `Bearer ${getSesion.token}`
                }
            }

            console.log("Experience ==>", Experience);
            axios.post(url, Experience, config)
                .then(response =>{

                    MySwal.fire({
                        position: 'center',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 3500
                    }).then(() => {
                        navigate('/perfil-freelancer');
                    });

                    setExperience(initialStateExperience);

                    setSendingData(false);
                })
                .catch(err =>{
                    console.log(err);
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        title: err.response.data.message,
                        showConfirmButton: false,
                        timer: 4500
                    });
                    setSendingData(false);
                })
        } else {

            const url = `${process.env.REACT_APP_API_URI}freelancer/update/work-experience/${params.id}`;

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ApiToken': `Bearer ${getSesion.token}`
                }
            };

            console.log("Experience get ==>", Experience);
            axios.put(url, Experience, config)
                .then(response => {
            
                    MySwal.fire({
                        position: 'center',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 3500
                    }).then(() => {
                        navigate('/perfil-freelancer');
                    });
            
                    setExperience(initialStateExperience);
                })
                .catch(err => {
            
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        title: err.response.data.message,
                        showConfirmButton: false,
                        timer: 3500
                    });
                })
        }
    }

    return (
        <>
            <div className="w-full md:w-95% flex justify-center">
                <form className="w-full md:w-8/12" onSubmit={handleWorkExperience}>
                    <div className="lg:columns-2">
                        <div className="mb-6 text-start">
                            <label htmlFor="nombreCompleto" className="text-white mb-8">Ingresa el nombre de la empresa </label>
                            <input
                                type="text"
                                className="form-control block w-full mt-2 px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:outline-none"
                                value={Experience.nombre_empresa}
                                name="nombre_empresa"
                                id="2"
                                onChange={
                                    (e) => {
                                        if ((e.target.value).length <= 100) {
                                            handleInputChange(e)
                                        } else {
                                            setExperience({...Experience, nombre_empresa:e.target.value.substring(0,100)});
                                        }
                                    }
                                }
                            />
                            <div className="text-[rgba(500,100,0,0.5)] italic">{Experience.nombre_empresa.length} / 100</div>
                            {alert.filter(input => input.input == "name" && input.state === true).map(message => (
                                <span className="text-red-500 text-start">{message.message}</span>
                            ))}
                        </div>
                        <div className="mb-6 text-start">
                            <label htmlFor="cargo" className="text-white mb-8">Cargo</label>
                            <input
                                type="text"
                                className="form-control block w-full mt-2 px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:outline-none"
                                name="cargo"
                                value={Experience.cargo}
                                onChange={handleInputChange}
                            />
                            {alert.filter(input => input.input == "cargo" && input.state === true).map(message => (
                                <span className="text-red-500 text-start">{message.message}</span>
                            ))}
                        </div>
                    </div>
                    <div className="lg:columns-1">
                        <div className="mb-6 text-start">
                            <label htmlFor="address" className="text-white mb-8">Describe brevemente tu puesto de trabajo</label>
                            <textarea className="form-control block w-full mt-2 px-4 py-2 text-md font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:outline-none"
                                name="descripcion_puesto"
                                id="descripcion_puesto"
                                value={Experience.descripcion_puesto}
                                onChange={
                                    (e) => {
                                        if ((e.target.value).length <= 250) {
                                            handleInputChange(e)
                                        }
                                    }
                                }
                                placeholder="Tú mensaje..."
                                rows={5}
                            >
                            </textarea>
                            <div className="text-[rgba(500,100,0,0.5)] italic">{lengthText} / {characterTextLength}</div>
                            {alert.filter(input => input.input == "description" && input.state === true).map(message => (
                                <span className="text-red-500 text-start">{message.message}</span>
                            ))}
                        </div>
                    </div>
                    <div className="lg:columns-2 flex flex-wrap items-center">
                        <div className="mb-6 text-start w-full md:w-47% md:mx-1">
                            <label htmlFor="dateInit" className="text-white mb-8">Desde (Fecha de inicio)</label>
                            <input
                                // type="date"
                                type="month"
                                className="form-control block w-full mt-2 px-4 py-2 text-lg text-holder-gray font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:outline-none"
                                name="fecha_inicio"
                                value={Experience.fecha_inicio}
                                id="1" //do not remove ID, it is important to validate
                                onChange={handleInputChange}
                            />
                            {alert.filter(input => input.input == "dateInit" && input.state === true).map(message => (
                                <span className="text-red-500 text-start">{message.message}</span>
                            ))}
                        </div>
                        { (Experience.currentJob == 2 || Experience.currentJob == 0 )
                            ?
                            <div className="mb-6 text-start w-full md:w-47% md:mx-1">
                                <label htmlFor="dateBirth" className="text-white mb-8">Hasta (Fecha de finalización)</label>
                                <input
                                    // type="date"
                                    type="month"
                                    className="form-control block w-full mt-2 px-4 py-2 text-lg text-holder-gray font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:outline-none"
                                    name="fecha_fin"
                                    id="1" //do not remove ID, it is important to validate
                                    value={Experience.fecha_fin}
                                    onChange={handleInputChange}
                                />
                                {alert.filter(input => input.input == "dateEnd" && input.state === true).map(message => (
                                    <span className="text-red-500 text-start">{message.message}</span>
                                ))}
                            </div>
                            :
                            <div className="mb-6 text-start w-full md:w-47% md:mx-1">
                                <p className="text-gray-500 text-sm italic">
                                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                     Si quieres colocar fecha de finalización desmarca chamba actual
                                </p>
                            </div>
                        }
                    </div>
                    <div className="columns-1 py-5 px-2 flex justify-start items-center">
                        <input
                            className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none accent-pink-500  checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white checked:after:w-1" 
                            type="checkbox"
                            id="currentJob"
                            name="currentJob"
                            onChange={handleInputChange}
                        />
                        <span className="text-white text-xs lg:text-xs xl:text-base">Chamba actual</span>
                    </div>
                    <div className="columns-1 flex justify-center">
                        <div className="text-center py-12 lg:text-left w-full md:w-1/2">
                            <button
                                type="submit"
                                className="inline-block px-7 py-3 bg-Yellow text-white font-medium text-sm leading-snug rounded-lg shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out w-full"
                            >
                                {!sendingData &&
                                    paramURL == 0 ? "Registrar experiencia" : "Actualizar experiencia"
                                }

                                {sendingData &&
                                    <IconLoading />
                                }
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default ExperienceFormal;