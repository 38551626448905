import { useEffect, useState } from "react";
import PublishProject from "../../views/PublishProject";
import { Link } from "react-router-dom";

import imagenesEditProject from "../../assets/imgEditProject/imgEditProject";
import CreateProjectComponent from "./createProject";
import FormPasantia from "../FormsProyects/FormPasantia";
import FormPlaza from "../FormsProyects/FormPlaza";
import FormProfesionalServices from "../FormsProyects/FormProfesionalServices";
import axios from "axios";
import Cookies from "universal-cookie";


const SelectProyecto = () => {

    const typeForm = "create";

    const [selectProject, setselectProject] = useState({ id: 0 });

    const [typeOpportunity, setTypeOpportunity] = useState([]);

    const cookies = new Cookies();

    const getSesion = cookies.get('Bearer');

    const handleSelectedProject = (event: any) => {
        const projectId = event.target.value;
        setselectProject({ id: projectId });
    };
    

    
    async function getTypeOportunity() {
  
        const url = `${process.env.REACT_APP_API_URI}type-opportunity/filter`;

        console.log(url, "url")
  
        let config = {
           headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'ApiToken': `Bearer ${getSesion.token}`
           }
        };
        try {
  
           const resp = await axios.get(url, config);

           setTypeOpportunity(resp.data.data);
  
        } catch (err) {

           console.log(err)

        };
     };

    useEffect(() => {
        getTypeOportunity();
    }, []);

    // const Oportu = () => {
    //     const url = `${process.env.REACT_APP_API_URI}company/create/project/${getSesion.id}`;
  
    //     let config = {
    //        // multipart/form-data
    //        headers: {
    //           'Content-Type': 'application/json',
    //           'Accept': 'application/json',
    //           'ApiToken': `Bearer ${getSesion.token}`
    //        }
    //     };
        
    //     const formulario = {
    //         id_type_opportunities:4
    //     };

    //     axios.post(url, formulario , config)
    //        .then(response => {
    //          console.log(response);
    //        })
    //        .catch(err => {
    //           console.log(err);
    //        })
    //  };

    return (

        <div className="bg-turquoise">
            <section className="block -mt-px md:flex md:flex-wrap">
                <div className="md:w-full lg:w-5/5 flex flex-wrap justify-center pb-5 lg:py-5 md:pt-4 lg-pt-0">
                    <div className="w-full sm:w-5% mt-4 md:mt-1 py-1 md:pl-1">
                        <Link to="" onClick={() => window.history.go(-1)}>
                            <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                        </Link>
                    </div>

                    <div className="w-full md:w-95% flex justify-center">
                        <div className="w-11/12">
                            <div className="flex justify-start pb-0 lg:pb-4 py-4">
                                <h1 className="text-Yellow font-bold text-3xl w-full">¡Publica tu oportunidad fácilmente!</h1>
                            </div>

                            <div className="lg:columns-1 relative mb-40 md:mb-20">
                                <div className="mb-6 text-start">
                                    
                                    <label htmlFor="nombreCompleto" className="text-white">
                                        ¡Seleccione tipo de oportunidad!
                                    </label>
                                    
                                    <div className="flex flex-wrap mt-4 absolute w-full">
                                        <div className="w-full md:w-1/1 mb-2">
                                            <select
                                                onChange={handleSelectedProject}
                                                name="s_types_id"
                                                className="bg-Light-Blue form-control block w-full px-4 py-3 text-xl font-normal text-sky-400 bg-clip-padding border border-solid border-transparent rounded transition ease-in-out md:m-0 focus:border-Yellow focus:outline-none focus:text-white"  >
                                                <option className="text-white" value="0" selected>...</option>
                                                {
                                                    typeOpportunity.map((item: any) => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </select>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                {(selectProject.id == 1) && <FormPasantia typeForm={typeForm} />}
                                {(selectProject.id == 2) && <FormPlaza typeForm={typeForm} />}
                                {(selectProject.id == 3) && <FormProfesionalServices typeForm={typeForm} />}
                                {(selectProject.id == 4) && <PublishProject typeForm={typeForm} />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}


export default SelectProyecto;