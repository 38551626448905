import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage, faX } from "@fortawesome/free-solid-svg-icons";
import Cookies from "universal-cookie";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from "axios";
import mapboxgl, { FullscreenControl, GeolocateControl, Marker, NavigationControl } from 'mapbox-gl';
import { alertLogin } from "../Interfaces/Login";
import { Employer } from "../Interfaces/Employer";
import { InitialStateCountry, InitialStateDepartment } from "../Interfaces/departmentsAndmuniti";
import ImgProfileSkeleton from "../components/Skeleton/ProfileCompany/imgProfileSkeleton";
import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import imagenesEditPerfil from "../assets/imgEditPerfil/imagenesEditPerfil.js";
import { ValidateForm } from "../function/validateForm";
import imgDefault from '../assets/imgEditPerfil/user.png';
import IconLoading from "../components/iconLoading/IconLoading";
import Input from "react-select/dist/declarations/src/components/Input";

mapboxgl.accessToken = 'pk.eyJ1IjoiZ2Vvcmdlc3YiLCJhIjoiY2s5YTJjaGV0MDB0NzNsbXFuM3EyeThnYSJ9.F7uxDu4RAFlHz61e2cxNjw';

type handleInputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>

const initialUpadateEmployer = {
    activity_id: 0,
    about_me: '',
    complete_profile_status: 0,
    country_id: 0,
    cover_photo: '',
    email: '',
    id: 0,
    languages: [],
    names: '',
    lastnames: '',
    phone: '',
    phone_alternative: '',
    photo: '',
    facebook_link: '',
    instagram_link: '',
    twitter_link: '',
    linkedin_link: '',
    status: 0,
    type_id: 0,
    user_id: 0,
    username: '',
    // otherLanguage: ""
}

const initialStateInput = {
    input: "",
    message: '',
    state: false
}

const initialLatLng = {
    lng: 0,
    lat: 0
}

const initialStateCountry = {
    code: 0,
    id: 0,
    name: ""
}

const initialStateDepartments = {
    code: 0,
    id: 0,
    name: ""
}

const initialStateEconomicActivityDefault = {
    id: 0,
    name: ""
}

const initialStateLanguages = {
    id: 0,
    name: ""
}

let checkedState: any = [];

const EditProfileEmployer = () => {

    const [employer, setEmployer] = useState<Employer>(initialUpadateEmployer);

    const [loadImgProfile, setloadImgProfile] = useState(true);

    const [closeImg, setCloseImg] = useState(false);

    const [MostrarImg, setMostrarImg] = useState("second");

    const [alert, setAlert] = useState<alertLogin[]>([initialStateInput]);

    const [UbicacionMap, setUbicacionMap] = useState(initialLatLng);

    const [sendingData, setSendingData] = useState(false);

    const [Countries, setCountries] = useState<InitialStateCountry[]>([initialStateCountry]);

    const [departments, setDepartments] = useState<InitialStateDepartment[]>([initialStateDepartments]);

    const [phone, setPhone] = React.useState<string>("");

    const [inputPhone, setInputPhone] = useState(false);

    const [imgOld, setImgOld] = useState(imgDefault);

    const [initialStateEconomicActivity, setInitialStateEconomicActivity] = useState([initialStateEconomicActivityDefault]);

    const [stateProfileInput, setStateProfileInput] = useState('');

    const mapContainer = useRef<any>();

    const map = useRef<any>();

    const [lng, setLng] = useState(-88.9426);

    const [lat, setLat] = useState(13.8767);

    const [zoom, setZoom] = useState(9);

    const [IsLoading, setIsLoading] = useState(true);

    const [Languages, setLanguages] = useState([initialStateLanguages]);

    const [ExistOtherLanguage, SetExistOtherLanguage] = useState(false);

    const [CheckboxOtherLanguageIsChecked, setCheckboxOtherLanguageIsChecked] = useState(true);

    const [habilitarOtherLanguage, setHabilitarOtherLanguages] = useState(false);
    

    const MySwal = withReactContent(Swal);

    const cookies = new Cookies();

    const getSesion = cookies.get('Bearer');

    const navigate = useNavigate();

    let fileRefProfile = null;

    useEffect(() => {
        getInfoEmployer();
        getLanguages();
    }, []);

    async function getInfoEmployer() {

        const idUser = cookies.get('Bearer');
        // console.log('getSesion',idUser.id);

        const urlEmployer = `${process.env.REACT_APP_API_URI}employer/profile`
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        };
        try {
            const resp = await axios.get(urlEmployer, config);
            console.log(resp.data);

            let imgCompany = "";

            if (resp.data.photo == null || resp.data.photo == "") {
                imgCompany = imgDefault;
            } else {
                imgCompany = `${process.env.REACT_APP_PHOTO_URI}company-image/${resp.data.photo}`;
            }
            setMostrarImg(imgCompany);
            setImgOld(imgCompany);
            setTimeout(() => {
                setloadImgProfile(false);
            }, 1000);


            // console.log("resp.data.social_media_links", resp.data.social_media_links);

            console.log("PHONE:", resp.data.phone);
            console.log("secondary_phone:", resp.data.secondary_phone);
            if (resp.data.social_media_links == null) {
                resp.data.social_media_links = '';
            }
            let lang = [];
            if (resp.data.languages == null) {
                lang = [];
            } else {
                lang = resp.data.languages;
            }
            let listInfo = {
                activity_id: resp.data.activity_id,
                about_me: resp.data.about_me,
                complete_profile_status: resp.data.complete_profile_status,
                country_id: resp.data.country_id,
                cover_photo: resp.data.photo,
                email: resp.data.email,
                id: resp.data.id,
                languages: lang,
                names: resp.data.names,
                lastnames: resp.data.lastnames,
                phone: resp.data.phone,
                phone_alternative: resp.data.phone_alternative,
                photo: resp.data.photo,
                facebook_link: resp.data.social_media_links.facebook_link,
                instagram_link: resp.data.social_media_links.instagram_link,
                twitter_link: resp.data.social_media_links.twitter_link,
                linkedin_link: resp.data.social_media_links.linkedin_link,
                status: resp.data.status,
                type_id: resp.data.type_id,
                user_id: resp.data.user_id,
                username: resp.data.username
            };

            switch (resp.data.complete_profile_status) {
                case 1:
                    setStateProfileInput('En validación');
                    break;
                case 2:
                    setStateProfileInput('Incompleto');
                    break;
                case 3:
                    setStateProfileInput('Verificado');
                    break;
                case 4:
                    setStateProfileInput('Rechazado');
                    break;

                default:
                    setStateProfileInput('Perfil Empresa');
                    break;
            }
            console.log(listInfo);

            console.log("ListInfo", listInfo);

            setEmployer(listInfo);
            if (resp.data.country_id == '3') {
                console.log("AQUI");
                setInputPhone(true);
                console.log(inputPhone);
            }

            setIsLoading(false);
        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    };

    const resetInput = (e: React.MouseEvent<HTMLElement>) => {
        // employer.photo = '';
    }

    const uploadProfile = (e: { target: { files: any; }; }) => {
        let files = e.target.files;
        let image = files[0];
        fileRefProfile = image;
        let imageUrl = URL.createObjectURL(image);
        employer.photo = fileRefProfile
        setloadImgProfile(true);
        setMostrarImg(URL.createObjectURL(image));
        setTimeout(() => {
            setCloseImg(true);
            setloadImgProfile(false);
        }, 1000);
    }

    const handleInputChangeEmployer = ({ target: { name, value, id, } }: handleInputChange) => {

        //Input Mask
        if (name == 'phone' || name == 'secondary_phone') {
            setPhone(value);
        }
        if (name == 'country_id' && value == '3') {
            setInputPhone(true);
        }
        else {
            setInputPhone(false);
        }
        console.log(name, value);

        setEmployer({ ...employer, [name]: value });

    };


    const handleUpdatedInfoValidate = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        employer.id = getSesion.id;

        let verifyValues = [
            { typeValidate: "str-r", name: "names", value: employer.names },
            { typeValidate: "str-r", name: "lastnames", value: employer.lastnames },
            { typeValidate: "str-r", name: "phone", value: employer.phone },
            { typeValidate: "int-r", name: "country_id", value: employer.country_id },
            { typeValidate: "str-r", name: "about_me", value: employer.about_me },
            { typeValidate: "int-r", name: "activity_id", value: employer.activity_id },
            { typeValidate: "int-r", name: "languages", value: employer.languages.length }
        ];

        console.log('verifyValues', verifyValues);

        const validateInputs = ValidateForm(verifyValues);

        setAlert(validateInputs);

        console.log(validateInputs);

        const state = validateInputs.filter(input => input.state === false).map((s) => { return false });

        console.log(state);

        if ((employer.facebook_link == undefined || employer.facebook_link == "") && (employer.instagram_link == undefined || employer.instagram_link == "") && (employer.twitter_link == undefined || employer.twitter_link == "") && (employer.linkedin_link == undefined || employer.linkedin_link == "")) {
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: "Agregar al menos una red social",
                showConfirmButton: false,
                timer: 5500
            });
        } else {

            // employer.latitude = UbicacionMap.lat;
            // employer.longitude = UbicacionMap.lng;

            if (state.length >= 6) sendData();
            // sendData();

        }
        // if (employer.photo == "") {
        //     // delete employer.photo;
        //     delete employer['photo'];
        // };
    };

    const reverseImg = () => {
        setloadImgProfile(true);
        // console.log(imgOld);
        setMostrarImg(imgOld);
        setCloseImg(false);
        setTimeout(() => {
            setloadImgProfile(false);
        }, 1000);
    }

    function sendData() {

        // if(CheckboxOtherLanguageIsChecked == false) {
        //     employer.otherLanguage = "";
        // }

        setSendingData(true);

        const url = `${process.env.REACT_APP_API_URI}employer/complete-profile`;

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        }

        
        employer.languages = checkedState;
        
        console.log('Data form', employer);
        axios.post(url, employer, config)
            .then(response => {

                if (response.data.Error != "El formato del archivo no es permitido solo se permiten archivos .jpg") {
                    MySwal.fire({
                        position: 'center',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 3500
                    });

                    setDepartments([initialStateDepartments]);
                    setEmployer(initialUpadateEmployer);

                    setSendingData(false);

                    setTimeout(() => {
                        navigate('/perfil-empleador');
                    }, 4000);

                } else {
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 3500
                    });
                    setSendingData(false);
                }
            })
            .catch(err => {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 4500
                });
                setSendingData(false);
            })
    };

    const handleInputChangelanguages = ({ target: { name, value, id, } }: handleInputChange) => {

        const checkbox = document.getElementById(id,) as HTMLInputElement | null;

        if (checkbox?.id == "other") {
            if (!checkbox?.checked) {
                setCheckboxOtherLanguageIsChecked(false);
            } else {
                setCheckboxOtherLanguageIsChecked(true);
            }
        }

        if (checkbox?.id == "language10") {
            if (!checkbox?.checked) {
                setCheckboxOtherLanguageIsChecked(false);
            } else {
                setCheckboxOtherLanguageIsChecked(true);
            }
        }

        if (checkbox?.checked) {
            console.log(value);
            checkedState.push(value);
            console.log(checkedState)
        } else {
            checkedState.map((infoArray: string, index: number) => {
                console.log(value);
                if (infoArray === value) {
                    checkedState.splice(index, 1);
                }
            });
            // console.log(checkedState)
        }
    };

    async function getLanguages() {
        const urlCountry = `${process.env.REACT_APP_API_URI}freelancer/get/languages`;
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        };
        try {

            const resp = await axios.get(urlCountry, config);
            setLanguages(resp.data.log);
            console.log(resp.data);

        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <div className="bg-turquoise -mt-5">
                <section className="block md:flex md:flex-wrap bg-gradient-to-b from-Light-purple via-turquoise to-turquoise">
                    <div className="hidden lg:flex w-1/6 py-14 bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">

                        <img className="py-10 opacity-50 h-1/2 lg:h-2/6 xl:h-1/2" src={imagenesEditPerfil.logo_blanco_mitad} alt="" />
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/4 pt-4 btn-file">
                        <div className="w-full sm:w-15%">
                            <Link to="" onClick={() => window.history.go(-1)}>
                                <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                            </Link>
                        </div>
                        <div className="relative mb-2 flex justify-center">
                            {
                                closeImg &&
                                <button className='flex items-center justify-center text-red-600 text-xs px-1.5 py-1 bg-white absolute left-24 xl:left-56 z-50 rounded-full font-bold sm:w-auto' onClick={reverseImg}>
                                    <FontAwesomeIcon icon={faX} />
                                </button>
                            }
                            {
                                loadImgProfile
                                    ?
                                    <ImgProfileSkeleton />
                                    :
                                    <img className="w-48 relative h-48 border-8 rounded-full object-cover align-middle border-Yellow flex justify-center" src={MostrarImg} alt="" />
                            }
                        </div>
                        <div className="flex justify-center">
                            <input
                                type="file"
                                className="bg-sky-700 hover:bg-sky-600 text-white text-xs rounded-full py-2 px-2 m-1"
                                onClick={resetInput}
                                value={''}
                                onChange={uploadProfile}
                            />
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 lg:w-7/12 flex flex-wrap justify-center pb-5 lg:py-5">
                        <h2 className="w-full text-Yellow text-2xl md:text-3xl text-center font-semibold py-2 xl:py-3 mb-2">Editar Perfil</h2>
                        <form className="w-90% lg:w-3/4 flex flex-wrap" onSubmit={handleUpdatedInfoValidate}>

                            {/* Name */}
                            <div className="text-start w-full lg:w-1/2 p-1 my-3">
                                <label htmlFor="nombreCompleto" className="text-white">Nombre</label>
                                <input
                                    type="text"
                                    className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                    name="names"
                                    value={employer.names}
                                    onChange={handleInputChangeEmployer}
                                />
                                {
                                    alert.filter(input => input.input == "names" && input.state === true).map(message => (
                                        <span className="text-red-500">{message.message}</span>
                                    ))
                                }
                            </div>

                            {/* Name */}
                            <div className="text-start w-full lg:w-1/2 p-1 my-3">
                                <label htmlFor="nombreCompleto" className="text-white">Apellidos</label>
                                <input
                                    type="text"
                                    className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                    name="lastnames"
                                    value={employer.lastnames}
                                    onChange={handleInputChangeEmployer}
                                />
                                {
                                    alert.filter(input => input.input == "lastnames" && input.state === true).map(message => (
                                        <span className="text-red-500">{message.message}</span>
                                    ))
                                }
                            </div>

                            {/* Actividad Economic */}
                            <div className="text-start w-full lg:w-1/2 p-1 my-3">
                                <label htmlFor="nickname" className="text-white">Actividad económica</label>
                                {/* <input
                                    type="text"
                                    className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                    name="economic_activity"
                                    
                                /> */}
                                <select
                                    name="activity_id"
                                    className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                    value={employer.activity_id}
                                    onChange={handleInputChangeEmployer}
                                >
                                    <option value="" selected> Actividad económica</option>
                                    {
                                        initialStateEconomicActivity.map((Activity) => {
                                            return <option value={Activity.id}>{Activity.name}</option>
                                        })
                                    }
                                </select>

                                {
                                    alert.filter(input => input.input == "activity_id" && input.state === true).map(message => (
                                        <span className="text-red-500">{message.message}</span>
                                    ))
                                }
                            </div>



                            {/* Estado */}
                            <div className="text-start w-full lg:w-1/2 p-1 my-3">
                                <label htmlFor="sex" className="text-white">Estado del perfil</label>
                                {/*  */}
                                <input
                                    type="text"
                                    className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                    name="names"
                                    value={stateProfileInput}
                                    disabled
                                />
                                {/* <select
                                    name="states_id"
                                    className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"  
                                    value={employer.states_id}
                                    onChange={handleInputChangeEmployer}
                                    >

                                    <option className="text-white" selected disabled>En validación</option>
                                    
                                </select> */}
                                {
                                    alert.filter(input => input.input == "stateProfile" && input.state === true).map(message => (
                                        <span className="text-red-500">{message.message}</span>
                                    ))
                                }

                            </div>
                            {/* Country  */}
                            <div className="mb-6 text-start w-full lg:w-1/2 p-1 my-3">
                                <label htmlFor="sex" className="text-white">Selecciona tu páis</label>
                                <select
                                    name="country_id"
                                    className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                    value={employer.country_id}
                                    onChange={handleInputChangeEmployer}
                                >
                                    <option value="" selected> Selecciona tu páis </option>
                                    {
                                        Countries.map((Countries) => {
                                            return <option value={Countries.id}>{Countries.name}</option>
                                        })
                                    }
                                </select>
                                {
                                    alert.filter(input => input.input == "country_id" && input.state === true).map(message => (
                                        <span className="text-red-500">{message.message}</span>
                                    ))
                                }
                            </div>

                            <div className="mb-6 text-start w-full lg:w-2/2 p-1 my-3">
                                <label htmlFor="sex" className="text-white">Correo electrónico</label>
                                <input
                                    type="text"
                                    className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                    name="email"
                                    value={employer.email}
                                    onChange={handleInputChangeEmployer}
                                    disabled
                                />
                                {
                                    alert.filter(input => input.input == "email" && input.state === true).map(message => (
                                        <span className="text-red-500">{message.message}</span>
                                    ))
                                }
                            </div>

                            {/* telephone */}
                            <div className="text-start w-full lg:w-1/2 p-1 my-3">
                                <label htmlFor="nickname" className="text-white">Teléfono principal </label>
                                {/* { 
                                    inputPhone 
                                    ?
                                        <InputMask
                                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                            mask={phone !== "" ? "" : ""}
                                            // maskChar=""
                                            type="number"
                                            placeholder="Teléfono"
                                            name="phone"
                                            value={employer.phone}
                                            onChange={handleInputChangeEmployer}
                                        />
                                    :
                                        <InputMask
                                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                            mask={phone !== "" ? "" : ""}
                                            // maskChar=""
                                            type="number"
                                            placeholder="Teléfono"
                                            name="phone"
                                            value={employer.phone}
                                            onChange={handleInputChangeEmployer}
                                        />
                                } */}
                                <input
                                    type="text"
                                    className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                    name="phone"
                                    value={employer.phone}
                                    onChange={handleInputChangeEmployer}
                                />
                                {
                                    alert.filter(input => input.input == "phone" && input.state === true).map(message => (
                                        <span className="text-red-500">{message.message}</span>
                                    ))
                                }
                            </div>

                            {/* Telephone secondary */}
                            <div className="text-start w-full lg:w-1/2 p-1 my-3">

                                <label htmlFor="nickname" className="text-white">Teléfono secundario</label>
                                {/* {
                                    inputPhone
                                    ?
                                        <InputMask
                                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                            mask={phone !== "" ? "" : ""}
                                            // maskChar=""
                                            type="number"
                                            placeholder="Teléfono"
                                            name="secondary_phone"
                                            value={employer.secondary_phone}
                                            onChange={handleInputChangeEmployer}
                                        />
                                    :
                                        <InputMask
                                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                            mask={phone !== "" ? "" : ""}
                                            maskPlaceholder={null}
                                            type="number"
                                            placeholder="Teléfono"
                                            name="secondary_phone"
                                            value={employer.secondary_phone}
                                            onChange={handleInputChangeEmployer}
                                        />
                                } */}
                                <input
                                    type="text"
                                    className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                    name="secondary_phone"
                                    value={employer.phone_alternative}
                                    onChange={handleInputChangeEmployer}
                                />
                                {
                                    alert.filter(input => input.input == "phone_alternative" && input.state === true).map(message => (
                                        <span className="text-red-500">{message.message}</span>
                                    ))
                                }
                            </div>

                            {/* address */}
                            {/* <div className="text-start w-full lg:w-2/2 p-1 my-3">

                                <label htmlFor="nickname" className="text-white">Dirección</label>
                                <input
                                    type="text"
                                    className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                    name="direction"
                                    value={employer.direction}
                                    onChange={handleInputChangeEmployer}
                                />
                                {
                                    alert.filter(input => input.input == "address" && input.state === true).map(message => (
                                        <span className="text-red-500">{message.message}</span>
                                    ))
                                }


                                <div className="py-6" ref={map}>
                                    <div ref={mapContainer} className="map-container" />
                                </div>


                            </div> */}



                            {/* description of the person */}
                            <div className="text-start w-full p-1 my-3">
                                <label htmlFor="direction" className="text-white">Acerca de mi</label>
                                <textarea
                                    className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                    rows={7}
                                    name="about_me"
                                    value={employer.about_me}
                                    onChange={handleInputChangeEmployer}
                                ></textarea>
                                {
                                    alert.filter(input => input.input == "about_me" && input.state === true).map(message => (
                                        <span className="text-red-500">{message.message}</span>
                                    ))
                                }
                            </div>

                            {/* <div className="w-full sm:w-1/2 p-2">
                              <div className="flex bg-Light-purple rounded-md p-4 h-full">
                                <div className="flex justify-center items-center w-1/3">
                                  <FontAwesomeIcon
                                    className="text-white text-2xl mr-4"
                                    icon={faLanguage}
                                  />
                                </div>
                                <div className="w-2/3 text-start text-white font-semibold lg:w-full">
                                  <h2>Idiomas:</h2>
                                  <h2>
                                    { (employer.languages != null)
                                        ?
                                        employer.languages.map(
                                            (idioma: any, index: number) => {
                                                let name = "";
                                                if ( !(employer.languages.length - 1 == index ) ) {
                                                    name = idioma.name + ", ";
                                                } else {
                                                    name = idioma.name;
                                                }
                                                return <span key={name}>{name}</span>;
                                            }
                                        )
                                        :
                                        <span>No tiene idiomas</span>
                                    }
                                  </h2>
                                </div>
                              </div>
                            </div> */}

                            <div className="text-start w-full p-1 my-3">
                                <label htmlFor="" className="text-white">Idiomas que domino</label>
                                <div className="flex flex-wrap pl-4 justify-start my-4">
                                    {
                                        Languages.map((item) => {
                                            return (
                                                <div className="flex items-center pr-5 w-full sm:w-1/2 xl:w-1/4">
                                                    <input 
                                                        className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white" 
                                                        type="checkbox" 
                                                        id={'language' + item.id} 
                                                        name="languages" 
                                                        onChange={handleInputChangelanguages} 
                                                        value={item.id} 
                                                    />
                                                    <span className="text-white pl-2">{item.name}</span>
                                                </div>
                                            );
                                        })
                                    }
                                    {/* {
                                        (ExistOtherLanguage == false)
                                            ?
                                            <div className="flex items-center pr-5 w-full sm:w-1/2 xl:w-1/4">
                                                <input className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2 checked:after:rotate-45 checked:after:absolute checked:after:border-white" type="checkbox"
                                                    onChange={handleInputChangelanguages}
                                                    id='language10'
                                                    name="languages"
                                                    value="10"
                                                    onClick={function () {
                                                        (habilitarOtherLanguage === true)
                                                            ?
                                                            setHabilitarOtherLanguages(false)
                                                            :
                                                            setHabilitarOtherLanguages(true)
                                                    }} />
                                                <span className="text-white pl-2">
                                                    {
                                                        (updatedInfo.otherLanguage == "")
                                                            ?
                                                            "Otro idioma"
                                                            :
                                                            updatedInfo.otherLanguage
                                                    }
                                                </span>
                                            </div>
                                            :
                                            <div className="flex items-center pr-5 w-full sm:w-1/2 xl:w-1/4">
                                                <input className="scale-150 w-3 h-3 mr-4 bg-checkbox border-solid border-transparent appearance-none checked:bg-Yellow checked:after:border-solid checked:after:border checked:after:border-t-0 checked:after:border-r-2 checked:after:border-b-2 checked:after:border-l-0 checked:after:top-px checked:after:ml-1 checked:after:w-1 checked:after:h-2  checked:after:rotate-45 checked:after:absolute checked:after:border-white" type="checkbox" id={'other'} name="languages" onChange={handleInputChangelanguages} value={'other'} />
                                                <span className="text-white pl-2">{updatedInfo.otherLanguage}</span>
                                            </div>
                                    } */}

                                </div>
                                {/* {
                                    (habilitarOtherLanguage == true)
                                    &&
                                    <div className="text-start w-full p-1 my-3">
                                        <label htmlFor="otherLanguage" className="text-white">Otro idioma</label>
                                        <input
                                            type="text"
                                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                            name="otherLanguage"
                                            value={updatedInfo.otherLanguage}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                } */}
                                {/* {
                                    (ExistOtherLanguage == true && CheckboxOtherLanguageIsChecked == true)
                                    &&
                                    <div className="text-start w-full p-1 my-3">
                                        <label htmlFor="otherLanguage" className="text-white">Editar idioma agregado</label>
                                        <input
                                            type="text"
                                            className="form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:focus:border-Yellow focus:outline-none"
                                            name="otherLanguage"
                                            value={updatedInfo.otherLanguage}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                } */}
                                {alert.filter(input => input.input == "language" && input.state === true).map((message, key) => (
                                    <span key={key} className="text-red-500">{message.message}</span>
                                ))}
                            </div>

                            <div className="text-start flex justify-center items-center w-full">
                                <hr className="w-12 xl:w-56 lg:w-32 2xl:w-96" />
                                <label htmlFor="" className="text-white px-2">Agregar redes sociales</label>
                                <hr className="w-12 xl:w-56 lg:w-32 2xl:w-96" />
                            </div>


                            {/* social networks */}
                            {/* Facebook */}
                            <div className="text-start w-full lg:w-1/2 p-1 my-3">
                                <label htmlFor="facebook" className="text-white">Url de tu perfil de Facebook</label>
                                <input
                                    type="url"
                                    className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                    name="facebook_link"
                                    value={employer.facebook_link}
                                    onChange={handleInputChangeEmployer}
                                />
                                {/* {alert.filter(input => input.input == "facebook" && input.state === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))} */}
                            </div>

                            {/* instagram */}
                            <div className="text-start w-full lg:w-1/2 p-1 my-3">
                                <label htmlFor="instagram" className="text-white">Url de tu perfil de Instagram</label>
                                <input
                                    type="url"
                                    className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                    name="instagram_link"
                                    value={employer.instagram_link}
                                    onChange={handleInputChangeEmployer}
                                />
                                {/* {alert.filter(input => input.input == "instagram" && input.state === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))} */}
                            </div>

                            {/* Twitter */}
                            <div className="text-start w-full lg:w-1/2 p-1 my-3">
                                <label htmlFor="facebook" className="text-white">Url de tu perfil de Twitter</label>
                                <input
                                    type="url"
                                    className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                    name="twitter_link"
                                    value={employer.twitter_link}
                                    onChange={handleInputChangeEmployer}
                                />
                                {/* {alert.filter(input => input.input == "twitter" && input.state === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))} */}
                            </div>

                            {/* linkedin */}
                            <div className="text-start w-full lg:w-1/2 p-1 my-3">
                                <label htmlFor="instagram" className="text-white">Url de tu perfil de Linkedin</label>
                                <input
                                    type="url"
                                    className="login form-control block w-full px-4 py-2 text-xl font-normal text-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                                    name="linkedin_link"
                                    value={employer.linkedin_link}
                                    onChange={handleInputChangeEmployer}
                                />
                            </div>
                            <div className="text-center lg:text-left mx-12 w-full p-1 my-3">
                                <button type="submit" className="inline-block px-7 py-3 bg-Yellow text-white font-medium text-sm leading-snug uppercase rounded-lg shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out w-full">
                                    {!sendingData && <>Guardar cambios</>}
                                    {sendingData && <IconLoading />}
                                </button>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </>
    )
}

export default EditProfileEmployer;