import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { faPenToSquare, faFolderPlus, faX, faTrash, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ModalKnowledgeSkeleton from "../../Skeleton/modalUpdatedKnowledge/modalKnowledgeSkeleton";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


interface Props {
   animation: string;
   state: String
   idFreeRouter: String | undefined;
}

const initialStateknowledge = {
   category_id: 0,
   id: 0,
   name: "",
   nivel_conocimiento: 0,
   specialization_category: "",
   id_registro: 0
}

const initialStateListSpecialization = {
   created_at: "",
   id: 0,
   name: "",
   updated_at: ""
}

type handleInputChangeUpdated = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;


const Specialization = ({ animation, idFreeRouter, state }: Props) => {

   // Cookies
   const cookies = new Cookies();

   const getSesion = cookies.get("Bearer");

   const [specializations, setSpecializations] = useState([initialStateListSpecialization]);

   const [knowledge, setknowledge] = useState([initialStateknowledge]);

   const [indoModalKnowledge, setIndoModalKnowledge] = useState({ id: 0, name: "", level: 0, idCo: 0 });

   const [IsLoading, setIsLoading] = useState(true);

   const [IsLoadingModal, setIsLoadingModal] = useState(true);

   const MySwal = withReactContent(Swal);

   const [toggleShowAndHide, setToggleShowAndHide] = useState<Boolean>(false);

   const [addClass, setAddClass] = useState<string>("hidden bg-fadeOut");

   const [idUser, setidUser] = useState<number>(0);

   let ListSpecializationsFreelancer = [{
      name: "",
      list: [initialStateknowledge]
   }];

   specializations.map((specialization) => {

      if (knowledge[0].name != "") {

         const listNew = knowledge.filter((knowledgeFree) => knowledgeFree.category_id === specialization.id);

         let data = {
            name: specialization.name,
            list: listNew
         };

         ListSpecializationsFreelancer.push(data);

      };

   });

   const dataOfknowledgeFreelancer = ListSpecializationsFreelancer.filter((item) => item.name != "");
   console.log("dataOfknowledgeFreelancer ==>", dataOfknowledgeFreelancer);
   async function fetchData() {

      const { id } = cookies.get('Bearer');

      let urlCountry = "";

      if (state == "Public") {

         urlCountry = `${process.env.REACT_APP_API_URI}freelancer/list/knowledge-specializations/${idFreeRouter}`;

      } else if (state == "session") {

         urlCountry = `${process.env.REACT_APP_API_URI}freelancer/list/knowledge-specializations/${id}`;

      };

      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'ApiToken': `Bearer ${getSesion.token}`
         }
      };

      try {

         const resp = await axios.get(urlCountry, config);
         // console.log("resp ==>", resp);
         const specializationFreelancer = resp.data.data;

         setknowledge(specializationFreelancer);

         setIsLoading(false)

      } catch (err) {
         // Handle Error Here
         setIsLoading(false);
         console.error(err);
      }
   };

   useEffect(() => {

      fetchData();

      async function getSpecialties() {

         const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/types`;

         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         };
         try {

            const resp = await axios.get(urlCountry, config);
            setSpecializations(resp.data.specializationTypes);

            // setIsLoading(false);

         } catch (err) {
            // Handle Error Here
            setIsLoading(false);
            console.error(err);
         }
      };
      getSpecialties();

      const getIdUser = () => {
         const { id } = cookies.get('Bearer');
         setidUser(id)
      }
      getIdUser();

   }, []);


   const handleModal = () => {
      toggleShowAndHide ? setToggleShowAndHide(false) : setToggleShowAndHide(true);

      if (!toggleShowAndHide) {
         setAddClass("block bg-fadeIn");
      } else {
         setAddClass("bg-fadeOut");
         setTimeout(() => { setAddClass("hidden") }, 500);
      }
   }

   const resetInfo = () => {
      handleModal();
      setIndoModalKnowledge({ id: 0, name: "", level: 0, idCo: 0 });
      setknowledge([initialStateknowledge]);
      fetchData();
   };

   const showModal = (category_id: number, name: string, nivel_conocimiento: number, specialization_category: string, knowledge: number, id_registro: number) => {

      setIndoModalKnowledge({ id: id_registro, name: "", level: 0, idCo: knowledge });

      handleModal();

      setIndoModalKnowledge({ id: id_registro, name: name, level: nivel_conocimiento, idCo: knowledge });

      setTimeout(() => { setIsLoadingModal(false) }, 1000);

   };

   const closeModalKnowledge = () => {

      toggleShowAndHide ? setToggleShowAndHide(false) : setToggleShowAndHide(true);

      if (!toggleShowAndHide) {
         setAddClass("block bg-fadeIn");
      } else {
         setAddClass("bg-fadeOut");
         setTimeout(() => { setAddClass("hidden") }, 500);
      };

      setIsLoadingModal(true);
   };


   // Function for Knowledge
   const handleKnowledgeUpdated = ({ target: { name, value, id, } }: handleInputChangeUpdated) => {

      setIndoModalKnowledge({ ...indoModalKnowledge, [name]: value });

   };

   const deleteKnowledge = async () => {

      console.log('knowledge.id', indoModalKnowledge.id);
      const urlDeleteKnowledge = `${process.env.REACT_APP_API_URI}freelancer/delete/Knowledge-specializations/${indoModalKnowledge.id}`;

      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'ApiToken': `Bearer ${getSesion.token}`
         }
      };

      axios.delete(urlDeleteKnowledge, config)
         .then(response => {
            console.log(response);
            MySwal.fire({
               position: 'center',
               icon: 'success',
               title: response.data.message,
               showConfirmButton: false,
               timer: 3500
            });

            resetInfo();

         }).catch(err => {
            
            console.log(err)
            MySwal.fire({
               position: 'center',
               icon: 'error',
               title: err.response.data.message,
               showConfirmButton: false,
               timer: 3500
            });

         });


   };

   const updatedKnowledge = async () => {

      const url = `${process.env.REACT_APP_API_URI}freelancer/register/Knowledge-specializations`;

      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'ApiToken': `Bearer ${getSesion.token}`
         }
      };

      const knowledges = {
         'user_id': idUser,
         'knowledges': [{
            knowledge_id: indoModalKnowledge.idCo,
            level: indoModalKnowledge.level
         }]

      };

      axios.post(url, knowledges, config)
         .then(response => {
      
            MySwal.fire({
               position: 'center',
               icon: 'success',
               title: response.data.message,
               showConfirmButton: false,
               timer: 3500
            });

            resetInfo();

         })
         .catch(err => {
            MySwal.fire({
               position: 'center',
               icon: 'error',
               title: err.response.data.log,
               showConfirmButton: false,
               timer: 3500
            });
         })

   };

   return (
      <>
         <div className={`text-white ${animation}`} id="contenedorEspecializacionProfesional" role="tabpanel" aria-labelledby="profile-tab">

            {
               (state == "session") &&
               <div className="w-full text-center">
                  <Link
                     to="/registrar-habilidades"
                     className="mb-4 inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded-lg shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                     Agregar
                     {/* <FontAwesomeIcon className="text-white text-lg px-2" icon={faFolderPlus} /> */}
                  </Link>
               </div>
            }


               <>
                  {IsLoading ? (
                     <div className="border border-Light-Blue p-5 mb-5 rounded-lg">
                        <h2 className="text-start"><Skeleton count={1} width={200} /></h2>
                        <div className="flex flex-wrap">
                           <div className="mt-4 w-full sm:w-1/2 p-5 relative">
                              <div className="text-start float-left"><Skeleton count={1} width={150} /></div>
                              <div className="text-end"><Skeleton count={1} width={150} /></div>
                              <Skeleton count={1} />
                           </div>
                           <div className="mt-4 w-full sm:w-1/2 p-5 relative">
                              <div className="text-start float-left"><Skeleton count={1} width={150} /></div>
                              <div className="text-end"><Skeleton count={1} width={150} /></div>
                              <Skeleton count={1} />
                           </div>
                           <div className="mt-4 w-full sm:w-1/2 p-5 relative">
                              <div className="text-start float-left"><Skeleton count={1} width={150} /></div>
                              <div className="text-end"><Skeleton count={1} width={150} /></div>
                              <Skeleton count={1} />
                           </div>
                           <div className="mt-4 w-full sm:w-1/2 p-5 relative">
                              <div className="text-start float-left"><Skeleton count={1} width={150} /></div>
                              <div className="text-end"><Skeleton count={1} width={150} /></div>
                              <Skeleton count={1} />
                           </div>
                        </div>
                     </div>
                  ) : (
                     (dataOfknowledgeFreelancer.length !== 0 ?
                        dataOfknowledgeFreelancer.filter(data => data.list.length != 0).map((item, key) => {
                           return (
                              <div key={key} className="border border-Light-Blue p-5 mb-5 rounded-lg">
                                 <h2 className="text-start">{item.name}</h2>
                                 <div className="flex flex-wrap">
                                    {
                                       item.list.map((knowledgeFreelancer, key) => {
                                          return (
                                             <div key={key} className="mt-4 w-full sm:w-1/2 p-6 relative">
                                                <div className="text-start float-left">{knowledgeFreelancer.name}</div>
                                                <div className="text-end">{knowledgeFreelancer.nivel_conocimiento}%</div>
                                                <div className="shadow w-full bg-white mt-2 rounded-lg">
                                                   <div className="bg-Light-Blue text-xs py-1 text-center text-white rounded-lg" style={{ width: `${knowledgeFreelancer.nivel_conocimiento}%`, lineHeight: "0" }}></div>
                                                </div>

                                                {
                                                   (state == "session") &&
                                                   <div className="inline-block p-1 bg-Yellow text-white font-medium text-xs leading-tight uppercase shadow-md hover:bg-amber-500 hover:shadow-lg focus:bg-amber-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-600 active:shadow-lg transition duration-150 ease-in-out absolute top-0 right-0 rounded-lg cursor-pointer">
                                                      <FontAwesomeIcon onClick={() => showModal(knowledgeFreelancer.category_id, knowledgeFreelancer.name, knowledgeFreelancer.nivel_conocimiento, knowledgeFreelancer.specialization_category, knowledgeFreelancer.id, knowledgeFreelancer.id_registro)} className="text-white text-lg" icon={faPenToSquare} />
                                                   </div>
                                                }

                                             </div>
                                          )
                                       })
                                    }
                                 </div>
                              </div>
                           )}) : (
                              <p className="text-center mt-9">No se encontraron especializaciones</p>
                           )
                     )
                  )}
               </>
         </div>
         <div id="modal-component-container" className={`fixed inset-0  hidden-modal ${toggleShowAndHide ? addClass : addClass}`}>
            <div className="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-96 text-center sm:block sm:pb-0 ">

               <div className='modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75'></div>
               <div className='modal-space-container hidden h-80 sm:inline-block sm:align-middle'> &nbsp;</div>

               <div className={`modal-container inline-block align-bottom bg-turquoise rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:aling-middle sm:w-2/4 w-full ${toggleShowAndHide ? 'modal-scaleIn' : 'modal-scaleout '}`}>


                  {IsLoadingModal &&
                     <ModalKnowledgeSkeleton />
                  }

                  {!IsLoadingModal &&
                     <>
                        <div className='modal-wrapper bg-stable-background-modal px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative'>
                           <h1 className="text-center text-2xl text-Yellow font-bold">Editar especialidad</h1>
                           <div className="modal-wrapper-flex">
                              <div className="px-8 grid py-4 w-full">
                                 <label htmlFor="" className="text-white h-12 overflow-hidden">{indoModalKnowledge.name}</label>
                                 <div className="flex">
                                    <input
                                       type="range"
                                       className="mt-2 mr-2"
                                       value={indoModalKnowledge.level}
                                       style={{ width: `100%`, lineHeight: "0" }}
                                       name="level"
                                       id={String(indoModalKnowledge.id)}
                                       onChange={handleKnowledgeUpdated}
                                    />
                                    <span className="text-white">
                                       {indoModalKnowledge.level}%
                                    </span>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className='modal-actions bg-stable-background-modal px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>

                           <div className="mb-4 absolute top-2 right-2">
                              <button className="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-Light-Blue text-white font-mediumtext-gray-700 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                 onClick={() => closeModalKnowledge()}
                              >
                                 <FontAwesomeIcon icon={faX} />
                              </button>
                           </div>

                           <div className="mb-4">
                              <button
                                 type="submit"
                                 className="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-red-500 text-white font-mediumtext-gray-700hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                 onClick={() => {
                                    MySwal.fire({
                                       title: '¿Estás seguro?',
                                       text: "No podrás revertir esto!",
                                       icon: 'warning',
                                       showCancelButton: true,
                                       confirmButtonColor: '#3085d6',
                                       cancelButtonColor: '#d33',
                                       confirmButtonText: 'Sí, eliminarlo!'
                                    }).then((result) => {
                                       if (result.isConfirmed) {
                                          deleteKnowledge();
                                       }
                                    })
                                 
                                 }}
                              >
                                 {/* <FontAwesomeIcon icon={faTrash} /> */}
                                 Eliminar
                              </button>
                           </div>

                           <div className="mb-4">
                              <button
                                 type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-Yellow text-white font-mediumtext-gray-700hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                 onClick={() => updatedKnowledge()}
                              >
                                 {/* <FontAwesomeIcon icon={faFloppyDisk} /> */}
                                 Actualizar
                              </button>
                           </div>

                        </div>
                     </>

                  }

               </div>

            </div>

         </div>
      </>
   )
}

export default Specialization;