import { useEffect, useState } from "react";
import IconLoading from "../iconLoading/IconLoading";
import { validateData } from "../validateData";
import axios from "axios";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams } from "react-router-dom";
import { to12Hours, to24Hours } from "../Utils/Utils";
import UpdatedProjectSkeleton from "../Skeleton/formUpdatedProject/updatedProjectSkeleton";
import Skeleton from "react-loading-skeleton";

const FormPasantia = ({ typeForm }) => {

    console.log("🚀 ~ FormPasantia ~ typeForm:", typeForm)



    const editTypeOpportunity = useParams();

    const initialStateInput = {
        input: "",
        message: '',
        state: false
    }

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const characterTextLength = 500;
    const [lengthText, setLengthText] = useState(0);
    const [alert, setAlert] = useState([initialStateInput]);
    const [infoPasantia, setInfoPasantia] = useState({
        title: "",
        typeof: "",
        montoMensual: "",
        // otherBenefits: "",
        // duration: "",
        dateInit: "",
        dateEnd: "",
        dateLimitToApply: "",
        schedules: "",
        modality: "",
        location: "",
        description: "",
        scheduleInit: "",
        scheduleEnd: "",
    });

    const [sendingData, setSendingData] = useState(false);

    const [showMonto, setShowMonto] = useState(false);

    const [oldData, setOldData] = useState({
        title: "",
        typeof: "",
        montoMensual: "",
        // otherBenefits: "",
        // duration: "",
        dateInit: "",
        dateEnd: "",
        dateLimitToApply: "",
        schedules: "",
        modality: "",
        location: "",
        description: "",
    });

    const [dateModify, setDateModify] = useState(typeForm == "update" ? false : true);

    const [id, setId] = useState(0);

    const MySwal = withReactContent(Swal);

    const cookies = new Cookies();
    const getSesion = cookies.get('Bearer');

    console.log(getSesion, "getSesion");

    const handleInputPasantia = (e) => {
        if (e.target.name === "title") {
            if (e.target.value.length <= 40) {
                setInfoPasantia({
                    ...infoPasantia,
                    [e.target.name]: e.target.value
                });
            }
        } else if (e.target.name === "typeof") {
            setInfoPasantia({
                ...infoPasantia,
                [e.target.name]: e.target.value
            });

            if (e.target.value == "1") {
                setShowMonto(true);
            } else {
                setShowMonto(false);
            }
        } else {
            setInfoPasantia({
                ...infoPasantia,
                [e.target.name]: e.target.value
            });
        }
    }

    const getInfoPasantia = async () => {

        setIsLoading(true);

        let urlListOpportunity = `${process.env.REACT_APP_API_URI}project/get/${editTypeOpportunity.id}?id_type_opportunities=1`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        };

        try {
            const resp = await axios.get(urlListOpportunity, config);
            const respInfoPasantia = resp.data;

            console.log("🚀 ~ getInfoPasantia ~ respInfoPasantia:", respInfoPasantia)

            let scheduleInit = "";
            let scheduleEnd = "";

            try {
                scheduleInit = to24Hours(respInfoPasantia.schedules.split(" - ")[0]);
                scheduleEnd = to24Hours(respInfoPasantia.schedules.split(" - ")[1]);
            } catch (error) {
                console.error(error);
            }

            const listDataNew = {
                title: respInfoPasantia.job_title,
                typeof: respInfoPasantia.id_type_internship,
                montoMensual: respInfoPasantia.remuneration,
                dateInit: respInfoPasantia.start_date,
                dateEnd: respInfoPasantia.end_date,
                dateLimitToApply: respInfoPasantia.application_deadline,
                schedules: respInfoPasantia.schedules,
                scheduleInit: scheduleInit,
                scheduleEnd: scheduleEnd,
                modality: respInfoPasantia.id_work_modality,
                location: respInfoPasantia.location,
                description: respInfoPasantia.description,
            }

            if (respInfoPasantia.id_type_internship == "1") {
                setShowMonto(true);
            }

            setId(respInfoPasantia.id);

            setInfoPasantia(listDataNew);

            setOldData(listDataNew);

            setIsLoading(false);

        } catch (err) {
            console.error(err);
        };

    };

    const sendData = () => {
        const url = `${process.env.REACT_APP_API_URI}company/${typeForm}/project/${id}`;

        let config = {
            // multipart/form-data
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        };

        let sendData = {
            id_type_internship: infoPasantia.typeof,
            id_work_modality: infoPasantia.modality,
            job_title: infoPasantia.title,
            location: infoPasantia.location,
            description: infoPasantia.description,
            id_type_opportunities: 1,
        };

        // Convertir a formato de 12 horas el horario
        let scheduleInit = to12Hours(infoPasantia.scheduleInit);
        let scheduleEnd = to12Hours(infoPasantia.scheduleEnd);

        sendData.schedules = `${scheduleInit} - ${scheduleEnd}`;

        if (showMonto) {
            sendData.remuneration = infoPasantia.montoMensual;
        }

        if (dateModify) {
            sendData.application_deadline = infoPasantia.dateLimitToApply;
            sendData.end_date = infoPasantia.dateEnd;
            sendData.start_date = infoPasantia.dateInit;
        } else {
            sendData.application_deadline = oldData.dateLimitToApply;
            sendData.end_date = oldData.dateEnd;
            sendData.start_date = oldData.dateInit;
        }

        console.log(sendData, "sendData");

        axios.post(url, sendData, config).then(response => {
            console.log(response);
            MySwal.fire({
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                navigate('/perfil-empresa');
            });
        }).catch(err => {
            console.log(err);
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: err.response.data.Message,
                showConfirmButton: false,
                timer: 3500
            });
        })
    };

    const datosValidados = (e) => {
        e.preventDefault();
        let inputs = Object.entries(infoPasantia).map(([key, value]) => {
            return {
                name: key,
                value: value,
            };
        });

        // inputs = inputs.filter(input => input.name !== "otherBenefits");

        if (showMonto == false) {
            inputs = inputs.filter(input => input.name !== "montoMensual");
        }

        if (dateModify == false) {
            inputs = inputs.filter(input => input.name !== "dateInit" && input.name !== "dateEnd" && input.name !== "dateLimitToApply");
        }

        inputs = inputs.filter(input => input.name !== "schedules");

        const datosValidados = validateData(inputs);

        console.log("🚀 ~ datosValidados ~ datosValidados:", datosValidados)

        setAlert(datosValidados);

        let totalValidaciones = datosValidados.filter(input => input.status !== false).map(() => { return false });

        if (totalValidaciones.length === 0) {
            sendData();
        } else {
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: 'Por favor, asegúrate de llenar todos los campos requeridos.',
                showConfirmButton: false,
                timer: 3000
            });
        }
    };

    useEffect(() => {
        if (typeForm == "update") {
            getInfoPasantia();
        } else if (typeForm == "create") {
            setId(getSesion.id);
        }
    }, []);

    return (
        <div className="w-full flex justify-center">
            <form className="w-full" onSubmit={datosValidados}>
                <div className="bg-bluecard-big p-5 rounded">
                    <div className="md:flex items-center md:justify-between">
                        <h1 className="text-Yellow font-bold text-3xl text-left mb-8 px-2 flex items-center justify-between">Requerimientos de pasantía</h1>
                        {typeForm === "update" && isLoading === false && <label className="relative inline-flex items-center cursor-pointer mb-8 ml-2">
                            <input type="checkbox"
                                checked={dateModify}
                                onChange={() => setDateModify(!dateModify)}
                                className="sr-only peer"
                                name="emergency_contact"
                            />
                            <div className="w-11 h-6 peer-focus:outline-none rounded-full peer bg-gray-500 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-Yellow"></div>
                            <span className="ml-3 text-sm font-medium text-white">Modificar fechas</span>
                        </label>}
                    </div>
                    {dateModify && typeForm === "update" && <h1 className="text-Yellow font-bold text-sm text-left mb-8 px-2">¡Importante! <span className="text-white">Para modificar las fechas, la nueva fecha límite de aplicación tiene que ser igual o mayor a la actual y por ende las de inicio y de finalización tienen que ser mayor a la fecha límite de aplicación.</span></h1>}
                    <div className="flex flex-wrap">
                        <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                            <label htmlFor="title" className="text-white lg:text-sm xl:text-base flex justify-between">
                                <span className="text-red-500"><span className="text-white">Titulo del puesto </span>*</span><span className="text-Yellow"> {infoPasantia.title.length}/40</span>
                            </label>
                            {isLoading ? <Skeleton className="form-control block w-full mt-2 px-4 py-3 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                            :
                            <input
                                type="text"
                                className="form-control block w-full mt-2 px-4 py-3 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                name="title"
                                value={infoPasantia.title}
                                onChange={handleInputPasantia}
                            />}
                            {
                                alert.filter(input => input.input == "title" && input.status === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))
                            }
                        </div>
                        <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                            <label htmlFor="typeof" className="text-white mb-8 lg:text-sm xl:text-base">
                                Tipo de pasantía <span className="text-red-500">*</span>
                            </label>
                            {isLoading ? <Skeleton className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                            :
                            <select
                                name="typeof"
                                value={infoPasantia.typeof}
                                onChange={handleInputPasantia}
                                className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none">
                                <option className="text-white" value={""}>Selecciona el tipo</option>
                                <option value={"1"} className="text-white">Remunerada</option>
                                <option value={"2"} className="text-white">No remunerada</option>
                            </select>}
                            {
                                alert.filter(input => input.input == "typeof" && input.status === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))
                            }
                        </div>
                        <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                            <label htmlFor="modality" className="text-white mb-8 lg:text-sm xl:text-base">
                                Modalidad <span className="text-red-500">*</span>
                            </label>
                            {isLoading ? <Skeleton className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                            :
                            <select
                                name="modality"
                                value={infoPasantia.modality}
                                onChange={handleInputPasantia}
                                className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none">
                                <option className="text-white" value={""} selected>Selecciona el tipo</option>
                                <option value={"1"} className="text-white">Presencial</option>
                                <option value={"2"} className="text-white">Remoto</option>
                                {/* <option value={"3"} className="text-white">Híbrido</option> */}

                                {/* {
                                listBudget.map((range) => {
                                    return <option value={range.id}>{range.range}</option>
                                })
                            } */}

                            </select>}
                            {
                                alert.filter(input => input.input == "modality" && input.status === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))
                            }
                        </div>
                        {/* <div className="mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2">
                        <label htmlFor="duration" className="text-white mb-8 lg:text-sm xl:text-base">
                            Duración de la pasantía (En semanas/meses) <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control block w-full mt-2 px-4 py-3 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                            name="duration"
                            value={infoPasantia.duration}
                            onChange={handleInputPasantia}
                        />
                        {
                            alert.filter(input => input.input == "duration" && input.status === true).map(message => (
                                <span className="text-red-500">{message.message}</span>
                            ))
                        }
                    </div> */}
                        {
                            dateModify &&
                            <>
                                <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                                    <label htmlFor="dateInit" className="text-white mb-8 lg:text-sm xl:text-base">
                                        Fecha de inicio <span className="text-red-500">*</span>
                                    </label>
                                    {isLoading ? <Skeleton className="form-control block w-full mt-2 px-4 py-3 text-lg text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                                    :
                                    <input
                                        type="date"
                                        className="form-control block w-full mt-2 px-4 py-3 text-lg text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                        name="dateInit"
                                        value={infoPasantia.dateInit}
                                        onChange={handleInputPasantia}
                                    />}
                                    {
                                        alert.filter(input => input.input == "dateInit" && input.status === true).map(message => (
                                            <span className="text-red-500">{message.message}</span>
                                        ))
                                    }
                                </div>
                                <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                                    <label htmlFor="dateEnd" className="text-white mb-8 lg:text-sm xl:text-base">
                                        Fecha de finalización <span className="text-red-500">*</span>
                                    </label>
                                    {isLoading ? <Skeleton className="form-control block w-full mt-2 px-4 py-3 text-lg text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                                    :
                                    <input
                                        type="date"
                                        className="form-control block w-full mt-2 px-4 py-3 text-lg text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                        name="dateEnd"
                                        value={infoPasantia.dateEnd}
                                        onChange={handleInputPasantia}
                                    />}
                                    {
                                        alert.filter(input => input.input == "dateEnd" && input.status === true).map(message => (
                                            <span className="text-red-500">{message.message}</span>
                                        ))
                                    }
                                </div>
                                <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                                    <label htmlFor="dateLimitToApply" className="text-white mb-8 lg:text-sm xl:text-base">
                                        Fecha limite de aplicación <span className="text-red-500">*</span>
                                    </label>
                                    {isLoading ? <Skeleton className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                                    :
                                    <input
                                        name="dateLimitToApply"
                                        type="date"
                                        value={infoPasantia.dateLimitToApply}
                                        onChange={handleInputPasantia}
                                        className="form-control block w-full px-4 py-3 mt-2 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />}
                                    {
                                        alert.filter(input => input.input == "dateLimitToApply" && input.status === true).map(message => (
                                            <span className="text-red-500">{message.message}</span>
                                        ))
                                    }
                                </div>
                            </>
                        }
                        {
                            showMonto &&
                            <>
                                <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                                    <label htmlFor="montoMensual" className="text-white mb-8 lg:text-sm xl:text-base">
                                        Monto mensual ofrecido <span className="text-red-500">*</span>
                                    </label>
                                    {isLoading ? <Skeleton className="form-control block w-full mt-2 px-4 py-3 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                                    :
                                    <input
                                        type="number"
                                        className="form-control block w-full mt-2 px-4 py-3 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                        name="montoMensual"
                                        min={0}
                                        value={infoPasantia.montoMensual}
                                        onChange={handleInputPasantia}
                                    />}
                                    {
                                        alert.filter(input => input.input == "montoMensual" && input.status === true).map(message => (
                                            <span className="text-red-500">{message.message}</span>
                                        ))
                                    }
                                </div>
                                {/* <div className="mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2">
                                <label htmlFor="otherBenefits" className="text-white mb-8 lg:text-sm xl:text-base">
                                    Otros beneficios
                                </label>
                                <input
                                    type="text"
                                    className="form-control block w-full mt-2 px-4 py-3 text-xl font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                    name="otherBenefits"
                                    value={infoPasantia.otherBenefits}
                                    onChange={handleInputPasantia}
                                />
                                {
                                    alert.filter(input => input.input == "otherBenefits" && input.status === true).map(message => (
                                        <span className="text-red-500">{message.message}</span>
                                    ))
                                }
                            </div> */}
                            </>
                        }
                        <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                            <label htmlFor="timeInit" className="text-white mb-8 lg:text-sm xl:text-base">
                                Horario de trabajo <span className="text-red-500">*</span>
                            </label>
                            <div className="flex">
                                <div className="w-full">
                                    {isLoading ? <Skeleton className="form-control block w-full mt-2 px-4 py-3 text-lg text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                                    :
                                    <input
                                        type="time"
                                        className="form-control block w-full mt-2 px-4 py-3 text-lg text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                        name="scheduleInit"
                                        value={infoPasantia.scheduleInit}
                                        onChange={handleInputPasantia}
                                    />}
                                    {
                                        alert.filter(input => input.input == "scheduleInit" && input.status === true).map(message => (
                                            <span className="text-red-500">{message.message}</span>
                                        ))
                                    }
                                </div>
                                <div className="w-full">
                                    {isLoading ? <Skeleton className="form-control block w-full mt-2 px-4 py-3 text-lg text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                                    :
                                    <input
                                        type="time"
                                        className="form-control block w-full mt-2 px-4 py-3 text-lg text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                        name="scheduleEnd"
                                        value={infoPasantia.scheduleEnd}
                                        onChange={handleInputPasantia}
                                    />}
                                    {
                                        alert.filter(input => input.input == "scheduleEnd" && input.status === true).map(message => (
                                            <span className="text-red-500">{message.message}</span>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`mb-6 text-start w-full md:w-1/2 lg:w-1/3 px-2`}>
                            <label htmlFor="location" className="text-white mb-8 lg:text-sm xl:text-base">
                                Ubicación <span className="text-red-500">*</span>
                            </label>
                            {isLoading ? <Skeleton className="form-control block w-full mt-2 px-4 py-3 text-lg text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none" />
                            :
                            <input
                                type="text"
                                className="form-control block w-full mt-2 px-4 py-3 text-lg text-white font-normal text- bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none"
                                name="location"
                                value={infoPasantia.location}
                                onChange={handleInputPasantia}
                            />}
                            {
                                alert.filter(input => input.input == "location" && input.status === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))
                            }
                        </div>
                        <div className={`mb-6 text-start btn-editpro w-full px-2`}>
                            <label htmlFor="description" className="text-white mb-8 lg:text-sm xl:text-base">
                                Descripción de la pasantía <span className="text-red-500">*</span>
                            </label>
                            <div className="relative">
                                <Skeleton className={`${isLoading ? "block" : "hidden"} absolute w-full h-full`} />
                                <textarea
                                    className={`${isLoading ? "invisible" : "visible"} form-control block w-full mt-2 px-4 py-3 text-lg font-normal text-white bg-clip-padding border border-solid border-transparent rounded transition ease-in-out m-0 focus:border-Yellow focus:outline-none`}
                                    rows={4}
                                    placeholder="Descripción"
                                    name="description"
                                    value={infoPasantia.description}
                                    onChange={
                                        (e) => {
                                            if (e.target.value.length <= characterTextLength) {
                                                setLengthText(e.target.value.length);
                                                handleInputPasantia(e);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="text-[rgba(500,100,0,0.5)] italic">{infoPasantia.description.length} / {characterTextLength}</div>
                            {
                                alert.filter(input => input.input == "description" && input.status === true).map(message => (
                                    <span className="text-red-500">{message.message}</span>
                                ))
                            }
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-center text-justify md:text-left">
                        <div className="w-full md:w-1/3 flex items-center">

                            <div className="relative w-full">
                                <Skeleton className={`${isLoading ? "block" : "hidden"} absolute w-full h-full`} />
                                <button
                                    type="submit"
                                    className={`${isLoading ? "invisible" : "visible"} inline-block px-7 py-5 bg-Yellow text-white font-medium lg:text-sm xl:text-base leading-snug rounded shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out w-full text-center`}>

                                    {!sendingData &&
                                        <>
                                            {(typeForm == "update") && <>Actualizar pasantía</>}
                                            {(typeForm == "create") && <>Publicar pasantía</>}
                                        </>
                                    }

                                    {sendingData &&
                                        <IconLoading />
                                    }
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default FormPasantia;