import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import StudiesFreelancer from "../components/EditProfilefreelancer/studies/StudiesFreelancer";
import { Link } from "react-router-dom";
import { useState, useEffect, useCallback } from 'react';
import AddOtherStudies from "../components/EditProfilefreelancer/otherStudies/OtherStudies";
import Cookies from "universal-cookie";
import axios from "axios";
import SkeletonPaymentHistory from "../components/Skeleton/paymentHistory/paymentHistorySkeleton";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

const dataHistorial = { date: "", description: "", total: "" }

const initialHistorial = {
    date: "", 
    description: "", 
    total: ""
}

const ViewHistorialFacturacion = () => {

  const [data, setData] = useState([initialHistorial]);

  const [loaded, setLoaded] = useState(false);

  const [animateSkeletonHistory, setAnimateSkeletonHistory] = useState(true);

  // Cookies
  const cookies = new Cookies();

  const getSesion = cookies.get('Bearer');


  const [HistorialFacturacion, setHistorialFacturacion] = useState([dataHistorial]);

  useEffect(() => {
    async function getHistorial() {

      const urlData = `${process.env.REACT_APP_API_URI}payment/history/${getSesion.id}`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'ApiToken': `Bearer ${getSesion.token}`
        }
      };

      try {

        const resp = await axios.get(urlData, config);
        setHistorialFacturacion(resp.data);
        setData(resp.data);
        setTimeout(() => { setAnimateSkeletonHistory(false); }, 2000);
        
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    }
    getHistorial();
  }, []);

  const columns = [
    {
        name: 'Fecha',
        width: "20%",
        cell: (row: { date: string | any[]; }) => <b className="text-white">
            {row.date.slice(0,10)}
        </b>
    },
    {
        name: 'Descripción',
        width: "50%",
        cell: (row: { description: any; }) => <b className="text-white">{row.description}</b>
    },
    {
        name: 'Total',
        width: "30%",
        cell: (row: { total: any; }) => <b className="text-white">{row.total}</b>
    }
];

  const  tableData  =  { 
    columns, 
    data 
  };

  const defaultComponentOptions = {
    rowsPerPageText: 'Filas por pagina:',
    rangeSeparatorText: 'de',
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: 'All',
  };

  createTheme('table-chambaticon', {
    text: {
      primary: '#FF9200',
      secondary: '#FFFFFF',
    },
    background: {
      default: '#0C3149',
    },
    divider: {
      default: 'rgb(75 85 99)',
      height: "5px"
    },
    highlightOnHover: {
			default: '#0C3149',
			text: '#FFFFFF',
		},
  });

  return (
    <>

      {
        (animateSkeletonHistory) ? (
          <SkeletonPaymentHistory />
        ):(
          <div className="bg-gradient-to-b from-Light-purple lg:via-turquoise via-Light-purple to-turquoise">
            <section className="block -mt-px md:flex md:flex-wrap">
              <div className="hidden lg:flex w-1/5 py-14 bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">
                <img
                  className="opacity-50 py-10 h-1/2 lg:h-3/4 xl:h-5/6"
                  src={imagenesEditProject.logo_blanco_mitad}
                  alt=""
                />
              </div>
              <div className="w-full lg:w-4/5 md:mb-0 justify-start p-5 px-6">
                <div className="sm:w-5% ml-5 sm:ml-0">
                  <Link to="" onClick={() => window.history.go(-1)}>
                    <img
                      src={imagenesEditProject.arrow_back_4}
                      className="w-10% md:w-full md:mx-4"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="ml-5 mt-5">
                  <h2 className="text-white text-2xl md:text-3xl font-semibold">
                      Historial de suscripción y pago
                  </h2>
                  {/* <p className="text-Yellow text-lg md:text-xl">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Reprehenderit consequatur adipisci porro voluptas eum. Sunt,
                    sit! Perspiciatis rerum pariatur nobis nam quasi aperiam aut.
                    Culpa provident assumenda est veniam possimus.
                  </p> */}
                  <div className="p-5 sm:p-0 block w-full mt-5">
                    <div className="relative overflow-x-auto rounded-lg text-black">
                      <DataTableExtensions className="text-white bg-turquoise" {...tableData} filterPlaceholder={"Buscar"} print={false}
                          export={false}>
                          <DataTable
                          // conditionalRowStyles={conditionalRowStyles}
                              theme="table-chambaticon"
                              columns={columns}
                              data={data}
                              noHeader
                              paginationComponentOptions={defaultComponentOptions}
                              // defaultSortField="date"
                              // defaultSortAsc={false}
                              pagination
                              highlightOnHover
                              responsive
                              noDataComponent={<div className="my-5">No hay registros</div>}
                          />
                      </DataTableExtensions>
                    </div>
                  </div>
                </div>

              </div>
            </section>
          </div>
        )
      }

      

    </>
  );
};

export default ViewHistorialFacturacion;
