import axios from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate, Link, useParams } from "react-router-dom";
import IconLoading from "../../iconLoading/IconLoading";
import ExperienceInformal from "../../tabsExperience/ExperienceInformal";

import Select from 'react-select';

const initialStateSpecialties = {
    id: 0,
    name: "",
    created_at: "",
    updated_at: ""
}

const initialStateknowledge = {
    id: 0,
    name: "",
    type_id: 0
}

const initialStateId = {
    id: "0"
}

const initialknowledge = {
    knowledge_id: "0",
    level: "0"
}

const initialStateOption = {
    value: "",
    label: ""
};



// type
type handleInputChangeSpecialtie = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>

let checkedState: string[] = ["0"];

const PortfolioFreelance = () => {


    const [specialties, setStateSpecialties] = useState([initialStateSpecialties]);

    const [Specialty, setSpecialty] = useState(0);

    const [SelectedValues, setSelectedValues] = useState([]);

    const [optionStates, setOptionStates] = useState([initialStateOption]);

    const [idUser, setidUser] = useState<number>(0);

    const [sendingData, setSendingData] = useState(false);


    const MySwal = withReactContent(Swal);

    const navigate = useNavigate();

    // Cookies
    const cookies = new Cookies();

    const getSesion = cookies.get("Bearer");

    const params = useParams();

    const [paramsURL, setParamsURL] = useState<Number>()

    //  Get Specialties
    useEffect(() => {

        getIdUser();

        sendData();

        setParamsURL(Number(params.id))

    }, []);

    async function sendData() {

        const urlCountry = `${process.env.REACT_APP_API_URI}freelancer/complete-profile/${idUser}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        };

        const portfolio = {
            'user_id': idUser,
            'briefcase_link': 'briefcase_link'
        };

        try {

            const resp = await axios.post(urlCountry, config);

            console.log(resp)

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    };

    const getIdUser = () => {
        const { id } = cookies.get('Bearer');
        setidUser(id)
    }


    // event form
    const handleLoginSession = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        // Send data
        sendDataValidate();
    };

    const sendDataValidate = () => {
        setSendingData(true);
        const url = `${process.env.REACT_APP_API_URI}freelancer/register/Knowledge-specializations`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        }

        const knowledges = {
            'user_id': idUser,
            'knowledges': SelectedValues
        };

        axios.post(url, knowledges, config)
            .then(response => {
                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                }).then(() => {
                    navigate('/perfil-freelancer');
                });

                setSendingData(false);

            })
            .catch(err => {
                console.log(err)
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: err.response.data.log,
                    showConfirmButton: false,
                    timer: 3500
                });

                setSendingData(false);
            })

    }

    // Handle Values Select
    const selectInputRef = useRef<any>();

    async function knowledged(ids: string) {

        setOptionStates([initialStateOption]);
        // get Session
        const getSesion = cookies.get('Bearer');

        const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/Knowledge/${ids}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`,
            }
        };

        try{

            const resp = await axios.get(urlCountry, config);

            if (resp.data.data.length > 0) {

                resp.data.data.map((infoData: any) => {

                    setOptionStates(current => [...current, { value: infoData.id, label: infoData.name }]);

                });


            }

        } catch (err) {
            console.error(err);
        };
    };

    return (
        <div className="w-full md:w-95% flex justify-center">
            <div className="w-full md:w-95%">
                <div className="flex justify-center">
                    <h2 className="text-Yellow text-2xl md:text-3xl text-center font-semibold py-2 md:py-8">
                        {paramsURL == 0 ? "Registrar portafolio" : "Actualizar portafolio"}
                    </h2>
                </div>
                <div className="">
                    <div className="mb-6 text-start">
                        <ExperienceInformal idFreelance={idUser} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PortfolioFreelance;


