import imagenesDetallesProject from "../assets/imgDetallesProject/imgDetallesProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faDownload, faEnvelope, faMoneyBill, faUser, faComment } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import React from "react";
import imagenesEditProject from "../assets/imgEditProject/imgEditProject";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import fileDownload from "js-file-download";
import imgDefault from '../assets/imgEditPerfil/user.png';
import ProposalSkeleton from "../components/Skeleton/Proposal/ProposalSkeleton";
import ProposalSkeletonList from "../components/Skeleton/Proposal/ProposalSkeleton";
import IconLoading from "../components/iconLoading/IconLoading";
import IconLoadingWhite from "../components/iconLoading/IconLoadingWhite";
import Modal from "../components/modals/ModalFreelancerPropuse";
import { infoProposal } from "../Interfaces/infoProposal";
import ModalStar from "../components/modals/modalStar";
import { StarRating } from 'star-rating-react-ts';
import ProposalOfProject from "../components/proposals/Proposal";
import Cookies from "universal-cookie";

const initialStateProposal = {
    nameProject: "",
    gmailFreelancer: "",
    amount: "",
    dateOfDelivery: "",
    file: "",
    photo: "",
    freelace_id: "",
    state: "",
    proposal_id: "",
    names: "",
    lastnames: "",
    description: ''
};

const initialStatePropos = {
    description: '',
    photo: '',
    names: '',
    lastanames: '',
    id_freelance: ''
};

const statusOpportunities: { [key: number]: string } = {
    1: "Activo",
    2: "Inactivo",
    3: "Eliminado",
    4: "En proceso",
    5: "Finalizado",
    6: "Cancelado",
    7: "Atrasado",
    8: "No disponible",
};

const DetallesProject = () => {
    const [animateProposal, setanimateProposal] = useState({
        animate: false,
        proposal: "0"
    });
    const [qualifyFreelancer, setQualifyFreelancer] = useState({
        star: false,
        proposalID: "0"
    });
    const themeStar = {
        colors: {
            backgroundDefault: 'lightgray',
            backgroundColorActive: '#FF9200',
            backgroundColorHover: '#FF9200',
        },
        size: 30,
    }

    const initInternship = {
        application_deadline: "",
        company_uuid: "",
        description: "",
        end_date: "",
        id: "",
        id_type_internship: "",
        id_type_opportunities: "",
        id_work_modality: "",
        job_title: "",
        location: "",
        remuneration: "",
        schedules: "",
        start_date: "",
        status: 0,
        user_name: ""
    }

    const initJobVacancy = {
        name: "",
        status: "",
    }
    
    const initProfessionalService = {
        name: "",
        status: "",
    }
    
    const initProject = {
        name: "",
        status: "",
    }

    type opportunityType = 
    { 
        application_deadline: string,
        company_uuid: string,
        description: string,
        end_date: string,
        id: string,
        id_type_internship: string,
        id_type_opportunities: string,
        id_work_modality: string,
        job_title: string,
        location: string,
        remuneration: string,
        schedules: string,
        start_date: string,
        status: number,
        user_name: string
    } | 
    { 
        id: string
        name: string; 
        status: number 
    } |
    { 
        id: string
        job_title: string; 
        status: number 
    } |
    { 
        id: string
        project_name: string; 
        status: number 
    };

    const typeInitOpportunity: { [key: number]: any } = {
        1: initInternship,
        2: initJobVacancy,
        3: initProfessionalService,
        4: initProject,
      };

    const params = useParams();
    const MySwal = withReactContent(Swal);
    const [ListProposal, setListProposal] = useState([]);
    const [MessageDefault, setMessageDefault] = useState(false);
    const [IsLoading, setIsLoading] = useState(true);
    const [NameProject, setNameProject] = useState("");
    const [assignedStatus, setAssignedStatus] = useState();
    const [StatusProject, setStatusProject] = useState("3");
    const [GetIdProyecto, setGetIdProyecto] = useState(params.id);
    const [sendingData, setSendingData] = useState(false);
    const [addClassModal, setAddClassModal] = useState("modalCloseProject");
    const [IsLoadingModal, setIsLoadingModal] = useState(false);
    const [star, setStar] = useState(false);
    const [qStar, setQStar] = useState(0);
    const [idProject, setIdProject] = useState(0);
    const [paymentProject, setpaymentProject] = useState(0);
    const [newMessageCaht, setNewMessageCaht] = useState(0);
    const [infoProposal, setInfoProposal] = useState<infoProposal>(initialStatePropos);
    const [dataProposal, setDataProposal] = useState([]);
    const [selectedCards, setSelectedCards] = useState<number[]>([]);
    const [dataWork, setDataWork] = useState<opportunityType | null>(null);
    const cookies = new Cookies();
    const getSesion = cookies.get('Bearer');

    const RechazadaProposal = async (idProposal: string) => {
        MySwal.fire({
            title: 'Esta seguro de rechazar esta propuesta',
            icon: 'question',
            showDenyButton: true,
            confirmButtonText: 'Rechazar',
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let animate = {
                    animate: true,
                    proposal: idProposal
                };
                setanimateProposal(animate);
                const urlProposal = `${process.env.REACT_APP_API_URI}company/deny-proposal/${idProposal}`;
                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'ApiToken': `Bearer ${getSesion.token}`
                    }
                };
                try {
                    const resp = await axios.post(urlProposal, { id_type_opportunities: params.type }, config);
                    MySwal.fire({
                        position: 'center',
                        icon: 'success',
                        title: resp.data.message,
                        showConfirmButton: false,
                        timer: 3500
                    }).then(() => {
                        getProposalFree();
                    });
                    let animate = {
                        animate: false,
                        proposal: idProposal
                    };
                    setanimateProposal(animate);
                } catch (err) {
                    console.log(err);
                    let animate = {
                        animate: false,
                        proposal: idProposal
                    };
                    setanimateProposal(animate);
                }
            } else if (result.isDenied) {
                // Swal.fire('Propuesta cancelada', '', 'warning');
            }
        });

    };

    const ProjectFinishQ = (idProposal: string, idProject: any, idFreelancer: string) => {
        let animate = { animate: true, proposal: idProposal };
        setanimateProposal(animate);
        setTimeout(() => {
            let animate = { animate: false, proposal: idProposal };
            setanimateProposal(animate);
            let qualify = { star: true, proposalID: idProposal };
            setQualifyFreelancer(qualify);
        }, 3000);

    };

    const ProjectFinish = async (proposalID: string) => {
        MySwal.fire({
            title: 'Esta seguro de finalizar la oportunidad',
            icon: 'question',
            showDenyButton: true,
            confirmButtonText: 'Aceptar',
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            const urlFinishProject = `${process.env.REACT_APP_API_URI}project/complete-project/${proposalID}?id_type_opportunities=${params.type}`;
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ApiToken': `Bearer ${getSesion.token}`
                }
            };
            try {
                const resp = await axios.post(urlFinishProject, config);
                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: resp.data.message,
                    showConfirmButton: false,
                    timer: 2500
                });
                getProposalFree();
            } catch (err: any) {
                console.error(err);
            }
        });
    }

    async function getProposalFree() {
        const urlInfoPrject = `${process.env.REACT_APP_API_URI}company/get/project-proposals/${params.id}?id_type_opportunities=${params.type}`;
        console.log(urlInfoPrject);
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        };
        try {
            await axios.get(urlInfoPrject, config)
                .then(resp => {
                    console.log("=)", resp);
                    setDataWork(resp.data.opportunity);
                    resp.data.opportunity.status !== 5 && resp.data.opportunity.status !== 4 && setDataProposal(resp.data.data);
                    resp.data.opportunity.status === 5 && setDataProposal(resp.data.data.filter((freelance: any) => freelance.status_assigned === 3));
                    resp.data.opportunity.status === 4 && setDataProposal(resp.data.data.filter((freelance: any) => freelance.status_assigned === 1))
                    setIsLoading(false);
                })
                .catch(error => {
                    console.log(error.response);
                });
        } catch (err) {
            console.error(err);
        };
    };

    async function getNewMessageChat() {

        const urlInfoPrject = `${process.env.REACT_APP_API_URI}company/get/project-proposals/${params.id}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        };

        try {

            const resp = await axios.get(urlInfoPrject, config);

            if (resp.data.data.length != 0) {

                setNewMessageCaht(resp.data.project.newMessages);

            } else {
                setSendingData(false);
                setMessageDefault(true);
            };

        } catch (err) {
            console.error(err);

        }
    };

    const handleDocumentProposal = (url: string, filename: string) => {

        setSendingData(true);

        axios({
            url: url,
            method: "GET",
            responseType: 'blob'
        }).then(res => {

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            setSendingData(false);

        }).catch(function (error) {

            setSendingData(false);

            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: 'Error, al descargar el documento, vuelve a intentar más tarde.',
                showConfirmButton: false,
                timer: 3500
            });

        })

    };

    const other = (state: Boolean) => {
        // console.log("CERRAR");
        if (state) {
            setAddClassModal("modalOpenProject");
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
        } else {
            setAddClassModal("modalCloseProject");
            // console.log("CERRAR");
            document.getElementsByTagName("body")[0].style.overflow = "visible";
        }
        setIsLoadingModal(true);
        setInterval(() => {
            setIsLoadingModal(false);
        }, 1000);
    }

    const showModalProject = (description: string, photo: string, names: string, lastnames: string, id_freelance: string) => {

        setInfoProposal({
            description: description,
            photo: photo,
            names: names,
            lastanames: lastnames,
            id_freelance: id_freelance
        });

    };

    const sendQualification = (id: string, proposalID: string) => {

        let animate = { animate: true, proposal: proposalID };

        setanimateProposal(animate);

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ApiToken': `Bearer ${getSesion.token}`
            }
        };

        const data = {
            project: GetIdProyecto,
            rating: qStar
        };

        const urlQ = `${process.env.REACT_APP_API_URI}rating/freelancer/${id}`;

        const response = axios.post(urlQ, data, config);

        response.then(res => {

            if (res.data.message == "Calificación registrada.") {

                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Calificación registrada exitosamente',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    ProjectFinish(proposalID)
                });
            }
        })
        response.catch(err => {

            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 3500
            }).then(() => {
                let animate = { animate: false, proposal: proposalID };
                setanimateProposal(animate);
            });
        });
    };

    const getQ = (e: number) => { setQStar(e) };

    const acceptProposal = (idProposal: string) => {
        MySwal.fire({
            title: 'Esta seguro de aceptar la propuesta de los talento',
            icon: 'question',
            showDenyButton: true,
            confirmButtonText: 'Aceptar',
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let animate = {
                    animate: true,
                    proposal: idProposal
                };
                // setanimateProposal(animate);

                const urlAcceptProposal = `${process.env.REACT_APP_API_URI}company/accept-proposal/${idProposal}?id_type_opportunities=${params.type}`;
                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'ApiToken': `Bearer ${getSesion.token}`
                    }
                };
                try {
                    const ids = selectedCards.map((obj: any) => obj.id);
                    await axios.post(urlAcceptProposal, { freelancer_ids: ids }, config)
                        .then((resp) => {
                            getProposalFree();
                            setSelectedCards([]);
                            MySwal.fire({
                                position: 'center',
                                icon: 'success',
                                title: resp.data.message,
                                showConfirmButton: false,
                                timer: 3500
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                } catch (err: any) {
                    console.error(err);
                    let animate = {
                        animate: false,
                        proposal: idProposal
                    };
                    // setanimateProposal(animate);

                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        title: err.response.data.error,
                        showConfirmButton: false,
                        timer: 3500
                    });
                }
            } else if (result.isDenied) {
                Swal.fire('El aceptar propuestas se ha cancelado', '', 'info');
            }
        });
    };

     //Handel error img profile not found
   const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        (e.target as HTMLImageElement).src = imgDefault;  // Si la imagen no carga, usamos la imagen por defecto
    }

    useEffect(() => {
        getProposalFree();
    }, []);

    return (
        <>
            <div className="bg-turquoise">
                <section className="block -mt-px md:flex md:flex-wrap">
                    <div className="hidden lg:flex w-10% bg-gradient-to-b from-Light-purple via-turquoise to-turquoise justify-end">
                        <img className="" src={imagenesDetallesProject.fondo_cadena} alt="" />
                    </div>
                    <div className="md:w-full lg:w-90% flex flex-wrap pb-5 lg:py-5">
                        <div className="w-full sm:w-5% mt-4 lg:mt-1">
                            <Link to="" onClick={() => window.history.go(-1)}>
                                <img src={imagenesEditProject.arrow_back_4} className="w-10% md:w-full mx-4" alt="" />
                            </Link>
                        </div>
                        <div className="mx-8 sm:w-85%">
                            <div className=" pb-2 lg:pb-4 mt-4 lg:mt-0 2xl:p-2">
                                <h1 className="text-Yellow font-bold text-xl sm:text-2xl">
                                    { dataWork?.status === 1 ? 'Propuestas de la oportunidad: ' : 'Oportunidad: '} 
                                    { dataWork && ("job_title" in dataWork && (dataWork.job_title))}
                                    { dataWork && ("name" in dataWork && (dataWork.name))}
                                    { dataWork && ("project_name" in dataWork && (dataWork.project_name))}
                                </h1>
                                <h6 className="text-white">Estado: {statusOpportunities[dataWork?.status || '8']}
                                </h6>
                                { dataWork?.status === 4 &&
                                    <button onClick={() => ProjectFinish(dataWork?.id)} className="h-9 flex justify-center bg-purple-cards items-center text-center font-roboto  xl:text-base text-white rounded px-4 md:px-2 lg:px-4 py-2 mt-4 cursor-pointer">
                                        Completar oportunidad   
                                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                                    </button>
                                }
                                {/* <br /> */}
                                {/* <h1 className="text-Yellow font-bold text-xl sm:text-3xl">Estado del proyecto: {assignedStatus}</h1> */}
                            </div>
                            {/* <div className="columns-1 sm:columns-2 md:my-2 lg:columns-4 flex justify-strat 2xl:p-2"> */}
                            {/* <div className="columns-1 sm:columns-2 md:my-2 lg:columns-4 flex justify-strat">
                                {(Number(dataWork.status) == 2) &&
                                    <Link
                                        to={`/actualizar-proyecto/${params.id}`}
                                        className="inline-block w-95% sm:w-full md:w-1/4 px-5 py-2 lg:py-3 bg-Yellow text-white font-medium text-xs sm:text-sm xl:text-base text-center leading-snug rounded shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out"
                                    >
                                        Editar Proyecto
                                    </Link>
                                }

                                {(Number(dataWork.status) == 1) &&
                                    <Link
                                        to={`/informacion-proyecto/${params.id}`}
                                        className="inline-block w-95% sm:w-full md:w-1/4 px-5 py-2 lg:py-3 bg-Yellow text-white font-medium text-xs sm:text-sm xl:text-base text-center leading-snug rounded shadow-md hover:bg-amber-600 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg transition duration-150 ease-in-out"
                                    >
                                        Ver información de proyecto
                                    </Link>
                                }

                            </div> */}

                            {/* {
                                (MessageDefault) &&
                                <p className="text-white text-center text-xl my-16">No tienes propuesta de Freelancer en este proyecto </p>
                            } */}
                            <section>
                                <div className="mb-4">
                                    {selectedCards.length > 0 &&
                                        <div className="flex items-center">
                                            <h2 className="text-xl font-semibold mb-2 text-white">Talentos seleccionados</h2>
                                            <button onClick={() => acceptProposal(dataWork?.id || '8')} className="h-9 flex justify-center bg-purple-cards items-center text-center font-roboto  xl:text-base text-white rounded px-4 md:px-2 lg:px-4 py-2 m-2 cursor-pointer">
                                                Asignar
                                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    }
                                    <div className="flex w-full gap-4 overflow-x-auto">
                                        {selectedCards.map((proposal: any) => {
                                            return <div className={`w-1/2`}>
                                                <div className="flex flex-wrap">
                                                    <div className="my-2 w-full pr-2 2xl:p-2">
                                                        <div className={` ${proposal.state == "1" ? "bg-purple-cards" : "bg-Dark-purple"} border border-gray-300 rounded flex flex-wrap py-4 px-4 sm:px-4`}>
                                                            <div className="flex justify-center mx-2 items-center w-full md:w-1/4 xl:w-22% 2xl:w-30%">
                                                                <Link to={`/freelancer/${proposal.freelancer_id}`} target="_blank">
                                                                    <img className="w-20 h-20 rounded-full object-cover object-center" onError={handleError} src={proposal.photo ? `${process.env.REACT_APP_PHOTO_REGISTRO_URI}photo/student/${proposal.photo}` : imgDefault} style={{ maxWidth: "190px" }} alt="Foto del usuario" />
                                                                    {/* <img src={`${process.env.REACT_APP_PHOTO_URI}freelancer/profile-img/${proposal.photo}`} className="h-24 w-24 md:h-16 md:w-16 2xl:w-24 2xl:h-24 rounded-full overflow-hidden object-cover object-center" alt="" /> */}
                                                                </Link>
                                                            </div>
                                                            <div className="w-full px-2 md:w-65% xl:w-65% 2xl:w-3/5">
                                                                {/* <h1 className="text-white font-roboto font-bold text-basic h-9 w-full overflow-hidden py-2">{proposal.nameProject}</h1> */}

                                                                <h3 className="text-white text-sm flex h-6 w-full overflow-hidden mt-4 truncate">
                                                                    <FontAwesomeIcon className="pr-1" icon={faUser} />
                                                                    <span className="text-white">
                                                                        {proposal?.freelancer?.user?.names} {proposal?.status_assigned} {proposal?.freelancer?.user?.lastnames}
                                                                    </span>
                                                                </h3>
                                                                <h3 className="text-white text-sm truncate">
                                                                    <FontAwesomeIcon className="pr-1" icon={faEnvelope} />
                                                                    <span className="text-white">{proposal?.freelancer?.user?.email}</span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </section>
                            <div className="grid md:gap-4 lg:gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-2">
                                <>
                                    {
                                        IsLoading
                                            ? <ProposalSkeletonList cards={6} />
                                            : dataProposal.length > 0
                                            &&
                                            dataProposal.map((proposal) => {
                                                return (
                                                    <ProposalOfProject
                                                        proposal={proposal}
                                                        dataWork={dataWork}
                                                        opportunityType={params.type}
                                                        getProposalFree={getProposalFree}
                                                        setSelectedCards={setSelectedCards}
                                                        selectedCards={selectedCards}
                                                    />
                                                )
                                            })
                                    }
                                </>
                            </div>
                            {dataProposal.length === 0 && <p className="text-white text-xl my-16 text-center w-full">No tienes propuestas en este proyecto.</p>}
                        </div>
                    </div>
                </section>
            </div>
            {/* <Modal info={infoProposal} addClass={addClassModal} HandleModal={other} IsLoading={IsLoadingModal} /> */}
            {/* <ModalStar info={infoProposal} addClass={addClassModal} HandleModal={other} IsLoading={IsLoadingModal}/> */}
        </>
    )
}

export default DetallesProject;