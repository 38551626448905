// import { useState } from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';

// import Alumno from "./../../assets/eliezer_cuadrada.png";
// import Discord from "./icons/discord.png"
// import Phone from "./icons/telefono.png"
// import Mail from "./icons/correo.png"
// import Linkedin from "./icons/Linkedin.png"
// import Whatsapp from "./icons/whatsapp.png"
// import Twitter from "./icons/twitter.png"
// import Website from "./icons/website.png"
// import Telegram from "./icons/telegram.png"
// import Facebook from "./icons/facebook.png"
// import Github from "./icons/github.png"
import RobotoRegular from "./Fonts/Roboto-Regular.ttf"
import RobotoBold from "./Fonts/Roboto-Bold.ttf"
import { useState } from 'react';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: RobotoRegular
      },
      {
        src: RobotoBold,
        fontWeight: 'bold'
      },
    ]
  })

const CVPDF = () => {

    // const [infoCV, setInfoCV] = useState();
    const [loaded, setLoaded] = useState(false);

    const getSesion = Cookies.get("Bearer");

    const [photo, setPhoto] = useState("");

    console.log(getSesion, "Sesión")

    const [experiences, setExperiences] = useState([]);
    const [general, setGeneral] = useState({
        about_me: "",
        age: 0,
        birth_date: "",
        city: "",
        country: "",
        direction: "",
        dui: "",
        email: "",
        lastnames: "",
        names: "",
        phone: "",
        state: "",
    });
    const [knowledge, setKnowledge] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [otherStudies, setOtherStudies] = useState([]);
    const [studies, setStudies] = useState([]);

    const styles = StyleSheet.create({
        page: {
            backgroundColor: "#fff",
            color: "black",
            fontSize: 12,
            lineHeight: 1.5,
            padding: 15,
            position: "relative",
            width: "200px", 
            height: "200px", 
            fontFamily: 'Roboto',
            overflow: "hidden",
            paddingBottom: 55,
        },
        container: {
            // flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            // marginBottom: 0,
            // padding: 0,
        },
        section: {
            margin: 15,
            padding: 15,
        },
        date: {
            margin: 15,
            marginTop: 50,
            padding: 15,
            textAlign: "right",
        },
        destinatario: {
            margin: 15,
            marginTop: 20,
            padding: 15,
        },
        parrafo: {
            margin: 15,
            marginTop: 3,
            padding: 15,
            textAlign: "justify"
        },
        atentamente: {
            margin: 15,
            marginTop: 50,
            padding: 15,
        },
        viewer: {
            width: "100%", 
            height: window.innerHeight,            
        },
        columnas:{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            backgroundColor: "#0D021E",
            borderTopLeftRadius: 50,
            zIndex: 1,
            height: "100%",
        },
        columnLeft:{
            width: "40%",
            height: "100%",
            display: "flex",
            marginTop: 40,
            paddingBottom: 40,
            backgroundColor: "#0D021E",
            borderTopLeftRadius: 50,
            borderBottomRightRadius: 40,
        },
        columnRight:{
            width: "60%",
            height: "100%",
            padding: 40,
            backgroundColor: "white",
            marginTop: 30,
            borderTopLeftRadius: 50, 
            textAlign: "left",
        },
        nombreBeneficiario:{
            fontSize: loaded && general.names.length > 14 || loaded && general.lastnames.length > 14 ? 20 : 30,
            textTransform: "uppercase",
            textAlign: "left",
            fontWeight: "bold",
        },
        gradoAcademico:{
            fontSize: 14,
            // fontWeight: "normal",
        },
        imgRounded:{
            width: 200,
            height: 200,
            overflow: "hidden",
            position: "relative",
            borderRadius: "50%", 
            border: "5px solid #ee9218",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ee9218",
        },
        wave:{
            width: "100px",
            height: "100px",
            overflow: "hidden",
            position: "absolute",
            top: 0,
            left: -55,
            zIndex: 100,
            backgroundColor: "white",
            borderBottomRightRadius: 50,
        },
        waveBlanca:{
            width: "100px",
            height: "100px",
            overflow: "hidden",
            position: "absolute",
            top: -40,
            left: 38,
            zIndex: 100,
            backgroundColor: "white",
        },
        waveAzul:{
            width: "110px",
            height: "100px",
            overflow: "hidden",
            position: "absolute",
            top: 0,
            left: 40,
            zIndex: 100,
            backgroundColor: "#004A98",
            borderTopLeftRadius: 40,
        },
        titulosAmarillos:{
            color: "#ee9218",   
            fontSize: 10,
            textTransform: "uppercase",
            marginTop: 15,
            fontWeight: "bold",
            textAlign: "left",
        },
        titulosAzulesSubrayados:{
            color: "#004A98",   
            fontSize: 10,
            // textDecoration: "underline",
            // textDecorationColor: "#ee9218",
            textTransform: "uppercase",
            marginTop: 15,
            fontWeight: "bold",
            textAlign: "left",
        },
        parrafosBlancos:{
            color: "#fff",   
            fontSize: 9,
            marginTop: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        viewInfoEstudianteLeft:{
            padding: 20,
        },
        burbuja:{
            width: "auto",
            minWidth: "30px",
            marginBottom: "2px",
            marginTop: "2px",
            marginRight: "2px",
            paddingHorizontal: "10px",
            paddingVertical: "6px",
            backgroundColor: "#004A98",
            color: "#fff",
            borderRadius: 5,
            textAlign: "center",
            fontSize: 9,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        contenedorBurbujas:{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
        },
    });

    console.log(window.innerHeight);
    //Obtener dia, mes y año con el formato 00/00/0000
    let date = new Date();
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    // let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    // Obtenemos el mes en español
    let month = date.toLocaleString('es-ES', { month: 'long' });
    month = month.charAt(0).toUpperCase() + month.slice(1);

    let year = date.getFullYear();

    const navigate = useNavigate();

    // console.log(data, "Data del beneficiario")

    //Cambiar nombre del archivo PDF a descargar
    // let fileName = `Carta de Recomendación - ${props.data.names + " " + props.data.lastnames}.pdf`;

    useEffect(() => {
        getInfoCV();
        // if(data.photo === null){
        //     // Alerta sweet alert
        //     Swal.fire({
        //         title: "¡Error!",
        //         text: "Para poder generar el PDF es necesario que el beneficiario tenga una foto de perfil, se le redirigirá a la sección de editar beneficiario para que pueda agregar una foto de perfil.",
        //         icon: "error",
        //         button: "Aceptar",
        //     }).then((result) => {
        //         if (result.value) {
        //             window.location.href = `/EditarBeneficiario/${data.id}`;
        //         }
        //         window.location.href = `/EditarBeneficiario/${data.id}`;
        //     });
        // }
    }, []);

    async function getInfoCV() {
        const dui = Cookies.get("dui");
        const nit = Cookies.get("nit");
        setPhoto(sessionStorage.getItem("photo"))

        console.log(sessionStorage.getItem("photo"), "Foto de perfil")

        let url = "";

        let document = "";

        if(dui !== undefined){
            url = `${process.env.REACT_APP_API_URI}freelancer/resume/share-data?typeDocument=DUI`;
            document = dui;
        } else if (nit !== undefined){
            url = `${process.env.REACT_APP_API_URI}freelancer/resume/share-data?typeDocument=NIT`;
            document = nit;
        } else {
            Swal.fire({
                title: "¡Error!",
                text: "No se ha podido obtener la información del beneficiario, por favor intente de nuevo.",
                icon: "error",
                button: "Aceptar",
            }).then(() => {
                navigate("/perfil-freelancer");
            });
        }

        console.log(url, "URL")

        setLoaded(false);

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.ApiToken = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        let data = {
            document: document,
        }

        try {
            const resp = await axios.post(url, data, config);
            console.log(resp.data.data, "Respuesta de info CV")
            setExperiences(resp.data.data.experiences);
            setGeneral(resp.data.data.general);
            setKnowledge(resp.data.data.knowledges);
            setLanguages(resp.data.data.languages);
            setOtherStudies(resp.data.data.other_studies);
            setStudies(resp.data.data.studies);
            setLoaded(true);
            Cookies.remove("dui");
            Cookies.remove("nit");
            sessionStorage.removeItem("photo");
        } catch (err) {
            Cookies.remove("dui");
            Cookies.remove("nit");
            sessionStorage.removeItem("photo");
            console.error(err, "Error al obtener la información del beneficiario");
        }
    };

    const knokledgeSeparados = knowledge.map(objeto => objeto.name).join(', ');
    console.log(knokledgeSeparados, "Conocimientos separados")

    return (
        loaded
        &&
        <>
            <PDFViewer 
                style={styles.viewer}
            >
                <Document>
                    <Page size="letter" style={styles.page}>
                        <View style={styles.columnas}>
                            <View style={styles.columnLeft}>
                                <View style={{justifyContent: "center", alignItems: "center", position: "relative"}}>
                                    <View style={styles.imgRounded}>
                                        <Image src={`data:image/jpeg;base64,${photo}`} style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                            overflow: "hidden",
                                            objectPosition: "center top",
                                            objectFit: "cover",
                                        }} />
                                    </View>
                                </View>
                                <View style={styles.viewInfoEstudianteLeft}>
                                    <Text style={styles.titulosAmarillos}>
                                        Acerca de mi
                                    </Text>
                                    <Text style={styles.parrafosBlancos}>
                                        { loaded && general.about_me === null ? "No proporcionado" : general.about_me }
                                    </Text>

                                    {/* Si */}
                                    <Text style={styles.titulosAmarillos}>
                                        Información personal
                                    </Text>

                                    {/* No */}
                                    {/* <Text style={styles.parrafosBlancos}>
                                        ESTADO CIVIL: Soltero
                                    </Text> */}

                                    {/* Si */}
                                    <Text style={styles.parrafosBlancos}>
                                        {
                                            loaded 
                                            &&
                                            `DUI: ${general.dui === null ? "No proporcionado" : general.dui}`
                                        }
                                    </Text>
                                    <Text style={styles.parrafosBlancos}>
                                        FECHA DE NACIMIENTO:
                                    </Text>
                                    <Text style={styles.parrafosBlancos}>
                                        {
                                            loaded 
                                            &&
                                            general.birth_date !== null ? general.birth_date.split("-").reverse().join("/") : "No proporcionado"
                                        }
                                    </Text>

                                    {
                                        loaded && general.city !== null && general.state !== null
                                        &&
                                        <>
                                            <Text style={styles.parrafosBlancos}>
                                                RESIDENCIA:
                                            </Text>
                                            <Text style={styles.parrafosBlancos}>
                                                {
                                                    loaded 
                                                    &&
                                                    `${general.city}, ${general.state}`
                                                }
                                            </Text>
                                        </>
                                    }

                                    {/* No */}
                                    {/* <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <Image src={Phone} style={{width: "15px", marginRight: 7}} />
                                        <Text style={styles.parrafosBlancos}>
                                            (503) 7373 9393
                                        </Text>
                                    </View>
                                    <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <Image src={Mail} style={{width: "15px", marginRight: 7}} />
                                        <Link src='mailto:lopez.victor@gmail.com' style={styles.parrafosBlancos}>
                                            lopez.victor@gmail.com
                                        </Link>
                                    </View> */}
                                    {
                                        loaded && languages.length > 0
                                        &&
                                        <>
                                            <Text style={styles.titulosAmarillos}>
                                                Idiomas
                                            </Text>
                                            {
                                                languages.map((item, index) => {
                                                    return <Text key={index} style={{color: "white", fontSize: 9}}>
                                                        {item.name}
                                                    </Text>
                                                })
                                            } 
                                        </>
                                    }
                                    {/* <Text style={styles.titulosAmarillos}>
                                        REDES
                                    </Text>
                                    <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <Image src={Whatsapp} style={{width: "15px", marginRight: 7, borderRadius: "50%"}} />
                                        <Link src='https://wa.me/73201382' style={styles.parrafosBlancos}>
                                            7320 1382
                                        </Link>
                                    </View>
                                    <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <Image src={Telegram} style={{width: "15px", marginRight: 7, borderRadius: "50%"}} />
                                        <Text style={styles.parrafosBlancos}>
                                            @victorlopez13
                                        </Text>
                                    </View>
                                    <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <Image src={Github} style={{width: "15px", marginRight: 7, borderRadius: "50%"}} />
                                        <Text style={styles.parrafosBlancos}>
                                            @victorlopez13
                                        </Text>
                                    </View>
                                    <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <Image src={Facebook} style={{width: "15px", marginRight: 7, borderRadius: "50%"}} />
                                        <Text style={styles.parrafosBlancos}>
                                            Victor Lopez
                                        </Text>
                                    </View>
                                    <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <Image src={Twitter} style={{width: "13px", marginRight: 7}} />
                                        <Text style={styles.parrafosBlancos}>
                                            @victorlopez13
                                        </Text>
                                    </View>
                                    <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <Image src={Discord} style={{width: "15px", marginRight: 7}} />
                                        <Text style={styles.parrafosBlancos}>
                                            @victorlopez13
                                        </Text>
                                    </View>
                                    <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <Image src={Linkedin} style={{width: "15px", marginRight: 7, borderRadius: "50%"}} />
                                        <Text style={styles.parrafosBlancos}>
                                            @victorlopez13
                                        </Text>
                                    </View>
                                    <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <Image src={Website} style={{width: "15px", marginRight: 7, borderRadius: "50%"}} />
                                        <Text style={styles.parrafosBlancos}>
                                            @victorlopez13
                                        </Text>
                                    </View> */}

                                    {/* Si */}
                                    {
                                        <>
                                            <Text style={styles.titulosAmarillos}>
                                                APTITUDES
                                            </Text>
                                            {
                                                // data.soft_skills.map((item, index) => {
                                                //     return(
                                                <>
                                                    <Text style={{color: "white", fontSize: 9}}>
                                                        Trabajo en equipo
                                                    </Text>
                                                    <Text style={{color: "white", fontSize: 9}}>
                                                        Resolucion de problemas
                                                    </Text>
                                                    <Text style={{color: "white", fontSize: 9}}>
                                                        Manejo del tiempo 
                                                    </Text>
                                                    <Text style={{color: "white", fontSize: 9}}>
                                                        Manejo de estres
                                                    </Text>
                                                </>
                                                //     )
                                                // })
                                            }
                                        </>
                                    } 
                                </View>
                            </View>
                            <View style={styles.columnRight}>

                                {/* Si */}
                                <Text style={styles.nombreBeneficiario}>
                                    {general.names}
                                </Text>
                                <Text style={styles.nombreBeneficiario}>
                                    {general.lastnames}
                                </Text>

                                {/* No */}
                                {/* <Text style={styles.gradoAcademico}>
                                    Técnico en Ingeniería en Computación
                                </Text> */}

                                {/* Si */}
                                {
                                    studies.length > 0
                                    &&
                                    <>
                                        <Text style={styles.titulosAzulesSubrayados}>
                                            Formación
                                        </Text>
                                        {studies.map((item, index) => {
                                            return(
                                                <>
                                                    <Text style={{
                                                        fontWeight: "bold",
                                                        fontSize: 9,
                                                        marginTop: 5,
                                                    }}>
                                                        {item.study_degree}
                                                    </Text>
                                                    <Text style={{
                                                        fontSize: 9,
                                                    }}>
                                                        {item.institution}
                                                    </Text>
                                                    <Text style={{
                                                        fontSize: 9,
                                                    }}>
                                                        {item.full_emision_year}
                                                    </Text>
                                                </>
                                            )
                                        })}
                                    </>
                                }
                                {
                                    experiences.length > 0
                                    &&
                                    <>
                                        <Text style={styles.titulosAzulesSubrayados}>
                                            Experiencia
                                        </Text>
                                        {experiences.map((item, index) => {
                                            return(
                                                <>
                                                    <Text style={{
                                                        fontWeight: "bold",
                                                        fontSize: 9,
                                                        marginTop: 5,
                                                    }}>
                                                        {item.cargo}
                                                    </Text>
                                                    <Text style={{
                                                        fontSize: 9,
                                                    }}>
                                                        {item.nombre_empresa}
                                                    </Text>
                                                    <Text style={{
                                                        fontSize: 9,
                                                    }}>
                                                        {item.full_start_date} - {item.full_end_date}
                                                    </Text>
                                                </>
                                            )
                                        })}
                                    </>
                                }
                                {
                                    otherStudies.length > 0 
                                    &&
                                    <>
                                        <Text style={styles.titulosAzulesSubrayados}>
                                            Otros estudios
                                        </Text>
                                        {otherStudies.map((item, index) => {
                                            return(
                                                <>
                                                    <Text style={{
                                                        fontWeight: "bold",
                                                        fontSize: 9,
                                                        marginTop: 5,
                                                    }}>
                                                        {item.study_degree}
                                                    </Text>
                                                    <Text style={{
                                                        fontSize: 9,
                                                    }}>
                                                        {item.institution}
                                                    </Text>
                                                    <Text style={{
                                                        fontSize: 9,
                                                    }}>
                                                        {item.full_emision_year}
                                                    </Text>
                                                </>
                                            )
                                        })}
                                    </>
                                }   
                                {
                                    knowledge.length > 0
                                    &&
                                    <>
                                        <Text style={styles.titulosAzulesSubrayados}>
                                            Conocimientos
                                        </Text>
                                        <View style={{flexDirection: "column", fontSize: 9}}>
                                            <View style={{ flexDirection: "row", marginBottom: 2 }}>
                                                <Text>{knokledgeSeparados}.</Text>
                                            </View>
                                        </View>  
                                    </>
                                } 
                            </View>
                            {/* <View style={styles.wave}></View>
                            <View style={styles.waveBlanca}></View>
                            <View style={styles.waveAzul}></View> */}
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </>
      );
}

export default CVPDF;