import { BrowserRouter, Route, Routes, Link, Navigate } from 'react-router-dom'

// @ts-ignore
import { Dots as Preloader } from 'react-preloaders2';

// Views
import Home from '../../views/home';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faUpDown, faUpLong } from "@fortawesome/free-solid-svg-icons";



// Login y registro
import LoginMain from '../../views/Login';
import SignIn from '../../views/SignIn';
import RegistroFreelancer from '../../views/RegistroFreelancer';
import RegistroEmpresa from '../../views/RegistroEmpresa';


// Freelancer
import CreacionProfileFreelancer from '../../views/CreacionProfileFreelancer';
import EditPerfil from '../../views/EditPerfil';
import EditSkill from '../../views/EditSkill';
import EditExpLaboral from '../../views/EditExpLaboral';
import EditAcademica from '../../views/EditAcademica';
import ListFreelance from '../../views/ListFreelance';
import ViewHistorialFacturacion from '../../views/historialFacturacion';
import RegisterPortfolio from '../../views/RegisterPortfolio';

// Empresa
import CreacionProfileEmpresa from '../../views/CreacionProfileEmpresa';
import EditEmpresa from '../EditorProfileCompany/EditEmpresa';

// Proyecto
import PublishProject from '../../views/PublishProject';
import ListProject from '../../views/ListProject';

// Proyecto empresa
import ListProProEmp from '../../views/ListProProEmp';
import DetallesProject from '../../views/DetallesProject';
import ChatProjects from '../../views/chatProjects';
import EditProject from '../../views/EditProject';
import UpdatedProjectCompany from '../createProject/updatedProyect';

// Proyecto Freelancer
import AplyProFree from '../../views/AplyProFree';


// Paginas estaticas
import PoliticasDeprivacidad from '../../views/Politicadeprivacidad';
import Contactanos from '../../views/Contactanos';


// Compoenents
import Navbar from "../Navbar/Navbar";
import QuienSomos from '../../views/QuienSomos';
import PoliticasdeChambaticon from '../../views/PoliticasdeChambaticon';
import TerminosDeServicio from '../../views/TerminosDeServicio';
import PasswordRecovery from '../../views/passwordRecovery';
import FreelancerPublicProfile from '../FreelancerPublicProfile/FreelancerPublicProfile';
import Error404 from '../../views/Error404';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Footer from '../Footer/Footer';
import Planes from '../../views/Planes';
import PurchasePlans from '../../views/PurchasePlans';

// @ts-ignore
import ScrollToTop from "react-scroll-to-top";
import UpdatedProjectView from '../../views/UpdatedViewProject';
import ScrollTop from '../scrollTop/scrollTop';
import LoginEmpresa from '../../views/LoginEmpresa';
import AddOtherStudies from '../EditProfilefreelancer/otherStudies/OtherStudies';
import ViewOtherStudies from '../../views/addOtherStudies';
import ViewDetallesSuscripcion from '../../views/DetallesSuscripcion';
import PerfilPago from '../perfilDePago/perfilPago';
import PagoFreelancer from '../../views/Pago-freelancer';
import PaymentForm from '../../views/PaymentForm';
import ListFreelanceCategory from '../../views/ListCategoriFreelancer';
import ListProjectCategory from '../../views/ListProjectCategory';
import SeeProjectInformation from '../../views/SeeProjectInformacion';
import FreelancerShareProfile from '../FreelancerShareProfile/FreelancerShareProfile';
import Employer from '../../views/Employer';
import RegisterEmployer from '../../views/RegisterEmployer';
import EditProfileEmployer from "../../views/EditProfileEmployer";


import Cookies from 'universal-cookie';
import { HandleSession } from '../../function/Login';
import PrivateRoute from './ProtectedRoute';
import ValidateRouteProfile from '../../views/validateRouterProfile';
import { ProtectedRoute } from '../ProtectedRoute/ProtectedRoute';
import { useContext, useEffect, useState } from 'react';
import SessionContentChambaticon from '../ProtectedRoute/contenText';
import CreatePasswordUser from '../../views/createPasswordUser';
import Certificado from '../../views/certificadoFindi';
import CVPDF from '../CVPDF/CVPDF';
import PolIticadeCookies from '../../views/PolIticadeCookies';
import TerminosYCondiciones from '../../views/TerminosYCondiciones';
import PerfilPublicoTalento from '../perfilPublicoFreelancer/PerfilPublicoFreelancer';
import SelectProyecto from '../createProject/selectProyecto';
import OpportunityInformation from '../../views/InfoInternships';
import InfoServicesProfessional from '../../views/servicesProfessional';
import InformactionProject from '../../views/infoProyect';
import EditarOportunidad from '../createProject/editarServicioProfesional';
import InformacionPlaza from '../../views/InformacionPlaza';



const RouterApp = () => {

   const chambaticonSession = useContext(SessionContentChambaticon);

   return (
      <>
         <SkeletonTheme baseColor="#241145" highlightColor="#0F2C48">
            <BrowserRouter>

               <Navbar />

               <div className="content">
                  <Routes>

                     <Route path="/" element={<Home />} />

                     {/* Login y registro */}
                     <Route path='/Login' element={<LoginMain />}> </Route>
                     <Route path='/login-empresa' element={<LoginEmpresa />}> </Route>

                     {/* <Route path='/Registro' element={<SignIn />}> </Route> */}
                     <Route path='/registro-freelancer' element={<RegistroFreelancer />}> </Route>
                     <Route path='/registro-empresa' element={<RegistroEmpresa />}> </Route>
                     <Route path='/registro-empleador' element={<RegisterEmployer />}></Route>

                     {/* Freelancer */}
                     <Route path='/freelancers' element={<ListFreelance />}> </Route>
                     <Route path='/freelancer/share/:username' element={<FreelancerShareProfile />}> </Route>
                     <Route path='/categorias-freelancers/:id' element={<ListFreelanceCategory />}> </Route>
                     <Route path='/freelancer/:id' element={<FreelancerPublicProfile />}> </Route>
                     <Route path='/CVPDF' element={<CVPDF />}></Route>

                     {/* Proyecto */}
                     <Route path='/proyectos' element={<ListProject />} />

                     <Route path='/informacion-pasantia/:id' element={<OpportunityInformation />} />
                     <Route path='/informacion-plaza/:id' element={<InformacionPlaza />} />
                     <Route path='/informacion-servicio-profesional/:id' element={<InfoServicesProfessional />} />
                     <Route path='/informacion-proyecto/:id' element={<InformactionProject />} />

                     <Route path='/categorias-proyectos/:id' element={<ListProjectCategory />} />

                     {/* Paginas estaticas */}
                     <Route path='/politica-de-privacidad' element={<PoliticasDeprivacidad />}> </Route>
                     <Route path="/quienes-somos" element={<QuienSomos></QuienSomos>} ></Route>

                     <Route path="/politica-de-chambaticon" element={<PoliticasdeChambaticon></PoliticasdeChambaticon>} ></Route>
                     <Route path="/Politica-de-cookies" element={<PolIticadeCookies /> } ></Route>
                     <Route path="/Terminos-y-Condiciones" element={<TerminosYCondiciones /> } ></Route>

                     <Route path="/terminos-de-servicio" element={<TerminosDeServicio></TerminosDeServicio>} ></Route>
                     <Route path="/Contactanos" element={<Contactanos></Contactanos>} ></Route>

                     {/* redirect */}
                     <Route path='/cerrar-sesion' element={<Navigate to="/" />} > </Route>
                     <Route path='/upds' element={<Navigate to="/Login" />} > </Route>

                     {/* Not Found */}
                     <Route path='*' element={<Error404></Error404>}></Route>
                     
                     {/* Recuperar password */}
                     <Route path='/reset/:token' element={<PasswordRecovery />} />

                     <Route path='/create-password/:token' element={<CreatePasswordUser />} />

                     <Route path='/vertificate-verification' element={<Certificado />} />

                     <Route path='/profile-public/:username/:registroID' element={<PerfilPublicoTalento />} />

                     {/* Rutas protegidas */}
                     <Route element={<ProtectedRoute isAuthenticated={chambaticonSession} />} >

                        {/* Freelancer */}
                        <Route path='/perfil-freelancer' element={<CreacionProfileFreelancer />} />
                        

                        


                        <Route path='/editar-perfil-freelance' element={<EditPerfil />} />
                        <Route path='/registrar-habilidades' element={<EditSkill />} />
                        <Route path='/historialFacturacion' element={<ViewHistorialFacturacion />} />
                        <Route path='/registrar-experiencia-laboral/:id' element={<EditExpLaboral />} />
                        <Route path='/registrar-estudios-academicos/:id' element={<EditAcademica />} />
                        <Route path='/registrar-otros-estudios/:id' element={<ViewOtherStudies />} />
                        <Route path='/registrar-portafolio/:id' element={<RegisterPortfolio />} />

                        {/* Empresa */}
                        <Route path='/perfil-empresa' element={<CreacionProfileEmpresa />} />
                        <Route path='/editar-perfil-empresa' element={<EditEmpresa />} />
                        <Route path='/perfil-pago' element={<PerfilPago />} />
                        <Route path='/pago-freelancer/:id/:idFreelancer' element={<PagoFreelancer />} />
                        <Route path='/PruebaTarjeta' element={<PaymentForm />}></Route>

                        {/*Empleador*/}
                        <Route path='/perfil-empleador' element={<Employer />}></Route>
                        <Route path='/editar-perfil-empleador' element={<EditProfileEmployer />}></Route>

                        {/* Proyecto */}
                        <Route path='/publicar-proyecto' element={<SelectProyecto />} />
                        <Route path='/editar-oportunidad/:oportunidad/:id' element={<EditarOportunidad />} />


                        <Route path='/actualizar-proyecto/:id' element={<UpdatedProjectView />} />
                        <Route path='/informacion-proyecto/:id' element={<SeeProjectInformation />} />
                        <Route path='/categorias-proyectos/:id' element={<ListProjectCategory />} />

                        {/* Proyecto Empresa */}
                        <Route path='/proyectos-empresa' element={<ListProProEmp />} />
                        <Route path='/detalles-de-proyecto/:id/:type' element={<DetallesProject />} />
                        <Route path='/mensajes-proyecto/:profile/:id/:if/:projectState' element={<ChatProjects />} />

                        {/* Proyecto Freelancer */}
                        <Route path='/enviar-propuesta/:id' element={<AplyProFree />} />

                        {/* Planes */}
                        <Route path="/Planes" element={<Planes />} />
                        <Route path="/obtener-plan/:id" element={<PurchasePlans />} />
                        <Route path="/DetallesSuscripcion" element={<ViewDetallesSuscripcion />} />


                     </Route>

                  </Routes>
               </div>

               <Footer />

            </BrowserRouter>
         </SkeletonTheme>

      </>

   );

}

export default RouterApp;

